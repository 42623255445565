import * as Codes from 'constants/codes';
import { localCache } from 'utils/localCache';

function getAuthenticationStorage(): Storage {
  return localCache;
}

export function storeAuthTokens(
  accessToken: string | null,
  refreshToken: string | null,
  expires: number | null,
) {
  const _expires = expires ?? 0;

  try {
    const storage = getAuthenticationStorage();

    storage.setItem(Codes.AUTH_TOKEN, `${accessToken}`);
    storage.setItem(
      Codes.PERSISTENCE_KEY_REFRESH_TOKEN_CODE,
      `${refreshToken}`,
    );
    storage.setItem(
      Codes.PERSISTENCE_KEY_TOKEN_EXPIRES_CODE,
      _expires.toString(),
    );
    storage.setItem(
      Codes.PERSISTENCE_KEY_TOKEN_EXPIRES_AT_CODE,
      (_expires + Date.now()).toString(),
    );
  } catch (err) {
    console.error('Failed to store AuthTokens', err);
  }
}

export function getAccessToken(): string | null {
  try {
    const storage = getAuthenticationStorage();
    return storage.getItem(Codes.AUTH_TOKEN);
  } catch (err) {
    console.error('Failed to retrieve AccessToken', Codes.AUTH_TOKEN, err);
  }

  return null;
}
