export const dom = {
  'availabilityStatuses.available': 'Available',
  'availabilityStatuses.deal-closed': 'Deal closed',
  'availabilityStatuses.off-market': 'Off Market',
  'availabilityStatuses.rented': 'Rented',
  'availabilityStatuses.reserved': 'Reserved',
  'availabilityStatuses.sold': 'Sold',
  'availabilityStatuses.unavailable': 'Unavailable',
  'availabilityStatuses.under-negotiation': 'Under Negotiation',
  'availabilityTypes.business-lease': 'Business Lease',
  'availabilityTypes.letting': 'Letting',
  'availabilityTypes.letting-sale': 'Letting/Sale',
  'availabilityTypes.sale': 'Sale',
  'availabilityTypes.sub-lease': 'Sublease',
  'instructionStatuses.active': 'Active',
  'instructionStatuses.deleted': 'Deleted',
  'instructionStatuses.draft': 'Draft',
  'instructionStatuses.ended': 'Ended',
  'instructionStatuses.on-going-negotiation': 'On Going Negotiation',
  'instructionStatuses.undefined': 'Undefined',
  'instructionTypes.co-exclusive': 'Co-exclusive',
  'instructionTypes.direct': 'Direct',
  'instructionTypes.exclusive': 'Exclusive',
  'instructionTypes.non-exclusive': 'Non exclusive',
  'instructionTypes.open': 'Open',
  'instructionTypes.research-only': 'Research only',
  'instructionTypes.unknown': 'Unknown',
  'instructionTypes.verbal': 'Verbal',
  'floorTypes.lower-floor': 'Lower floor',
  'floorTypes.upper-floor': 'Upper floor',
  'floors.-1': 'B1',
  'floors.-10': 'B10',
  'floors.-2': 'B2',
  'floors.-3': 'B3',
  'floors.-4': 'B4',
  'floors.-5': 'B5',
  'floors.-6': 'B6',
  'floors.-7': 'B7',
  'floors.-8': 'B8',
  'floors.-9': 'B9',
  'floors.1': '1st',
  'floors.10': '10th',
  'floors.11': '11th',
  'floors.12': '12th',
  'floors.13': '13th',
  'floors.14': '14th',
  'floors.15': '15th',
  'floors.16': '16th',
  'floors.17': '17th',
  'floors.18': '18th',
  'floors.19': '19th',
  'floors.2': '2nd',
  'floors.20': '20th',
  'floors.21': '21st',
  'floors.22': '22nd',
  'floors.23': '23rd',
  'floors.24': '24th',
  'floors.25': '25th',
  'floors.26': '26th',
  'floors.27': '27th',
  'floors.28': '28th',
  'floors.29': '29th',
  'floors.3': '3rd',
  'floors.30': '30th',
  'floors.4': '4th',
  'floors.5': '5th',
  'floors.6': '6th',
  'floors.7': '7th',
  'floors.8': '8th',
  'floors.9': '9th',
  'floors.all': 'Entire Building',
  'floors.ground': 'GF',
  'floors.mezzanine': 'MF',
  'floors.raised-ground': 'RG',
  'floors.range': 'Range',
  'floors.rooftop': 'RT',
  'priceTypes.asking': 'Asking',
  'mainUnitUses.living': 'Living',
  'mainUnitUses.logistics': 'Logistics',
  'mainUnitUses.office': 'Offices',
  'mainUnitUses.parking': 'Parking',
  'mainUnitUses.retail': 'Retail',
  'mainUnitUses.storage': 'Storage',
  'usedStatuses.good-condition': 'Good condition',
  'usedStatuses.mint-refurbished': 'Mint refurbished',
  'usedStatuses.new': 'New',
  'usedStatuses.to-be-refurbished': 'To be refurbished',
  'usedStatuses.under-construction': 'Under construction',
  'boxAvailabilities.included': 'Included',
  'boxAvailabilities.optional': 'Optional',
  'climaTypes.fan-coils': 'Fan coils',
  'climaTypes.fan-coils-ceiling': 'Fan coils Ceiling',
  'climaTypes.split': 'Split',
  'climaTypes.underfloor': 'Underfloor',
  'climaTypes.underfloor-dehumidification': 'Underfloor Dehumidification',
  'energyRatingStatuses.actual': 'Actual',
  'energyRatingStatuses.by-project': 'By Project',
  'energyRatingStatuses.to-be-renewed': 'To Be Renewed',
  'energyRatings.not-available': 'Missing',
  'energyRatings.not-required': 'Not required',
  'energyRatings.pending-release': 'Pending release',
  'exposuretypes.double': 'Double',
  'exposuretypes.quadruple': 'Quadruple',
  'exposuretypes.single': 'Single',
  'exposuretypes.triple': 'Triple',
  'heatingSources.geothermal': 'Geothermal',
  'heatingSources.hydrothermal': 'Hydrothermal',
  'heatingSources.methane': 'Methane',
  'heatingSources.solar': 'Solar',
  'heatingSystems.air-heating': 'Air Heating',
  'heatingSystems.ceiling-heating': 'Ceiling Heating',
  'heatingSystems.floor-heating': 'Floor Heating',
  'heatingSystems.radiator': 'Radiator',
  'heatingTypes.autonomous': 'Autonomous',
  'heatingTypes.centralized': 'Centralized',
  'kitchenTypes.coverable-kitchenette': 'Coverable kitchenette',
  'kitchenTypes.habitable': 'Habitable',
  'kitchenTypes.kitchenette': 'Kitchenette',
  'kitchenTypes.open': 'Open',
  'kitchenTypes.semi-habitable': 'Semi Habitable',
  'laPositioningTypes.east': 'East',
  'laPositioningTypes.north': 'North',
  'laPositioningTypes.north-east': 'North-East',
  'laPositioningTypes.north-west': 'North-West',
  'laPositioningTypes.south': 'South',
  'laPositioningTypes.south-east': 'South-East',
  'laPositioningTypes.south-west': 'South-West',
  'laPositioningTypes.west': 'West',
  'residentialUseTypes.Building': 'Building',
  'residentialUseTypes.apartment': 'Apartment',
  'residentialUseTypes.attic': 'Attic',
  'residentialUseTypes.automated-parking-space': 'Automated parking space',
  'residentialUseTypes.box': 'Box',
  'residentialUseTypes.cellar': 'Cellar',
  'residentialUseTypes.covered-parking-space': 'Covered parking space',
  'residentialUseTypes.entire-building': 'Entire building',
  'residentialUseTypes.laboratory': 'Laboratory',
  'residentialUseTypes.land': 'Land',
  'residentialUseTypes.loft': 'Loft',
  'residentialUseTypes.loft-open-space': 'Loft open space',
  'residentialUseTypes.motorbike-parking-space': 'Motorbike parking space',
  'residentialUseTypes.office': 'Office',
  'residentialUseTypes.outdoor-parking-space': 'Outdoor parking space',
  'residentialUseTypes.penthouse': 'Penthouse',
  'residentialUseTypes.retail': 'Retail',
  'residentialUseTypes.shed': 'Shed',
  'residentialUseTypes.shop': 'Shop',
  'residentialUseTypes.showroom': 'Showroom',
  'residentialUseTypes.terraced-house': 'Terraced house',
  'residentialUseTypes.urban-villa': 'Urban villa',
  'residentialUseTypes.villa': 'Villa',
  'residentialUseTypes.warehouse': 'Warehouse',
  'windowFixtures.double': 'Double',
  'windowFixtures.single': 'Single',
  'windowFixtures.triple': 'Triple',
  'reasons.amenities': 'Building Amenities',
  'reasons.building': 'Building',
  'reasons.concierge': 'Concierge',
  'reasons.contract-terms': 'Contract terms',
  'reasons.exposure': 'Exposure/Brightness',
  'reasons.external-areas': 'External areas',
  'reasons.floor': 'Floor',
  'reasons.layout': 'Layout',
  'reasons.location': 'Location/Area',
  'reasons.parking': 'Box/Parking',
  'reasons.personal-taste': 'Personal taste',
  'reasons.price': 'Price/Rent',
  'reasons.quiet-noise': 'Quiet/Noise',
  'reasons.required-warranties': 'Required warranties',
  'reasons.servicies': 'Location services',
  'reasons.surface': 'Surface',
  'reasons.micro-location': 'Micro-Location',
  'reasons.timing': 'Timing',
  'reasons.view': 'View',
};
