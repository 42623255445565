import * as Constants from 'constants/constants';
import { AppContext, AppContextType } from 'context';
import { motion } from 'framer-motion';
import { InfoCardRowTitle } from 'pages/KeyPlan/components/infoCardRowTitle';
import { useFeedbackCardState } from 'pages/KeyPlan/hooks/useFeedbackCardState';
import React, { useContext } from 'react';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import { UnitDataType } from 'types';
import { FeedbackReasonType, OpportunityProductRaw } from 'types/opportunity';

export enum OpportunityProductStatus {
  Interested,
  NotInterested,
}

export type FeedbackElementsType = Record<
  string,
  {
    value: boolean | undefined;
    positiveFeedbackId?: number;
    negativeFeedbackId?: number;
  }
>;

export const FeedbackCard = ({
  feedbackReasonsChunk,
  setFeedbackElements,
  feedbackElements,

  selectedOpportunityProduct,
  getExistingFeedbacks,
  opportunityId,
  unitData,
  opportunityProductDeletionInProgress,
  setOpportunityProductDeletionInProgress,
  isFullScreen,
}: {
  feedbackReasonsChunk: FeedbackReasonType[][];
  setFeedbackElements: React.Dispatch<
    React.SetStateAction<FeedbackElementsType>
  >;
  feedbackElements: FeedbackElementsType;
  propertyName: string;
  selectedOpportunityProduct: OpportunityProductRaw | undefined;
  getExistingFeedbacks: (opportunityProductId: number) => Promise<void>;
  opportunityId: number | undefined;
  unitData: UnitDataType;
  opportunityProductDeletionInProgress: boolean;
  setOpportunityProductDeletionInProgress: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  isFullScreen: boolean;
}) => {
  const { t, tDom, useStyles } = useContext<AppContextType>(AppContext);

  const localClasses = useLocalStyles();
  const classes = useStyles();

  const {
    status,
    setStatus,
    handleSave,
    isSaving,
    saveMessage,
    isDeleteButtonPressed,
    handleDeleteButtonClick,
    deleteMessage,
    handleDelete,
    setIseDeleteButtonPressed,
  } = useFeedbackCardState({
    selectedOpportunityProduct,
    opportunityId,
    unitData,
    feedbackElements,
    getExistingFeedbacks,
    setOpportunityProductDeletionInProgress,
    setFeedbackElements,
  });

  return (
    <div
      className={`${
        isFullScreen ? localClasses.feedbackCardContainer : 'h-100'
      }`}
    >
      <Row>
        <Col>
          <InfoCardRowTitle title={'Feedback'} icon="las la-thumbs-up" />
        </Col>
      </Row>
      <Row>
        <Col
          xs={isFullScreen ? 2 : 12}
          style={{ color: '#888888', fontSize: isFullScreen ? 22 : 15 }}
          className={`mt-auto ${
            isFullScreen
              ? localClasses.sectionTitle
              : localClasses.sectionTitleResponsive
          }`}
        >
          {t('status')}:
        </Col>
        <Col xs={isFullScreen ? 10 : 12}>
          <motion.span
            className="d-inline-block w-auto"
            whileTap={{ scale: 0.95 }}
          >
            <Button
              className={`me-3 ${classes.statusButton} ${
                status === OpportunityProductStatus.Interested
                  ? localClasses.positiveActiveButton
                  : ''
              }`}
              style={{ width: isFullScreen ? 250 : 160 }}
              onClick={() => {
                if (status === OpportunityProductStatus.Interested) {
                  setStatus(null);
                } else {
                  setStatus(OpportunityProductStatus.Interested);
                }
              }}
            >
              <i className="las la-smile me-3" />
              {t('interested')}
            </Button>
          </motion.span>

          <motion.span
            className="d-inline-block w-auto"
            whileTap={{ scale: 0.95 }}
          >
            <Button
              className={`${classes.statusButton} ${
                status === OpportunityProductStatus.NotInterested
                  ? localClasses.negativeActiveButton
                  : ''
              }`}
              style={{ width: isFullScreen ? 250 : 160 }}
              onClick={() => {
                if (status === OpportunityProductStatus.NotInterested) {
                  setStatus(null);
                } else {
                  setStatus(OpportunityProductStatus.NotInterested);
                }
              }}
            >
              <i className="las la-frown me-3" />
              {t('notInterested')}
            </Button>
          </motion.span>
        </Col>
      </Row>
      <Row className="mt-5 align-items-center">
        <Col
          xs={isFullScreen ? 2 : 12}
          className={`mt-2 ${
            isFullScreen
              ? localClasses.sectionTitle
              : localClasses.sectionTitleResponsive
          }`}
        >
          {t('feedback')}:
        </Col>

        <Col xs={isFullScreen ? 10 : 12}>
          <div
            className={`${
              isFullScreen
                ? localClasses.feedbackContainer
                : localClasses.feedbackContainerResponsive
            } ${isFullScreen ? 'd-flex' : ''}`}
          >
            {feedbackReasonsChunk.map((chunkedFeedbackReasons, idx) => {
              return (
                <div key={idx}>
                  {chunkedFeedbackReasons.map((reason, idx) => {
                    return (
                      <div className="mb-2" key={idx}>
                        <motion.div
                          className={`${
                            isFullScreen
                              ? localClasses.feedbackButton
                              : localClasses.feedbackButtonResponsive
                          } 
                          ${
                            feedbackElements[reason.code]?.value
                              ? localClasses.positiveActiveButton
                              : ''
                          }
                          `}
                          onClick={() => {
                            setFeedbackElements((prev) => {
                              const newFeedbackElements = { ...prev };
                              if (
                                newFeedbackElements[reason.code]?.value === true
                              ) {
                                newFeedbackElements[reason.code] = {
                                  ...newFeedbackElements[reason.code],
                                  value: undefined,
                                };
                              } else {
                                newFeedbackElements[reason.code] = {
                                  ...newFeedbackElements[reason.code],
                                  value: true,
                                };
                              }
                              return newFeedbackElements;
                            });
                          }}
                          whileTap={{ scale: 0.8 }}
                        >
                          <i className="las la-thumbs-up my-auto" />
                        </motion.div>

                        <motion.div
                          className={`negativeActive ${
                            isFullScreen
                              ? localClasses.feedbackButton
                              : localClasses.feedbackButtonResponsive
                          } 
                          ${
                            feedbackElements[reason.code]?.value === false
                              ? localClasses.negativeActiveButton
                              : ''
                          }

                          `}
                          onClick={() => {
                            setFeedbackElements((prev) => {
                              const newFeedbackElements = { ...prev };
                              if (
                                newFeedbackElements[reason.code]?.value ===
                                false
                              ) {
                                newFeedbackElements[reason.code] = {
                                  ...newFeedbackElements[reason.code],
                                  value: undefined,
                                };
                              } else {
                                newFeedbackElements[reason.code] = {
                                  ...newFeedbackElements[reason.code],
                                  value: false,
                                };
                              }
                              return newFeedbackElements;
                            });
                          }}
                          whileTap={{ scale: 0.8 }}
                        >
                          <i className="las la-thumbs-down my-auto" />
                        </motion.div>
                        <span className="ms-3">
                          {tDom(`reasons.${reason.code}`)}
                        </span>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </Col>
      </Row>

      <Row
        className={`justify-content-center ${localClasses.footerButtons} ${
          isFullScreen
            ? localClasses.saveButton
            : localClasses.saveButtonResponsive
        }`}
      >
        <Button
          className={localClasses.positiveActiveButton}
          onClick={handleSave}
          disabled={isSaving}
        >
          {saveMessage.showSpinner && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="mb-1 me-2"
            />
          )}

          <i className={`${saveMessage.icon} me-2`} />
          {saveMessage.text}
        </Button>
      </Row>
      {selectedOpportunityProduct && (
        <Row
          className={`justify-content-center ${localClasses.footerButtons} ${localClasses.deleteButton}`}
        >
          <Button
            className={
              isDeleteButtonPressed
                ? localClasses.negativeActiveButtonPressed
                : localClasses.negativeActiveButton
            }
            onClick={handleDeleteButtonClick}
            disabled={opportunityProductDeletionInProgress}
          >
            {deleteMessage.showSpinner && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="mb-1 me-2"
              />
            )}

            <i className={`${deleteMessage.icon} me-2`} />
            {deleteMessage.text}
          </Button>

          {isDeleteButtonPressed && (
            <div
              className={`${localClasses.deleteButtonText} mt-2`}
              style={{
                visibility:
                  deleteMessage.text === t('deleting') ? 'hidden' : 'visible',
              }}
            >
              <span className="me-3">{t('confirmDelete')}</span>
              <i
                className={`las la-check me-3 ${localClasses.deleteIcon}`}
                style={{
                  color: Constants.GREEN_COLOR,
                }}
                onClick={() => handleDelete()}
              />
              <i
                className={`las la-times me-3 ${localClasses.deleteIcon}`}
                style={{
                  color: Constants.RED_COLOR,
                }}
                onClick={() => setIseDeleteButtonPressed(false)}
              />
            </div>
          )}
        </Row>
      )}
    </div>
  );
};

const useLocalStyles = createUseStyles({
  footerButtons: {
    bottom: 0,
    '& .btn': {
      fontSize: '100%',
      padding: '10px 15px',
      width: 275,
    },
  },

  saveButton: {
    marginTop: '10rem',
  },
  saveButtonResponsive: {
    marginTop: '5rem',
  },

  deleteButtonText: {
    textAlign: 'center',
    color: '#8b8b8b',
    marginBottom: 5,
    fontSize: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  deleteButton: {
    marginTop: '2rem',
  },

  feedbackButton: {
    display: 'inline-block !important',
    padding: '10px 20px',
    backgroundColor: '#f0f0f3',
    color: '#888888',
    borderRadius: '100%',
    textAlign: 'center',
    fontSize: 33,
    cursor: 'pointer',
    marginRight: '1rem',
  },

  feedbackButtonResponsive: {
    display: 'inline-block !important',
    padding: '6px 12px',
    backgroundColor: '#f0f0f3',
    color: '#888888',
    borderRadius: '100%',
    textAlign: 'center',
    fontSize: 20,
    cursor: 'pointer',
    marginRight: '0.25rem',
  },

  feedbackContainer: {
    fontSize: 20,
    gap: '0 5rem',
  },
  feedbackContainerResponsive: {
    fontSize: 13,
    gap: '0 5rem',
    fontFamily: '"Nunito Sans", sans-serif',
  },

  positiveActiveButton: {
    backgroundColor: 'rgb(206, 241, 230)!important',
    border: 'rgb(206, 241, 230)!important',
    color: 'rgb(11, 183, 131)!important',
  },
  negativeActiveButton: {
    backgroundColor: 'rgb(250, 215, 215)!important',
    border: 'rgb(250, 215, 215)!important',
    color: 'rgb(231, 56, 56)!important',
  },
  negativeActiveButtonPressed: {
    color: 'rgb(250, 215, 215)!important',
    backgroundColor: 'rgb(231, 56, 56)!important',
    border: 'rgb(231, 56, 56)!important',
  },
  deleteIcon: {
    fontSize: 25,
    cursor: 'pointer',
  },
  feedbackCardContainer: {
    height: 1000,
  },
  sectionTitle: {
    color: '#888888',
    fontSize: 22,
    marginBottom: 'auto',
  },
  sectionTitleResponsive: {
    color: '#888888',
    fontSize: 15,
    marginBottom: 10,
  },
});
