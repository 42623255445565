import { LeftColumn } from 'components/LeftColumn';
import { MobileBottomContent } from 'components/MobileBottomContent';
import { MotionDiv } from 'components/MotionDiv';
import { AppContext, AppContextType } from 'context';
import React, { Fragment, useContext } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import { BackgroundOnlyType } from 'types/configuration';

export const BackgroundOnly: React.FC<BackgroundOnlyType> = ({
  title,
  backgroundImage,
  mainBackgroundImage,
  className,
}) => {
  const { useStyles, tConfig } = useContext<AppContextType>(AppContext);

  const classes = useStyles();
  const localClasses = useLocalStyles();

  return (
    <MotionDiv className={className ?? ''}>
      <Fragment>
        <Container fluid>
          <Row className="h-100 overflow-hidden">
            <LeftColumn title={title} />

            <Col
              xl={10}
              md={12}
              className={`${classes.galleryContainer} text-center mt-5`}
            >
              <img
                className={`img-fluid ${localClasses.backgroundOnlyImage}`}
                src={backgroundImage}
                alt={tConfig(title)}
              />
            </Col>
          </Row>
        </Container>
        <div className={classes.background}>
          {mainBackgroundImage && (
            <img
              src={mainBackgroundImage}
              alt="Gallery"
              className={classes.backgroundObject}
            />
          )}
        </div>
        <MobileBottomContent />
      </Fragment>
    </MotionDiv>
  );
};

const useLocalStyles = createUseStyles({
  backgroundOnlyImage: {
    maxHeight: '90vh',
  },
});
