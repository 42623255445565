import { useState } from 'react';
import { Keyplan, Marker } from 'types/configuration';

export const useKeyplanResponsiveState = () => {
  const [currentFloor, setCurrentFloor] = useState<Keyplan | null>(null);
  const [currentMarker, setCurrentMarker] = useState<Marker | null>(null);

  const [showModal, setShowModal] = useState<boolean>(false);

  const [floorMenuSection, setFloorMenuSection] = useState<Element | null>(
    null,
  );
  const [currentFloorId, setCurrentFloorId] = useState<number>();
  const [windowIsFlipped, setWindowIsFlipped] = useState(false);

  return {
    currentFloor,
    setCurrentFloor,
    currentMarker,
    setCurrentMarker,
    showModal,
    setShowModal,
    floorMenuSection,
    setFloorMenuSection,
    currentFloorId,
    setCurrentFloorId,
    windowIsFlipped,
    setWindowIsFlipped,
  };
};
