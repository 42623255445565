import { AppContext, AppContextType } from 'context';
import { useContext } from 'react';
import 'react-image-gallery/styles/css/image-gallery.css';
import { createUseStyles } from 'react-jss';

export const LoadingSpinner = () => {
  const { useStyles } = useContext<AppContextType>(AppContext);
  const localClasses = useLocalStyles();
  const classes = useStyles();

  return (
    <div className={`${localClasses.spinnerContainer} mt-5 mt-xl-0`}>
      <span className={`${localClasses.animationSpinner} ${classes.spinner}`} />
    </div>
  );
};

const useLocalStyles = createUseStyles({
  spinnerContainer: {
    margin: 'auto',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    textAlign: 'center',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    display: 'flex',
  },
  animationSpinner: {
    animation: '$mltShdSpin 1.7s infinite ease, $round 1.7s infinite ease',
    transform: 'translateZ(0)',
  },
  '@keyframes mltShdSpin': {
    '0%': {
      boxShadow:
        '0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em',
    },
    '5%, 95%': {
      boxShadow:
        '0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em',
    },
    '10%, 59%': {
      boxShadow:
        '0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em',
    },
    '20%': {
      boxShadow:
        '0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em ',
    },
    '38%': {
      boxShadow:
        '0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em',
    },
    '100%': {
      boxShadow:
        '0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em',
    },
  },
  '@keyframes round': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
});
