import { ConnectionStatus } from 'authentication/hooks/UseDatahubPolling';
import * as Constants from 'constants/constants';
import { AppContext, AppContextType } from 'context';
import { Orientation } from 'pages/KeyPlan/GestureWindow';
import { InfoCardRow } from 'pages/KeyPlan/components/infoCardRow';
import { InfoCardRowTitle } from 'pages/KeyPlan/components/infoCardRowTitle';
import { Fragment, useContext, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { UnitDataType } from 'types';
import { priceFormatter } from 'utils/priceFormatter';

export const PropertyDetailsCard = ({
  windowOrientation,
  unitData,
  isFullScreen,
}: {
  windowOrientation: Orientation;
  unitData: UnitDataType;
  isFullScreen: boolean;
}) => {
  const { t, tDom, authenticationStatus, configuration } =
    useContext<AppContextType>(AppContext);

  function infoCardFormatter(
    value: string | boolean | undefined | number,
    uom = '',
  ) {
    if (value === undefined) {
      return (
        <span style={{ color: '#b1b1b1', fontWeight: 400 }}>
          {t('unavailableData')}
        </span>
      );
    }
    if (typeof value === 'boolean') {
      if (value === true) {
        return <i className="las la-check-circle text-success" />;
      } else {
        return <i className="las la-times-circle text-danger" />;
      }
    }
    if (value) {
      return `${value} ${uom}`;
    }
  }

  type InformationSectionFieldsType = {
    label: string;
    value: string | boolean | undefined | number;
    translationKey: undefined | string;
  }[];

  const hideAvailabilities = useMemo(
    () => configuration.general.hideAvailabilities,
    [configuration.general.hideAvailabilities],
  );

  const generalInformationFields = [
    {
      label: t('propertyUnit.name'),
      value: unitData?.name ? unitData?.name : undefined,
      translationKey: undefined,
    },
    ...(!hideAvailabilities
      ? [
          {
            label: t('propertyUnit.status'),
            value: unitData?.availability?.availabilityStatus?.code,
            translationKey: 'availabilityStatuses',
          },
        ]
      : []),

    {
      label: t('propertyUnit.floor'),
      value: unitData?.floor?.code,
      translationKey: 'floors',
    },
    {
      label: t('propertyUnit.levelsNumber'),
      value: unitData?.levelsNumber ? unitData?.levelsNumber : undefined,
      translationKey: undefined,
    },
    {
      label: t('propertyUnit.residentialUseType'),
      value: unitData?.residentialDetails?.residentialUseType?.code,
      translationKey: 'residentialUseTypes',
    },
    {
      label: t('propertyUnit.usedStatus'),
      value: unitData?.property?.usedStatus?.code,
      translationKey: 'usedStatuses',
    },
    ...(authenticationStatus === ConnectionStatus.userAuthenticated
      ? [
          {
            label: t('price'),
            value: `${priceFormatter(
              unitData?.availability?.priceList?.price,
            )} €`,
            translationKey: undefined,
          },
        ]
      : []),
  ].map(({ label, value, translationKey }) => {
    const formattedValue =
      translationKey && value ? tDom(`${translationKey}.${value}`) : value;
    return { label, value: infoCardFormatter(formattedValue) };
  });

  const pertinencesFields = (
    [
      {
        label: t('propertyUnit.cellar'),
        value: unitData?.residentialDetails?.cellarFlag,
        translationKey: undefined,
      },
      {
        label: t('propertyUnit.terrace'),
        value: unitData?.residentialDetails?.terraceFlag,
        translationKey: undefined,
      },
      {
        label: t('propertyUnit.balcony'),
        value: unitData?.residentialDetails?.balconyFlag,
        translationKey: undefined,
      },
      {
        label: t('propertyUnit.communalGarden'),
        value: unitData?.residentialDetails?.communalGardenFlag,
        translationKey: undefined,
      },
    ] as InformationSectionFieldsType
  ).map(({ label, value, translationKey }) => {
    const formattedValue =
      translationKey && value ? tDom(`${translationKey}.${value}`) : value;
    return { label, value: infoCardFormatter(formattedValue) };
  });

  const climateFields = [
    {
      label: t('propertyUnit.heatingSystem'),
      value: unitData?.residentialDetails?.heatingSystemType?.code,
      translationKey: 'heatingSystems',
    },
    {
      label: t('propertyUnit.airConditioningFlag'),
      value: unitData?.residentialDetails?.airConditioningFlag
        ? unitData?.residentialDetails?.airConditioningFlag
        : undefined,
      translationKey: undefined,
    },
    {
      label: t('propertyUnit.climaType'),
      value: unitData?.residentialDetails?.climaType?.code,
      translationKey: 'climaTypes',
    },
  ].map(({ label, value, translationKey }) => {
    const formattedValue =
      translationKey && value ? tDom(`${translationKey}.${value}`) : value;
    return { label, value: infoCardFormatter(formattedValue) };
  });

  const compositionFields = [
    {
      label: t('propertyUnit.apartment'),
      value: `${unitData?.availability?.availableSurface} m²`,
      translationKey: undefined,
    },
    {
      label: t('propertyUnit.roomsNumber'),
      value: unitData?.residentialDetails?.roomsNumber,
      translationKey: undefined,
    },
    {
      label: t('propertyUnit.bedroomsNumber'),
      value: unitData?.residentialDetails?.bedroomsNumber,
      translationKey: undefined,
    },
    {
      label: t('propertyUnit.bathroomsNumber'),
      value: unitData?.residentialDetails?.bathroomNumber,
      translationKey: undefined,
    },
    {
      label: t('propertyUnit.kitchenType'),
      value: unitData?.residentialDetails?.kitchenType?.code,
      translationKey: 'kitchenTypes',
    },
    {
      label: t('propertyUnit.exposureType'),
      value: unitData?.residentialDetails?.exposureType?.code,
      translationKey: 'exposuretypes',
    },
    {
      label: t('propertyUnit.livingAreaPositioning'),
      value: unitData?.residentialDetails?.laPositioningType?.code,
      translationKey: 'laPositioningTypes',
    },
  ].map(({ label, value, translationKey }) => {
    const formattedValue =
      translationKey && value ? tDom(`${translationKey}.${value}`) : value;
    return { label, value: infoCardFormatter(formattedValue) };
  });

  const internalStatusFields = [
    {
      label: t('propertyUnit.fixturesMaterial'),
      value: unitData?.residentialDetails?.windowFixturesType?.code,
      translationKey: 'windowFixtures',
    },
  ].map(({ label, value, translationKey }) => {
    const formattedValue =
      translationKey && value ? tDom(`${translationKey}.${value}`) : value;
    return { label, value: infoCardFormatter(formattedValue) };
  });

  const internalFeaturesFields = (
    [
      {
        label: t('propertyUnit.securityDoor'),
        value: unitData?.residentialDetails?.securityDoorFlag,
        translationKey: undefined,
      },
    ] as InformationSectionFieldsType
  ).map(({ label, value, translationKey }) => {
    const formattedValue =
      translationKey && value ? tDom(`${translationKey}.${value}`) : value;
    return { label, value: infoCardFormatter(formattedValue) };
  });

  const parkingFields = (
    [
      {
        label: t('propertyUnit.boxAvailability'),
        value: unitData?.residentialDetails?.boxFlag,
        translationKey: undefined,
      },
      {
        label: t('propertyUnit.parkingSpace'),
        value: unitData?.residentialDetails?.parkingSpaceFlag,
        translationKey: undefined,
      },
      {
        label: t('propertyUnit.parkingSpaceMoto'),
        value: unitData?.residentialDetails?.parkingSpaceMotoFlag,
        translationKey: undefined,
      },
      {
        label: t('propertyUnit.electricChargeFlag'),
        value: unitData?.residentialDetails?.electricChargeFlag,
        translationKey: undefined,
      },
    ] as InformationSectionFieldsType
  ).map(({ label, value, translationKey }) => {
    const formattedValue =
      translationKey && value ? tDom(`${translationKey}.${value}`) : value;
    return { label, value: infoCardFormatter(formattedValue) };
  });

  const energyRating = [
    {
      label: t('propertyUnit.type'),
      value:
        unitData?.residentialDetails?.energyRating?.code?.toLocaleUpperCase(),
      translationKey: undefined,
    },
    {
      label: t('propertyUnit.status'),
      value: unitData?.residentialDetails?.energyRatingStatus?.code,
      translationKey: 'energyRatingStatuses',
    },
  ].map(({ label, value, translationKey }) => {
    const formattedValue =
      translationKey && value ? tDom(`${translationKey}.${value}`) : value;
    return { label, value: infoCardFormatter(formattedValue) };
  });

  const surfacesFields = (
    [
      {
        label: t('propertyUnit.apartment'),
        value: unitData?.surfaceUses.find(
          (sU) => sU.surfaceType.code === Constants.LIVING,
        )?.availableValue,
        translationKey: undefined,
      },
      {
        label: t('propertyUnit.porch'),
        value: unitData?.surfaceUses.find(
          (sU) => sU.surfaceType.code === Constants.LIVING,
        )?.availableValue,
        translationKey: undefined,
      },
    ] as InformationSectionFieldsType
  ).map(({ label, value, translationKey }) => {
    const formattedValue =
      translationKey && value ? tDom(`${translationKey}.${value}`) : value;
    return { label, value: infoCardFormatter(formattedValue, 'm²') };
  });

  const FirstColumnContent = () => {
    return (
      <Fragment>
        <InfoCardRowTitle
          title={t('propertyUnit.generalInformation')}
          icon="las la-building"
        />
        {generalInformationFields.map((data, id) => {
          return <InfoCardRow data={data} key={`${data.value}-${id}`} />;
        })}

        <InfoCardRowTitle
          title={t('propertyUnit.pertinences')}
          icon="las la-sliders-h"
        />
        {pertinencesFields.map((data, id) => {
          return <InfoCardRow data={data} key={`${data.value}-${id}`} />;
        })}

        <InfoCardRowTitle
          title={t('propertyUnit.climate')}
          icon="las la-fire-alt"
        />

        {climateFields.map((data, id) => {
          return <InfoCardRow data={data} key={`${data.value}-${id}`} />;
        })}

        {windowOrientation === Orientation.Portrait && <ThirdColumnContent />}
      </Fragment>
    );
  };

  const SecondColumnContent = () => {
    return (
      <Fragment>
        <InfoCardRowTitle
          title={t('propertyUnit.usedStatus')}
          icon="las la-door-open"
        />
        {internalStatusFields.map((data, id) => {
          return <InfoCardRow data={data} key={`${data.value}-${id}`} />;
        })}
        <InfoCardRowTitle
          title={t('propertyUnit.features')}
          icon="las la-list-alt"
        />

        {internalFeaturesFields.map((data, id) => {
          return <InfoCardRow data={data} key={`${data.value}-${id}`} />;
        })}
        <InfoCardRowTitle
          title={t('propertyUnit.parking')}
          icon="las la-parking"
        />
        {parkingFields.map((data, id) => {
          return <InfoCardRow data={data} key={`${data.value}-${id}`} />;
        })}

        <InfoCardRowTitle
          title={t('propertyUnit.energyRating')}
          icon="las la-signal"
        />

        {energyRating.map((data, id) => {
          return <InfoCardRow data={data} key={`${data.value}-${id}`} />;
        })}

        <InfoCardRowTitle
          title={t('propertyUnit.surfaces')}
          icon="las la-layer-group"
        />

        {surfacesFields.map((data, id) => {
          return <InfoCardRow data={data} key={`${data.value}-${id}`} />;
        })}
      </Fragment>
    );
  };

  const ThirdColumnContent = () => {
    return (
      <Fragment>
        <InfoCardRowTitle
          title={t('propertyUnit.composition')}
          icon="las la-cubes"
        />
        {compositionFields.map((data, id) => {
          return <InfoCardRow data={data} key={`${data.value}-${id}`} />;
        })}
      </Fragment>
    );
  };

  function getColumnWidth(
    windowOrientation: Orientation,
    isFullScreen: boolean,
  ) {
    if (!isFullScreen) {
      return 12;
    }
    if (windowOrientation === Orientation.Portrait) {
      return 6;
    }
    return 4;
  }

  return (
    <Row>
      <Col xs={getColumnWidth(windowOrientation, isFullScreen)}>
        <FirstColumnContent />
      </Col>

      <Col xs={getColumnWidth(windowOrientation, isFullScreen)}>
        <SecondColumnContent />
      </Col>

      {windowOrientation === Orientation.Landscape && (
        <Col xs={getColumnWidth(windowOrientation, isFullScreen)}>
          <ThirdColumnContent />
        </Col>
      )}
    </Row>
  );
};
