/* eslint-disable no-restricted-imports */
import icoWinClose from './ico-win-close.svg';
import icoWinIcon from './ico-win-icon.svg';
import icoWinInfo from './ico-win-info.svg';
import icoWinMax from './ico-win-max.svg';
import icoWinMin from './ico-win-min.svg';
import { useSpring, animated } from '@react-spring/web';
import { createUseGesture, dragAction, pinchAction } from '@use-gesture/react';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { AppContext, AppContextType } from 'context';
import { motion } from 'framer-motion';
import { InfoCard } from 'pages/KeyPlan/components/infoCard';
import { useImageStatus } from 'pages/KeyPlan/hooks/useImageStatus';
import React, {
  useState,
  useEffect,
  useContext,
  Fragment,
  useMemo,
} from 'react';
import { Col, Row } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import { AvailabilityDataType, UnitDataType } from 'types';
import { AppConfiguration } from 'types/configuration';
import {
  linearRegressionBottom,
  linearRegressionLeft,
} from 'utils/linearRegressions';

export enum Orientation {
  Portrait,
  Landscape,
}

// To ensure accurate positioning of the windows, the default width should always be set to 1240px.
// For images with a vertical orientation, adjust the height while keeping the width-to-height ratio in
// line with the fixed width of 1240px.
const DEFAULT_WIDTH = 1240;
const DEFAULT_HEIGHT = 877;

const SCALE_FACTOR = 0.35;
// If the app is running in an Electron context, request the keyplan images
// with the protocol expected by the Electron app
const KEYPLAN_IMAGE_PROTOCOL = window.electron ? 'asset:/' : '';

const useGesture = createUseGesture([dragAction, pinchAction]);

export function useWindowDimensions() {
  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  };

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export type MarkerWindowProps = {
  image: string;
  initialLeft?: number;
  initialTop?: number;
  plansData?: {
    buttonText: string | Record<string, string>;
    backgroundImage: string;
    markers: {
      title: string;
      top: string;
      left: string;
    }[];
  }[];
  idx: number;
  gestureWindowHeight?: number;
  availabilityData: AvailabilityDataType;
  opportunityProductDeletionInProgress: boolean;
  setOpportunityProductDeletionInProgress: React.Dispatch<
    React.SetStateAction<boolean>
  >;
};

export const MarkerWindow: React.FC<MarkerWindowProps> = ({
  image,
  plansData,
  idx,
  gestureWindowHeight = DEFAULT_HEIGHT,
  availabilityData,
  initialLeft = 0,
  initialTop = 0,
  opportunityProductDeletionInProgress,
  setOpportunityProductDeletionInProgress,
}) => {
  const isUserSelectionPage = useMemo<boolean>(() => {
    if (!plansData) {
      return true;
    }
    return false;
  }, [plansData]);

  const [left, setLeft] = useState<number>(initialLeft);
  const [top, setTop] = useState<number>(initialTop);

  useEffect(() => {
    setLeft(initialLeft);
    setTop(initialTop);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { configuration, useStyles, t, setMarkerWindows } =
    useContext<AppContextType>(AppContext);
  const { height, width } = useWindowDimensions();

  const windowOrientation = useMemo<Orientation>(() => {
    if (DEFAULT_WIDTH > gestureWindowHeight) return Orientation.Landscape;
    else return Orientation.Portrait;
  }, [gestureWindowHeight]);

  function getMarkerPosition() {
    if (isUserSelectionPage) {
      return {
        top: top + 200,
        left: left,
      };
    }

    return {
      //transform the % into absolute value based on the viewport size
      top:
        (height / 100) *
        parseInt(
          plansData!
            .flatMap((i) => i.markers)
            .filter((i) => i.title === image)[0]
            .top.replace('%', ''),
        ),
      left:
        (width / 100) *
        parseInt(
          plansData!
            .flatMap((i) => i.markers)
            .filter((i) => i.title === image)[0]
            .left.replace('%', ''),
        ),
    };
  }

  const ref = React.useRef<HTMLDivElement>(null);

  const [appearance, setAppearance] = useSpring(() => ({
    x: 0,
    y: 0,
    scale: SCALE_FACTOR,
    rotateZ: 0,
  }));

  const DEFAULT_WINDOW_STYLE = {
    ...appearance,
    zIndex: 9999,
  };

  const [isWindowZoomed, setIsWindowZoomed] = useState<boolean>(false);
  const [isWindowIcon, setIsWindowIcon] = useState<boolean>(false);
  const [windowStyle, setWindowStyle] = useState(DEFAULT_WINDOW_STYLE);
  const [scaleValue, setScaleValue] = useState<number>(SCALE_FACTOR);
  const [isPinching, setIsPinching] = useState<boolean>(false);

  useEffect(() => {
    const gestureHandler = (e: Event) => e.preventDefault();
    document.addEventListener('gestureStart', gestureHandler);
    document.addEventListener('gestureChange', gestureHandler);
    document.addEventListener('gestureEnd', gestureHandler);
    return () => {
      document.removeEventListener('gestureStart', gestureHandler);
      document.removeEventListener('gestureChange', gestureHandler);
      document.removeEventListener('gestureEnd', gestureHandler);
    };
  }, []);

  const handleExpandClick = () => {
    setAppearance.start({ scale: 1 });
    setWindowStyle(DEFAULT_WINDOW_STYLE);
    setAppearance.start({ x: 100, y: -30 });
    setIsWindowZoomed(true);
    setIsWindowIcon(false);
  };

  const handleReduceClick = () => {
    setAppearance.start({ scale: SCALE_FACTOR });
    setScaleValue(SCALE_FACTOR);
    setIsWindowZoomed(false);
  };

  const handleCloseClick = () => {
    setMarkerWindows((prev) => prev.filter((gW) => gW !== image));
  };

  function getIconCoordinates() {
    //Display icons in a grid based on their number
    return {
      top: 750 - Math.floor(idx / 8) * 50,
      left: (idx % 8) * 180,
    };
  }

  const handleIconClick = () => {
    setIsWindowIcon(true);
    handleReduceClick();
    setWindowStyle((windowStyle) => {
      return {
        ...windowStyle,
        height: 180,
        width: 700,
        backgroundImage: 'none',
        backgroundColor: '#FFF',
        top: getIconCoordinates().top,
        left: getIconCoordinates().left,
        color: '#324a66',
        borderRadius: 20,
      };
    });
    setAppearance.start({ x: -100, y: 100 });
  };
  const buttonsStyle: React.CSSProperties = isWindowZoomed
    ? //Buttons position if window is zoomed
      {
        transform: 'scale(0.65) translateY(10px) translateX(60px)',
        position: 'absolute',
        bottom: 870 + gestureWindowHeight - DEFAULT_HEIGHT,
        left: 1028,
      }
    : //Buttons position if window is in icon status
    isWindowIcon
    ? {
        transform: 'scale(2.5)',
        position: 'absolute',
        bottom: 55,
        right: 100,
      }
    : //Buttons position if window is normal (not zoomed nor icon)
      {
        transform: `scale(${0.65 / scaleValue})`,
        position: 'absolute',
        //Using linear regression to compute the best approximation
        bottom:
          linearRegressionBottom(scaleValue) +
          gestureWindowHeight -
          DEFAULT_HEIGHT,
        left: linearRegressionLeft(scaleValue),
        width: 250,
        visibility: `${isPinching ? 'hidden' : 'visible'}`,
      };

  const classes = useStyles();

  useGesture(
    {
      onPinch: ({
        origin: [originX, originY],
        first,
        movement: [ms],
        offset: [s, a],
        memo,
      }) => {
        if (!ref.current) {
          return;
        }
        setScaleValue(s * SCALE_FACTOR);
        setIsWindowZoomed(false);

        if (first) {
          const { width, height, x, y } = ref.current?.getBoundingClientRect();
          const tx = originX - (x + width / 2);
          const ty = originY - (y + height / 2);
          memo = [appearance.x.get(), appearance.y.get(), tx, ty];
        }

        const x = memo[0] - (ms - 1) * memo[2];
        const y = memo[1] - (ms - 1) * memo[3];

        setAppearance.start({
          scale: s * SCALE_FACTOR,
          rotateZ: Math.round(a / 90) * 90,
          x,
          y,
        });
        return memo;
      },
      onPinchStart: () => {
        setIsPinching(true);
      },
      onPinchEnd: () => {
        setIsPinching(false);
      },
      onDrag: ({ pinching, cancel, offset: [x, y], down }) => {
        if (pinching) return cancel();
        setAppearance.start({ x, y, immediate: down });
      },
    },
    {
      target: ref,
      drag: { from: () => [appearance.x.get(), appearance.y.get()] },
      pinch: { scaleBounds: { min: 0.25, max: 2 }, rubberband: true },
    },
  );

  const { imageIsAvailable, imageIsLoading } = useImageStatus(image);

  const [windowIsFlipped, setWindowIsFlipped] = useState(false);

  function handleInfoClick() {
    setWindowIsFlipped((prev) => !prev);
  }

  const localClasses = useLocalStyles({
    configuration,
    top: getMarkerPosition().top,
    left: getMarkerPosition().left,
    image,
    gestureWindowHeight,
    windowIsFlipped,
    isWindowIcon,
  });

  const unitData = useMemo<UnitDataType | undefined>(
    () => availabilityData.units.find((u) => u.name === image),
    [availabilityData.units, image],
  );

  return (
    <motion.div
      className={`flex fill center container`}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      key={image}
    >
      <animated.div
        className={`${localClasses.card} ${
          windowIsFlipped ? localClasses.cardRear : localClasses.cardFront
        }`}
        ref={ref}
        style={windowStyle}
      >
        {!windowIsFlipped && !isWindowIcon && (
          <Fragment>
            {imageIsLoading && <LoadingSpinner />}

            {!imageIsAvailable && !imageIsLoading && (
              <Row className="w-100">
                <Col>
                  <h5 className={classes.imageNotAvailableText}>
                    {t('unavailableImage').toLocaleUpperCase()}
                  </h5>
                </Col>
              </Row>
            )}
          </Fragment>
        )}

        {windowIsFlipped && !isWindowIcon && (
          <InfoCard
            propertyName={image}
            unitData={unitData}
            windowOrientation={windowOrientation}
            opportunityProductDeletionInProgress={
              opportunityProductDeletionInProgress
            }
            setOpportunityProductDeletionInProgress={
              setOpportunityProductDeletionInProgress
            }
            isFullScreen={true}
          />
        )}

        {/* Window Buttons */}
        {isWindowIcon && (
          <div style={{ fontSize: 65, marginLeft: 40 }}>{image}</div>
        )}

        <div style={buttonsStyle}>
          {unitData && !isWindowIcon && (
            <span
              onClick={handleInfoClick}
              className={localClasses.cursorPointer}
            >
              <img src={icoWinInfo} alt="icon" />
            </span>
          )}

          {!isWindowIcon && (
            <span
              onClick={handleIconClick}
              className={localClasses.cursorPointer}
            >
              <img src={icoWinIcon} alt="icon" />
            </span>
          )}
          {!isWindowZoomed && (
            <span
              onClick={handleExpandClick}
              className={localClasses.cursorPointer}
            >
              <img src={icoWinMax} alt="maximizeIcon" />
            </span>
          )}
          {isWindowZoomed && (
            <span
              onClick={handleReduceClick}
              className={localClasses.cursorPointer}
            >
              <img src={icoWinMin} alt="minimizeIcon" />
            </span>
          )}
          <span
            onClick={handleCloseClick}
            className={localClasses.cursorPointer}
          >
            <img src={icoWinClose} alt="closeIcon" />
          </span>
        </div>
      </animated.div>
    </motion.div>
  );
};

type StyleProps = {
  configuration: AppConfiguration;
  top: number;
  left: number;
  image: string;
  gestureWindowHeight: number;
  windowIsFlipped: boolean;
  isWindowIcon: boolean;
};

const useLocalStyles = createUseStyles({
  card: ({ top, left, gestureWindowHeight }: StyleProps) => ({
    position: 'absolute',
    width: DEFAULT_WIDTH,
    height: gestureWindowHeight,
    objectFit: 'cover',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    borderRadius: '5px',
    boxShadow: ' 0px 10px 30px -5px rgba(0, 0, 0, 0.3)',
    willChange: 'transform',
    border: '10px solid white',
    cursor: 'grab',
    touchAction: 'none',
    userSelect: 'none',
    webkitUserSelect: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    fontWeight: 500,
    fontSize: '22px',
    textAlign: 'center',
    color: '#000',
    top: top - 500 - (gestureWindowHeight - DEFAULT_HEIGHT) / 3,
    left: left - 500,
  }),

  cardRear: ({ isWindowIcon, image }: StyleProps) => ({
    backgroundColor: `#f7f7f6`,
    padding: !isWindowIcon ? '25px 75px 75px 75px !important' : '0',
    background: `linear-gradient(rgba(247, 247, 246, 0.95), rgba(247, 247, 246, 0.95)), url('${KEYPLAN_IMAGE_PROTOCOL}/image/planimetry/${image}.png') no-repeat top center / cover`,
  }),
  cardFront: ({ configuration, image }: StyleProps) => ({
    background: `url('${KEYPLAN_IMAGE_PROTOCOL}/image/planimetry/${image}.png')`,
    backgroundColor: `${configuration.style.primaryColor}`,
    padding: '20px',
    backgroundRepeat: 'no-repeat !important',
    backgroundSize: 'cover !important',
  }),
  cursorPointer: { cursor: 'pointer' },

  innerCardFront: {
    willChange: 'transform, opacity',
  },
});
