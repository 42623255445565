import { AppContext, AppContextType } from 'context';
import { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';

type InfoCardRowType = {
  data: {
    label: string;
    value: string | number | undefined | JSX.Element | boolean;
  };
};

export const InfoCardRow: React.FC<InfoCardRowType> = ({ data }) => {
  const { label, value } = data;
  const localClasses = useLocalStyles();
  const { useStyles } = useContext<AppContextType>(AppContext);

  const classes = useStyles();
  return (
    <Row className={classes.propertyInfoContainerRow}>
      <Col className={localClasses.propertyInfoContainerLeftColumn}>
        {label}
      </Col>
      <Col>{value}</Col>
    </Row>
  );
};

const useLocalStyles = createUseStyles({
  propertyInfoContainerLeftColumn: {
    color: '#888888',
  },
});
