export const translations = {
  unavailableImage: 'Image not available',
  unavailableInformation: 'Information not available',
  unavailableData: 'Data not available',
  missingToken: 'Missing access token',
  noVisits: 'No appointments available',
  visits: 'Appointments:',
  logout: 'Logout',
  'propertyUnit.generalInformation': 'General information',
  'propertyUnit.floor': 'Floor',
  'propertyUnit.levelsNumber': 'Levels number',
  'propertyUnit.name': 'Name',
  'propertyUnit.legalStatus': 'Legal status',
  'propertyUnit.residentialUseType': 'Residential use type',
  'propertyUnit.usedStatus': 'Internal status',
  'propertyUnit.features': 'Internal features',
  'propertyUnit.securityDoor': 'Security door',
  'propertyUnit.parking': 'Parking',
  'propertyUnit.box': 'Box',
  'propertyUnit.boxAvailability': 'Box availability',
  'propertyUnit.parkingSpace': 'Parking space',
  'propertyUnit.parkingSpaceMoto': 'Parking space moto',
  'propertyUnit.electricChargeFlag': 'Electric charge',
  'propertyUnit.pertinences': 'Pertinences',
  'propertyUnit.cellar': 'Cellar',
  'propertyUnit.terrace': 'Terrace',
  'propertyUnit.balcony': 'Balcony',
  'propertyUnit.communalGarden': 'Communal garden',
  'propertyUnit.climate': 'Climate',
  'propertyUnit.heatingSystem': 'Heating system',
  'propertyUnit.airConditioningFlag': 'Air conditioning',
  'propertyUnit.climaType': 'Clima type',
  'propertyUnit.composition': 'Composition',
  'propertyUnit.roomsNumber': 'Rooms number (bathroom and kitchen excluded)',
  'propertyUnit.bedroomsNumber': 'Bedrooms',
  'propertyUnit.bathroomsNumber': 'Bathrooms',
  'propertyUnit.kitchenType': 'Kitchen type',
  'propertyUnit.exposureType': 'Exposure type',
  'propertyUnit.livingAreaPositioning': 'Living area positioning',
  'propertyUnit.fixturesMaterial': 'Fixtures material',
  'propertyUnit.energyRating': 'Energy rating',
  'propertyUnit.type': 'Type',
  'propertyUnit.status': 'Status',
  'propertyUnit.surfaces': 'Surfaces',
  'propertyUnit.apartment': 'Apartment',
  'propertyUnit.porch': 'Porch',
  'propertyUnit.heating': 'Heating',
  yes: 'Yes',
  no: 'No',
  propertyDetails: 'Property details',
  feedback: 'Feedback',
  status: 'Status',
  interested: 'Interested',
  notInterested: 'Not interested',
  save: 'Save',
  saving: 'Saving',
  saved: 'Saved',
  delete: 'Delete',
  deleting: 'Deleting',
  deleted: 'Deleted',
  error: 'Error',
  addToSelection: 'Add to Selection',
  livingSurface: 'Living',
  porchSurface: 'Porch',
  price: 'Price',
  addFromKeyplanSection: 'Add from Keyplan page',
  noPropertiesWarning: "{{name}} doesn't have any selected property",
  confirmDelete: 'Confirm delete?',
  addFromKeyplan: 'Add from Keyplan',
  selectFloor: 'Select floor',
  selectLanguage: 'Select Language',
  fullscreenAlert:
    'To enable <b>advanced viewing mode</b>, start Full Screen mode from the sidebar menu <b>at the bottom right.</b>',
  selectPage: 'Select page:',
  page: 'Page',
};
