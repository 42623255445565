export const PORTRAIT = 'portrait-primary';
export const HOME = 'Home';
export const WHITE = 'white';
export const EVENT_INIT = 'init';
export const EVENT_CREATE = 'create';
export const MESSAGE_TYPE_AUTH = 'auth';
export const MESSAGE_TYPE_SUBSCRIBE = 'subscribe';
export const MESSAGE_TYPE_SUBSCRIPTION = 'subscription';
export const MESSAGE_TYPE_PING = 'ping';
export const MESSAGE_TYPE_PONG = 'pong';
export const MESSAGE_STATUS_OK = 'ok';
export const COLLECTION_DIGITAL_TABLE_SESSIONS = 'dm_digital_table_sessions';
export const ITEMS_ENDPOINT = 'items';
export const INTERESTED = 'interested';
export const NOT_INTERESTED = 'not-interested';
export const MISSING = 'missing';
export const LIVING_SEARCH_PROPERTY_SALE = 'living-search-property-sale';
export const CANCELED = 'canceled';
export const LIVING = 'living';
export const PORCH = 'porch';
export const ASKING = 'asking';
export const PRODUCTS = 'products';
export const GREEN_COLOR = 'green';
export const RED_COLOR = 'red';
export const GREY_COLOR = 'grey';
export const FIRST_VIDEO_TITLE = 'Video 1';
