import { AppContext, AppContextType } from 'context';
import { AnimatePresence, motion } from 'framer-motion';
import { QRCodeSVG } from 'qrcode.react';
import { useContext } from 'react';
import { getEnvVariable } from 'utils/getEnvVariable';

export const QrCodePopover: React.FC<{
  showQrCode: boolean;
  timestamp: number;
}> = ({ showQrCode, timestamp }) => {
  const { useStyles } = useContext<AppContextType>(AppContext);

  const classes = useStyles();

  const { configuration } = useContext<AppContextType>(AppContext);

  // eslint-disable-next-line no-console
  console.info(
    `${getEnvVariable(
      'REACT_APP_NEXT_BASE_URL',
    )}/business-units/worklife/apps/digital-table-authenticator/${encodeURIComponent(
      configuration.general.tableName,
    )}/${configuration.general.tableUuid}/authorization/${timestamp}`,
  );

  return (
    <AnimatePresence>
      <motion.div
        initial={{ right: -150, opacity: 0 }}
        animate={{
          right: showQrCode ? 0 : -150,
          opacity: showQrCode ? 1 : 0,
        }}
        transition={{
          duration: 0.3,
          ease: [0.43, 0.13, 0.23, 0.96],
        }}
        className={classes.qrPopover}
        key="qrCode"
      >
        <QRCodeSVG
          value={`${getEnvVariable(
            'REACT_APP_NEXT_BASE_URL',
          )}/business-units/worklife/apps/digital-table-authenticator/${encodeURIComponent(
            configuration.general.tableName,
          )}/${configuration.general.tableUuid}/authorization/${timestamp}`}
          size={128}
          bgColor={'#ffffff'}
          fgColor={'#000000'}
          level={'L'}
          includeMargin={false}
          key={Math.random()}
        />
      </motion.div>
    </AnimatePresence>
  );
};
