import {
  getOpportunityProducts,
  updateFeedbackElements,
  updateOpportunity,
} from 'api';
import * as Collections from 'constants/collections';
import * as Constants from 'constants/constants';
import { AppContext, AppContextType } from 'context';
import * as NextDomApi from 'directus/api/dom';
import * as DirectusApi from 'directus/api/items';
import {
  FeedbackElementsType,
  OpportunityProductStatus,
} from 'pages/KeyPlan/components/FeedbackCard';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { UnitDataType } from 'types';
import { OpportunityProductRaw } from 'types/opportunity';

const TIMEOUT_SAVE_BUTTON = 2000;
const TIMING_REFRESH_OPPORTUNITY_EMPLOYEE = 3000;

export const useFeedbackCardState = ({
  selectedOpportunityProduct,
  opportunityId,
  unitData,
  feedbackElements,
  getExistingFeedbacks,
  setOpportunityProductDeletionInProgress,
  setFeedbackElements,
}: {
  selectedOpportunityProduct: OpportunityProductRaw | undefined;
  opportunityId: number | undefined;
  unitData: UnitDataType;
  feedbackElements: FeedbackElementsType;
  getExistingFeedbacks: (opportunityProductId: number) => Promise<void>;
  setOpportunityProductDeletionInProgress: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setFeedbackElements: React.Dispatch<
    React.SetStateAction<FeedbackElementsType>
  >;
}) => {
  const { t, setVisitDetails, visitDetails } =
    useContext<AppContextType>(AppContext);

  const [isSaving, setIsSaving] = useState<boolean>(false);

  const SAVE_BUTTON_VARIANTS: Record<
    string,
    { text: string; icon: string; showSpinner: boolean }
  > = {
    saveEnabled: {
      text: t('save'),
      icon: 'las la-save me-2',
      showSpinner: false,
    },
    saveInProgress: {
      text: t('saving'),
      icon: '',
      showSpinner: true,
    },
    saveSuccess: {
      text: t('saved'),
      icon: 'las la-check',
      showSpinner: false,
    },
    saveError: {
      text: t('error'),
      icon: 'las la-times',
      showSpinner: false,
    },
  };

  const DELETE_BUTTON_VARIANTS: Record<
    string,
    { text: string; icon: string; showSpinner: boolean }
  > = {
    deleteEnabled: {
      text: t('delete'),
      icon: 'las la-trash me-2',
      showSpinner: false,
    },
    deleteInProgress: {
      text: t('deleting'),
      icon: '',
      showSpinner: true,
    },
    deleteError: {
      text: t('error'),
      icon: 'las la-times',
      showSpinner: false,
    },
  };

  const [saveMessage, setSaveMessage] = useState(
    SAVE_BUTTON_VARIANTS.saveEnabled,
  );

  const [deleteMessage, setDeleteMessage] = useState(
    DELETE_BUTTON_VARIANTS.deleteEnabled,
  );

  async function handleSave() {
    setIsSaving(true);
    setSaveMessage(SAVE_BUTTON_VARIANTS.saveInProgress);

    let selectedOpportunityProductId = selectedOpportunityProduct?.id!;

    try {
      if (!opportunityId) {
        throw new Error('No opportunity to update');
      }

      if (!selectedOpportunityProduct) {
        const insertedProduct =
          await DirectusApi.createItem<OpportunityProductRaw>(
            Collections.R_OPPORTUNITY_PRODUCTS,
            {
              opportunity: opportunityId,
              property_unit: unitData.id,
            },
          );
        selectedOpportunityProductId = insertedProduct.id;
      }

      await updateFeedbackElements(
        feedbackElements,
        selectedOpportunityProductId,
      );

      let statusId;
      if (status !== null) {
        statusId = await NextDomApi.getItemIdWithCode(
          Collections.DOM_MATCH_STATUS,
          status === OpportunityProductStatus.Interested
            ? Constants.INTERESTED
            : Constants.NOT_INTERESTED,
        );
      }
      await DirectusApi.updateItem(
        Collections.R_OPPORTUNITY_PRODUCTS,
        selectedOpportunityProductId,
        {
          status: statusId ?? null,
        },
      );

      setSaveMessage(SAVE_BUTTON_VARIANTS.saveSuccess);
      getExistingFeedbacks(selectedOpportunityProductId);
      const updatedOpportunityProducts = await getOpportunityProducts(
        opportunityId,
      );

      setVisitDetails((prev) => {
        return { ...prev, opportunityProducts: updatedOpportunityProducts };
      });

      setTimeout(() => {
        setSaveMessage(SAVE_BUTTON_VARIANTS.saveEnabled);
        setIsSaving(false);
      }, TIMEOUT_SAVE_BUTTON);
    } catch (error) {
      console.error(error);
      setSaveMessage(SAVE_BUTTON_VARIANTS.saveError);
      setTimeout(() => {
        setSaveMessage(SAVE_BUTTON_VARIANTS.saveEnabled);
        setIsSaving(false);
      }, TIMEOUT_SAVE_BUTTON);
    }
  }

  const initialStatus = useMemo<OpportunityProductStatus | null>(() => {
    switch (selectedOpportunityProduct?.status?.code) {
      case Constants.INTERESTED:
        return OpportunityProductStatus.Interested;
      case Constants.NOT_INTERESTED:
        return OpportunityProductStatus.NotInterested;
      default:
        return null;
    }
  }, [selectedOpportunityProduct?.status?.code]);

  const [status, setStatus] = useState<OpportunityProductStatus | null>(
    initialStatus,
  );

  const [isDeleteButtonPressed, setIseDeleteButtonPressed] =
    useState<boolean>(false);

  const handleDeleteButtonClick = () => {
    setIseDeleteButtonPressed(true);
  };

  const deleteUnit = useCallback(
    async (
      unit: OpportunityProductRaw | undefined,
      opportunityId: number | undefined,
      products: OpportunityProductRaw[] | undefined,
    ) => {
      setIseDeleteButtonPressed(false);
      if (!unit || !opportunityId || !products) {
        return;
      }
      setIsSaving(true);
      setOpportunityProductDeletionInProgress(true);
      setDeleteMessage(DELETE_BUTTON_VARIANTS.deleteInProgress);

      try {
        // FLOW: [CRM - Remove interest from product]
        await updateOpportunity({
          id: opportunityId,
          products: (products ?? []).filter((p) => p.id !== unit.id),
        });

        // Insert setTimeout because having started the flow  "Opportunities
        // Employees" and it is not of type "filter" but "action", we set a
        // setTimeout of 2 seconds so that we wait for the update of the db
        // and update the opportunity again
        setTimeout(async () => {
          const opportunityProducts = await getOpportunityProducts(
            opportunityId,
          );
          setVisitDetails((prev) => ({
            ...prev,
            opportunityProducts: opportunityProducts,
          }));
          setFeedbackElements({});
          setStatus(null);
          setDeleteMessage(DELETE_BUTTON_VARIANTS.deleteEnabled);
          setOpportunityProductDeletionInProgress(false);

          setIsSaving(false);
        }, TIMING_REFRESH_OPPORTUNITY_EMPLOYEE);
      } catch (err) {
        console.error(t('common.errorSaving'));
        setDeleteMessage(DELETE_BUTTON_VARIANTS.deleteError);
        setDeleteMessage(DELETE_BUTTON_VARIANTS.deleteEnabled);
        setOpportunityProductDeletionInProgress(false);

        setIsSaving(false);
      }
    },
    [
      DELETE_BUTTON_VARIANTS.deleteEnabled,
      DELETE_BUTTON_VARIANTS.deleteError,
      DELETE_BUTTON_VARIANTS.deleteInProgress,
      setFeedbackElements,
      setOpportunityProductDeletionInProgress,
      setVisitDetails,
      t,
    ],
  );

  const handleDelete = () => {
    deleteUnit(
      selectedOpportunityProduct,
      opportunityId,
      visitDetails?.opportunityProducts,
    );
  };

  return {
    status,
    setStatus,
    handleSave,
    isSaving,
    saveMessage,
    isDeleteButtonPressed,
    handleDeleteButtonClick,
    deleteMessage,
    handleDelete,
    setIseDeleteButtonPressed,
  };
};
