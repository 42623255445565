import { MainNavigationButton } from 'components/MainNavigationButton';
import { AppContext, AppContextType } from 'context';
import { useContext, useEffect, useState } from 'react';
import { Col, Container, Nav, Navbar, Row } from 'react-bootstrap';

export const MainNavigation = () => {
  const { useStyles, visitDetails } = useContext<AppContextType>(AppContext);

  const classes = useStyles();
  const { configuration } = useContext<AppContextType>(AppContext);

  const [isFirstRendering, setIsFirstRendering] = useState(true);

  useEffect(() => {
    setIsFirstRendering(false);
  }, []);

  return (
    <footer
      className={`position-absolute bottom-0 mt-auto w-100 p-5 ${classes.footer}`}
    >
      <Navbar className={`justify-content-center ${classes.nav}`}>
        <Nav>
          <Container fluid>
            <Row>
              {configuration.pages.map((page, id) => {
                return (
                  <Col
                    key={id}
                    xs={4}
                    sm={3}
                    lg={2}
                    xl="auto"
                    className="d-flex justify-content-center"
                  >
                    <MainNavigationButton
                      linkTo={page.path}
                      delay={id / 4}
                      imgSrc={page.icon}
                      title={page.title}
                    />
                  </Col>
                );
              })}
              {visitDetails && (
                <Col>
                  <MainNavigationButton
                    linkTo={'/user-selections'}
                    delay={
                      isFirstRendering ? configuration.pages.length / 4 : 0.25
                    }
                    imgSrc={
                      !window.electron
                        ? '/image/navigation/user.svg'
                        : 'asset://image/navigation/user.svg'
                    }
                    title={visitDetails.name}
                    key={visitDetails.name}
                  />
                </Col>
              )}
            </Row>
          </Container>
        </Nav>
      </Navbar>
    </footer>
  );
};
