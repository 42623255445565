import { AppContext, AppContextType } from 'context';
import { AnimatePresence, motion } from 'framer-motion';
import { useContext } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import { LanguageType } from 'types/configuration';

export const LanguageMenu = ({
  showLanguageMenu,
  setShowLanguageMenu,
  setShowSideMenu,
  availableLanguages,
}: {
  showLanguageMenu: boolean;
  setShowSideMenu: React.Dispatch<React.SetStateAction<boolean>>;
  setShowLanguageMenu: React.Dispatch<React.SetStateAction<boolean>>;
  availableLanguages: LanguageType[] | undefined;
}) => {
  const { useStyles, t, setSelectedLanguage } =
    useContext<AppContextType>(AppContext);

  const classes = useStyles();

  const localClasses = useLocalStyles();

  function handleLanguageCardClick(language: {
    code: string;
    language: string;
    flagImgCode?: string | undefined;
  }) {
    setSelectedLanguage(language);
  }

  return (
    <AnimatePresence>
      <motion.div
        initial={{ right: -200, opacity: 0 }}
        animate={{
          right: showLanguageMenu ? 0 : -200,
          opacity: showLanguageMenu ? 1 : 0,
        }}
        transition={{
          duration: 0.3,
          ease: [0.43, 0.13, 0.23, 0.96],
        }}
        className={`${localClasses.languageMenu} ${classes.nunitoSans}`}
        key="qrCode"
      >
        <Container className={localClasses.languageMenuContainer}>
          <Row>
            <div className={localClasses.visitsMenuTitle}>
              <i className="las la-calendar" /> {t('selectLanguage')}
            </div>
          </Row>
          {availableLanguages &&
            availableLanguages.map((language, idx) => {
              return (
                <motion.div
                  whileTap={{ scale: 0.95 }}
                  key={language.code}
                  onClick={() => {
                    handleLanguageCardClick(language);
                    setShowLanguageMenu(false);
                    setShowSideMenu(false);
                  }}
                >
                  <motion.div
                    initial={{ opacity: 0, x: 100 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{
                      duration: 0.8,
                      delay: idx / 8,
                      ease: [0, 0.71, 0.2, 1.01],
                    }}
                  >
                    <Row
                      className={`${localClasses.eventCard} align-items-center`}
                    >
                      <Col>
                        <img
                          src={`${
                            window.electron ? 'asset://' : ''
                          }image/flags/${
                            language?.flagImgCode ?? language.code
                          }.svg`}
                          alt={language?.flagImgCode ?? language.code}
                          style={{
                            width: 30,
                            height: 30,
                            borderRadius: '100%',
                          }}
                          className="me-2"
                        />
                        {language.language}
                      </Col>
                    </Row>
                  </motion.div>
                </motion.div>
              );
            })}
        </Container>
      </motion.div>
    </AnimatePresence>
  );
};

const useLocalStyles = createUseStyles({
  visitsMenuTitle: {
    color: '#B5B5C3',
    margin: '12px 0px 10px 0',
  },

  nameColumn: {
    padding: '0 5px',
    textAlign: 'right',
  },

  languageMenu: {
    width: 200,
    height: 175,
    position: 'absolute',
    backgroundColor: 'white',
    bottom: 150,
    borderRadius: '10px 0 0 10px',
    zIndex: 9999,
    fontSize: 14,
    overflow: 'auto',
    userSelect: 'none',
  },
  languageMenuContainer: {
    fontWeight: 600,
  },
  eventCard: {
    backgroundColor: '#F0F0F3',
    padding: '12px 10px',
    borderRadius: 8,
    margin: '0 0 8px 0',
    cursor: 'pointer',
  },
  hourColumn: {
    color: '#B5B5C3',
    padding: '0 5px',
  },
  visitsMenuFooter: {
    position: 'sticky',
    bottom: 0,
    backgroundColor: 'white',
    padding: 12,
    boxShadow: '0px -4px 10px rgba(0, 0, 0, 0.15)',
  },

  logoutButton: {
    width: '100%',
    backgroundColor: '#222222',
    border: 'none',
    '&:hover': {
      backgroundColor: '#222222',
      border: 'none',
    },
    '&:active': {
      backgroundColor: '#222222!important',
      border: 'none',
    },
  },

  syncButton: {
    backgroundColor: 'rgb(240 240 243)',
    color: '#212529',
    border: 'none',
    '&:hover': {
      backgroundColor: 'rgb(240 240 243)',
      color: '#212529',
      border: 'none',
    },
    '&:active': {
      backgroundColor: 'rgb(240 240 243)!important',
      color: '#212529!important',
      border: 'none',
    },
  },
});
