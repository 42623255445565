import { addSeconds, parseISO } from 'date-fns';

/**
 * Available storage types
 */
export enum StorageType {
  LocalStorage = 'localStorage',
  SessionStorage = 'sessionStorage',
  MemoryStorage = 'memoryStorage',
}

/**
 * Possible types of objects that can be archived
 */
type LocalCacheItemValueType =
  | string
  | number
  | boolean
  | Record<string, unknown>
  | unknown[];

/**
 * Common options related to LocalCache
 */
type LocalCacheOptions = {
  customNamespace?: string;
  storageType?: StorageType;
};

export type StorageBackend = Storage;

class MemoryStorage implements Storage {
  storage: Record<string, any> = {};

  get length(): number {
    return Object.keys(this.storage).length;
  }

  clear(): void {
    this.storage = {};
  }

  getItem(key: string): string | null {
    return this.storage[key] || null;
  }

  key(index: number): string | null {
    return Object.keys(this.storage)[index] || null;
  }

  removeItem(key: string): void {
    delete this.storage[key];
  }

  setItem(key: string, value: string): void {
    this.storage[key] = value;
  }

  [Symbol.iterator]() {
    const storage = this.storage;
    const keys = Object.keys(storage);
    let index = 0;

    return {
      next() {
        return {
          value: [keys[index], storage[keys[index++]]],
          done: index > keys.length,
        };
      },
    };
  }
}

/**
 * Manage local cache
 */
export class LocalCache implements StorageBackend {
  static LOCAL_CACHE_NAMESPACE_IDENTIFIER: string = 'dt';
  static ONE_MINUTE_IN_SECONDS: number = 60;
  static FIVE_MINUTES_IN_SECONDS: number = LocalCache.ONE_MINUTE_IN_SECONDS * 5;
  static NEVER_EXPIRE: number = -1;
  static DEFAULT_EXPIRE_TIME: number = LocalCache.NEVER_EXPIRE;

  readonly namespace: string;
  readonly defaultExpireTime: number;
  readonly storageType: StorageType;
  readonly storage: Storage;

  memoryStorage: MemoryStorage = new MemoryStorage();

  /**
   * Create a new instance of LocalCache
   * @param namespace The namespace to use within the key.
   * @param defaultExpireTime The default expire time in seconds.
   * @param defaultStorage The default storage type to use.
   */
  constructor(
    namespace: string = LocalCache.LOCAL_CACHE_NAMESPACE_IDENTIFIER,
    defaultExpireTime: number = LocalCache.DEFAULT_EXPIRE_TIME,
    storageType: StorageType = StorageType.LocalStorage,
  ) {
    this.namespace = namespace;
    this.defaultExpireTime = defaultExpireTime;
    this.storageType = storageType;
    this.storage = this.getStorage(storageType);
  }

  /**
   * Create a key based on the namespace, customNamespace and key
   */
  private createKey(key: string, customNamespace?: string): string {
    return [this.namespace, customNamespace, key].filter(Boolean).join('-');
  }

  /**
   * Return the storage based on the storageType
   */
  private getStorage(storageType: StorageType): Storage {
    switch (storageType) {
      case StorageType.LocalStorage:
        return window.localStorage;
      case StorageType.SessionStorage:
        return window.sessionStorage;
      case StorageType.MemoryStorage:
        return this.memoryStorage;
    }
  }

  /**
   * Sets the value of the pair identified by key to value,
   * creating a new key/value pair if none existed for key previously.
   *
   * @param key The key to set.
   * @param value The value to set.
   * @param options.expireTime The time in seconds until the item will expire. If expireTime is === -1 then the item will never expire.
   * @param options.customNamespace The custom namespace to use.
   * @param options.storageType The storage type to use.
   * @returns void.
   * @example
   * ```ts
   * localCache.setItem('test', 'test local', {
   *  expireTime: -1,
   *  customNamespace: 'test',
   *  storageType: StorageType.LocalStorage
   * });
   * ```
   */
  setItem(
    key: string,
    value: LocalCacheItemValueType,
    options?: LocalCacheOptions & { expireTime?: number },
  ): void {
    const { expireTime, customNamespace } = options ?? {};

    this.storage.setItem(
      this.createKey(key, customNamespace),
      JSON.stringify({
        value,
        expireTime:
          expireTime === undefined || expireTime === null
            ? this.defaultExpireTime
            : addSeconds(new Date(), expireTime),
      }),
    );
  }

  /**
   * Returns the current value associated with the given key, or null if the given key does not exist.
   * @param key The key to retrieve.
   * @param options.customNamespace The custom namespace to use.
   * @param options.storageType The storage type to use.
   * @example
   * ```ts
   * localCache.getItem('test', {
   *  customNamespace: 'test',
   *  storageType: StorageType.LocalStorage
   * });
   * ```
   * @returns The value associated with the key, or null if the key does not exist.
   */
  getItem<T extends LocalCacheItemValueType>(
    key: string,
    options?: LocalCacheOptions,
  ): T | null {
    const { customNamespace } = options ?? {};

    const item = this.storage.getItem(this.createKey(key, customNamespace));

    if (!item) {
      return null;
    }

    const { value, _expireTime } = JSON.parse(item);

    return value as T;
  }

  /**
   * Removes the key/value pair with the given key, if a key/value pair with the given key exists.
   *
   * @param key The key to remove.
   * @param options.customNamespace The custom namespace to use.
   * @param options.storageType The storage type to use.
   * @example
   * ```ts
   * localCache.removeItem('test', {
   *  customNamespace: 'test',
   *  storageType: StorageType.LocalStorage
   * })
   * ```
   */
  removeItem(key: string, options?: LocalCacheOptions): void {
    const { customNamespace } = options ?? {};

    this.storage.removeItem(this.createKey(key, customNamespace));
  }

  /**
   * Removes all key/value pairs from the list associated with the object.
   */
  clear(): void {
    if (this.storageType === StorageType.MemoryStorage) {
      return this.storage.clear();
    }

    Object.keys(this.storage).forEach((key) => {
      if (key.startsWith(this.namespace)) {
        this.storage.removeItem(key);
      }
    });
  }

  /**
   * Removes all expired key/value pairs from the list associated with the object.
   *
   * @param force [Default: true] If true, will remove all key/value pairs. If false, will only remove expired key/value pairs.
   * @param options.customNamespace The custom namespace to use.
   * @example
   * ```ts
   * localCache.clean(true, {
   *  customNamespace: 'test'
   * });
   * ```
   */
  clean(
    force: boolean = true,
    options?: Omit<LocalCacheOptions, 'storageType'>,
  ): void {
    const { customNamespace } = options ?? {};

    Object.keys(this.storage).forEach((key) => {
      if (
        key.startsWith(
          [this.namespace, customNamespace].filter(Boolean).join('-'),
        )
      ) {
        const { expireTime } = JSON.parse(this.storage.getItem(key) as string);
        if (
          force ||
          (expireTime !== LocalCache.NEVER_EXPIRE &&
            parseISO(expireTime) < new Date())
        ) {
          this.storage.removeItem(key);
        }
      }
    });
  }

  /** Returns the number of key/value pairs. */
  get length(): number {
    if (this.storageType === StorageType.MemoryStorage) {
      return this.storage.length;
    }

    return Object.keys(this.storage).filter((key) =>
      key.startsWith(this.namespace),
    ).length;
  }

  /** Returns the name of the nth key, or null if n is greater than or equal to the number of key/value pairs. */
  key<T extends LocalCacheItemValueType>(
    index: number,
    options?: LocalCacheOptions,
  ): T | null {
    const { customNamespace } = options ?? {};

    let entries: string[][];

    if (this.storageType === StorageType.MemoryStorage) {
      entries = Object.entries(this.storage.storage);
    } else {
      entries = Object.entries(this.storage);
    }

    const storageItems = entries.filter(([k]) =>
      k.startsWith([this.namespace, customNamespace].filter(Boolean).join('-')),
    );

    const namespacedKey = this.createKey('', customNamespace);

    return this.getItem<T>(
      storageItems[index][0].replace(`${namespacedKey}-`, ''),
      {
        customNamespace,
        storageType: this.storageType,
      },
    );
  }
}

// exports the LocalCache as a singleton
// @example
// ```ts
// import { localCache } from './localCache';
// localCache.setItem('test', 'test local');
// localCache.getItem<string>('test');
// ```
export const localCache = Object.freeze(new LocalCache());

export const sessionCache = Object.freeze(
  new LocalCache(
    LocalCache.LOCAL_CACHE_NAMESPACE_IDENTIFIER,
    LocalCache.DEFAULT_EXPIRE_TIME,
    StorageType.SessionStorage,
  ),
);

export const memoryCache = Object.freeze(
  new LocalCache(
    LocalCache.LOCAL_CACHE_NAMESPACE_IDENTIFIER,
    LocalCache.DEFAULT_EXPIRE_TIME,
    StorageType.MemoryStorage,
  ),
);
