import { ConnectionStatus } from 'authentication/hooks/UseDatahubPolling';
import * as Codes from 'constants/codes';
import { AppContext, AppContextType } from 'context';
import { motion, AnimatePresence } from 'framer-motion';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { VisitRaw } from 'types';
import { localCache } from 'utils/localCache';

export const IdlePage = ({
  setUserVisits,
  setShowSideMenu,
}: {
  setUserVisits: React.Dispatch<React.SetStateAction<VisitRaw[]>>;
  setShowSideMenu: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { configuration, setAuthToken, setUserData, setVisitDetails } =
    useContext<AppContextType>(AppContext);

  const { useStyles, setAuthenticationStatus } =
    useContext<AppContextType>(AppContext);

  const classes = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    setAuthToken(null);
    setUserData(undefined);
    setUserVisits([]);
    setAuthenticationStatus(ConnectionStatus.userOffline);
    setVisitDetails(null);
    localCache.removeItem(Codes.AUTH_TOKEN);
  }, [
    setAuthToken,
    setAuthenticationStatus,
    setUserData,
    setUserVisits,
    setVisitDetails,
  ]);

  return (
    <div
      className="cover-container d-flex w-100 h-100 mx-auto flex-column"
      onClick={() => {
        setShowSideMenu(false);
        localCache.removeItem(Codes.AUTH_TOKEN);
        navigate('/');
      }}
    >
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={classes.background}
          key="video"
          transition={{
            duration: 1.5,
            ease: [0, 0.71, 0.2, 1.01],
          }}
        >
          <video
            playsInline
            autoPlay
            muted
            loop
            className={classes.backgroundObject}
          >
            <source
              src={configuration.idle.video.mp4.src}
              type={configuration.idle.video.mp4.type}
            />
            <source
              src={configuration.idle.video.webm.src}
              type={configuration.idle.video.webm.type}
            />
          </video>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};
