import * as Constants from 'constants/constants';
import { AppContext, AppContextType } from 'context';
import { useContext, useMemo } from 'react';
import { OpportunityProductRaw } from 'types/opportunity';
import { groupByFn } from 'utils/array';

export const UseUserSelectionsPage = () => {
  const { visitDetails, t, configuration } =
    useContext<AppContextType>(AppContext);

  const opportunityProductsGroupedByStatus = useMemo<
    Record<string, OpportunityProductRaw[]>
  >(() => {
    return groupByFn(
      visitDetails?.opportunityProducts?.filter((_i, idx) => idx !== 0) ?? [],
      (vD) => vD?.status?.code ?? Constants.MISSING,
    );
  }, [visitDetails?.opportunityProducts]);

  const opportunityProductsSortedByStatusAndFeedbacks = useMemo<
    OpportunityProductRaw[]
  >(() => {
    return [
      ...(opportunityProductsGroupedByStatus?.interested ?? []).sort(
        (a, b) =>
          (b.positive_feedbacks ?? []).length -
          (a.positive_feedbacks ?? []).length,
      ),
      ...(opportunityProductsGroupedByStatus.missing ?? []),
      ...(opportunityProductsGroupedByStatus[Constants.NOT_INTERESTED] ?? []),
    ];
  }, [opportunityProductsGroupedByStatus]);

  const hideAvailabilities = useMemo(
    () => configuration.general.hideAvailabilities,
    [configuration.general.hideAvailabilities],
  );

  const generalInformationColumns = [
    {
      label: t('propertyUnit.name'),
      key: 'name',
    },
    ...(!hideAvailabilities
      ? [
          {
            label: t('propertyUnit.status'),
            key: 'status',
          },
        ]
      : []),
    {
      label: t('propertyUnit.floor'),
      key: 'floor',
    },
    {
      label: t('propertyUnit.levelsNumber'),
      key: 'levelsNumber',
    },
    {
      label: t('propertyUnit.residentialUseType'),
      key: 'residentialUseType',
    },
    {
      label: t('propertyUnit.usedStatus'),
      key: 'internalStatus',
    },
    {
      label: t('price'),
      key: 'price',
    },
  ];

  const pertinencesFields = [
    {
      label: t('propertyUnit.cellar'),
      key: 'cellarFlag',
    },
    {
      label: t('propertyUnit.terrace'),
      key: 'terraceFlag',
    },
    {
      label: t('propertyUnit.balcony'),
      key: 'balconyFlag',
    },
    {
      label: t('propertyUnit.communalGarden'),
      key: 'communalGardenFlag',
    },
  ];
  const compositionFields = [
    {
      label: t('propertyUnit.apartment'),
      key: 'availableSurface',
    },
    {
      label: t('propertyUnit.roomsNumber'),
      key: 'roomsNumber',
    },
    {
      label: t('propertyUnit.bedroomsNumber'),
      key: 'bedroomsNumber',
    },
    {
      label: t('propertyUnit.bathroomsNumber'),
      key: 'bathroomsNumber',
    },
    {
      label: t('propertyUnit.kitchenType'),
      key: 'kitchenType',
    },
    {
      label: t('propertyUnit.exposureType'),
      key: 'exposureType',
    },
    {
      label: t('propertyUnit.livingAreaPositioning'),
      key: 'laPositioningType',
    },
  ];

  const internalStatusFields = [
    {
      label: t('propertyUnit.fixturesMaterial'),
      key: 'fixturesMaterial',
    },
  ];

  const internalFeaturesFields = [
    {
      label: t('propertyUnit.securityDoor'),
      key: 'securityDoorFlag',
    },
  ];

  const parkingFields = [
    {
      label: t('propertyUnit.boxAvailability'),
      key: 'boxFlag',
    },
    {
      label: t('propertyUnit.parkingSpace'),
      key: 'parkingSpaceFlag',
    },
    {
      label: t('propertyUnit.parkingSpaceMoto'),
      key: 'parkingSpaceMotoFlag',
    },
    {
      label: t('propertyUnit.electricChargeFlag'),
      key: 'electricChargeFlag',
    },
  ];

  const energyRating = [
    {
      label: t('propertyUnit.type'),
      key: 'energyRating',
    },
    {
      label: t('propertyUnit.status'),
      key: 'energyRatingStatus',
    },
  ];

  const surfacesFields = [
    {
      label: t('livingSurface'),
      key: 'livingSurface',
    },
    {
      label: t('porchSurface'),
      key: 'porchSurface',
    },
  ];

  return {
    generalInformationColumns,
    pertinencesFields,
    compositionFields,
    internalStatusFields,
    internalFeaturesFields,
    parkingFields,
    energyRating,
    surfacesFields,
    visitDetails,
    opportunityProductsSortedByStatusAndFeedbacks,
  };
};
