import { LeftColumn } from 'components/LeftColumn';
import { MobileBottomContent } from 'components/MobileBottomContent';
import { AppContext, AppContextType } from 'context';
import { LayoutGroup } from 'framer-motion';
import { KeyPlanResponsiveMode } from 'pages/KeyPlan//KeyPlanResponsiveMode';
import { KeyPlanFullPageMode } from 'pages/KeyPlan/KeyPlanFullPageMode';
import React, { Fragment, useContext } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { KeyplanType } from 'types/configuration';

export const KeyPlan: React.FC<KeyplanType> = ({
  backgroundImage,
  backgroundResponsiveImage,
  data,
  availabilityColors,
  title,
  isFullScreen,
  showAlert,
  setShowAlert,
  buttonsContainerPosition,
  gestureWindowHeight,
  className,
}) => {
  const { configuration } = useContext<AppContextType>(AppContext);

  const { useStyles } = useContext<AppContextType>(AppContext);

  const classes = useStyles();

  return (
    <div className={className}>
      <LayoutGroup>
        <Fragment>
          <Container fluid>
            <Row className="h-100 overflow-hidden">
              <LeftColumn title={title}>
                <div id="floor-menu" />
              </LeftColumn>
              {!window.electron && !isFullScreen && (
                <Col
                  xl={10}
                  md={12}
                  className="text-center d-flex align-items-center m-auto flex-column"
                >
                  <KeyPlanResponsiveMode
                    backgroundImage={backgroundImage}
                    backgroundResponsiveImage={backgroundResponsiveImage}
                    data={data}
                    availabilityColors={availabilityColors}
                    showAlert={showAlert}
                    setShowAlert={setShowAlert}
                  />
                </Col>
              )}
            </Row>
          </Container>

          <div className={classes.background}>
            {configuration.general.backgroundImage && (
              <img
                src={configuration.general.backgroundImage}
                alt="Gallery"
                className={classes.backgroundObject}
              />
            )}
          </div>
          <MobileBottomContent />
        </Fragment>

        {(window.electron || isFullScreen) && (
          <KeyPlanFullPageMode
            backgroundImage={backgroundImage}
            data={data}
            availabilityColors={availabilityColors}
            buttonsContainerPosition={buttonsContainerPosition}
            gestureWindowHeight={gestureWindowHeight}
          />
        )}
      </LayoutGroup>
    </div>
  );
};
