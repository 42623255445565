// eslint-disable-next-line no-restricted-imports
import { LeftColumn } from '../components/LeftColumn';
import { MobileBottomContent } from 'components/MobileBottomContent';
import { MotionDiv } from 'components/MotionDiv';
import { AppContext, AppContextType } from 'context';
import React, { Fragment, useContext } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import { WebviewType } from 'types/configuration';
import 'video-react/dist/video-react.css';

export const Webview: React.FC<WebviewType> = ({ url, title, className }) => {
  const { useStyles } = useContext<AppContextType>(AppContext);
  const { configuration } = useContext<AppContextType>(AppContext);

  const classes = useStyles();
  const localClasses = useLocalStyles();

  return (
    <MotionDiv className={className ?? ''}>
      <Fragment>
        <Container fluid>
          <Row className="overflow-hidden">
            <LeftColumn title={title} />

            <Col
              xl={10}
              md={12}
              className={`mt-5 ${localClasses.iframeContainer}`}
            >
              <iframe src={url} title="Virtual Tour" />
            </Col>
          </Row>
        </Container>

        <div className={classes.background}>
          {configuration.general.backgroundImage && (
            <img
              src={configuration.general.backgroundImage}
              alt="Gallery"
              className={classes.backgroundObject}
            />
          )}
        </div>

        <MobileBottomContent />
      </Fragment>
    </MotionDiv>
  );
};

const useLocalStyles = createUseStyles({
  iframeContainer: {
    '& iframe': {
      height: '90vh',
      width: '100%',
    },
  },
});
