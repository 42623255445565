import { AppContext, AppContextType } from 'context';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useContext } from 'react';
import { Alert } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';

type FullscreenAlertType = {
  setShowAlert: React.Dispatch<React.SetStateAction<boolean>>;
};

export const FullscreenAlert: React.FC<FullscreenAlertType> = ({
  setShowAlert,
}) => {
  const classes = useStyles();

  const { t } = useContext<AppContextType>(AppContext);

  return (
    <AnimatePresence>
      <motion.div
        initial={{ bottom: -200, opacity: 0 }}
        animate={{ top: 0, opacity: 1 }}
        transition={{
          duration: 0.8,
          delay: 0,
        }}
        key="fullscreenAlert"
        className="d-none d-lg-block"
      >
        <Alert variant="warning" className={classes.alertWarning}>
          <span
            className={classes.alertTextSpan}
            dangerouslySetInnerHTML={{ __html: t('fullscreenAlert') }}
          />

          <span
            className={`las la-times la-2x ${classes.alertCloseIcon}`}
            onClick={() => setShowAlert(false)}
          />
        </Alert>
      </motion.div>
    </AnimatePresence>
  );
};

const useStyles = createUseStyles({
  alertWarning: {
    position: 'absolute',
    left: 0,
    right: 0,
    margin: 'auto',
    bottom: '50px',
    width: 'fit-content',
    display: 'flex',
    zIndex: 100,
    alignItems: 'center',
    fontFamily: 'system-ui !important',
    borderRadius: '100vw',
    backgroundColor: 'rgba(255, 255, 255, 0.6)',
    border: 'none',
    color: '#000',
    textShadow: '0 1px 0 rgba(255, 255, 255, 0.4)',
    padding: '1rem 1rem 1rem 2rem',
    backdropFilter: 'blur(5px)',
    '& .las': {
      marginLeft: '0.6em',
      backgroundColor: '#fff',
      borderRadius: '100vw',
      boxShadow: '0 0 0 10px #fff',
      transition: 'box-shadow 0.3s ease-in-out',
    },
    '& .las:hover': {
      boxShadow: '0 0 0 8px #fff',
    },
  },
  alertTextSpan: {
    marginRight: '5px',
  },
  alertCloseIcon: {
    cursor: 'pointer',
  },
});
