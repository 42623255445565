import { AppContext, AppContextType } from 'context';
import { motion } from 'framer-motion';
import { Orientation } from 'pages/Flipbook';
import { useContext, useState } from 'react';
import { Form, Row } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import HTMLFlipBook from 'react-pageflip';

export type FlipbookComponentProps = {
  width: number;
  height: number;
  images: string[];
  flipBook: React.MutableRefObject<undefined>;
  setOrientation: React.Dispatch<Orientation>;
  startPage: number;
};

export const FlipbookComponent: React.FC<FlipbookComponentProps> = ({
  width,
  height,
  images,
  flipBook,
  setOrientation,
  startPage,
}) => {
  const { useStyles } = useContext<AppContextType>(AppContext);

  const { configuration, t } = useContext<AppContextType>(AppContext);

  const useLocalStyles = createUseStyles({
    mobileSelect: {
      backgroundColor: 'transparent',
      width: '260px',
      border: 'none',
      borderRadius: '0',
      textAlign: 'center',
      borderBottom: `1px solid ${configuration.style.secondaryColor}`,
      '&:focus': {
        borderColor: `${configuration.style.secondaryColor}`,
        boxShadow: 'none',
      },
    },
    flipbookContainer: {
      marginTop: '4vh',
      '@media screen and (min-width: 768px) and (max-width: 1200px)': {
        marginTop: '0!important',
      },
    },
  });

  const classes = useStyles();
  const localClasses = useLocalStyles();
  const [currentPage, setCurrentPage] = useState<number>(0);

  return (
    <div className={localClasses.flipbookContainer}>
      {/* @ts-ignore */}
      <HTMLFlipBook
        width={width}
        height={height}
        ref={flipBook}
        className={classes.flipbook}
        onFlip={(page) => {
          setCurrentPage(page.data);
        }}
        onInit={(e) => {
          setOrientation(e.data.mode);
        }}
        startPage={startPage}
        autoSize={false}
      >
        {images.map((image, id) => (
          <div key={id}>
            <img
              src={image}
              alt={`${id}`}
              style={{ width: width, height: height }}
            />
          </div>
        ))}
      </HTMLFlipBook>
      <Row
        className={`justify-content-center d-none d-xl-flex ${classes.flipbookButtonsContainer}`}
      >
        {images.map((image, id) => {
          //We keep only odd numbers dots as, in terms of navigation, there's no need for the even ones too
          if (id % 2 === 0) {
            return null;
          }
          return (
            <motion.div
              whileTap={{ scale: 0.8 }}
              key={image}
              onClick={() => {
                //@ts-ignore
                flipBook.current.pageFlip().turnToPage(id);
                if (flipBook.current) {
                  setCurrentPage(
                    //@ts-ignore
                    flipBook.current.pageFlip().getCurrentPageIndex(),
                  );
                }
              }}
              className={`${classes.flipbookButtons} 
                ${currentPage === id - 1 && classes.flipbookActiveButton}`}
            >
              {id}
            </motion.div>
          );
        })}
      </Row>
      <Row className="justify-content-center d-flex d-xl-none mt-5">
        <Form.Select
          aria-label="page selection"
          className={`${localClasses.mobileSelect} ${classes.mobileSelect}`}
          value={currentPage > 0 ? currentPage : t('selectPage')}
          onChange={(e) => {
            const id = parseInt(e.target.value);
            if (!isNaN(id)) {
              //@ts-ignore
              flipBook.current.pageFlip().turnToPage(id);
              if (flipBook.current) {
                setCurrentPage(
                  //@ts-ignore
                  flipBook.current.pageFlip().getCurrentPageIndex(),
                );
              }
            }
          }}
        >
          <option value="">{t('selectPage')}</option>
          {images.map((_image, id) => {
            if (id === 0) {
              return null;
            }
            return (
              <option value={id} key={id}>
                {`${t('page')} ${id}`}
              </option>
            );
          })}
        </Form.Select>
      </Row>
    </div>
  );
};
