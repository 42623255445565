import { MainNavigation } from 'components/MainNavigation';
import { MotionDiv } from 'components/MotionDiv';
import { AppContext, AppContextType } from 'context';
import { AnimatePresence } from 'framer-motion';
import React, { Fragment, useContext } from 'react';
import { BasicPageProps } from 'types';

export const HomePage: React.FC<BasicPageProps> = () => {
  const { useStyles } = useContext<AppContextType>(AppContext);

  const { configuration } = useContext<AppContextType>(AppContext);
  const classes = useStyles();

  return (
    <AnimatePresence>
      <MotionDiv className="homepage" key="homepage">
        <Fragment>
          <div>
            <img
              src={configuration.general.mainLogo}
              className={`img-fluid ${classes.homeLogo}`}
              alt="logo"
            />
          </div>
          <div className={classes.background}>
            {configuration.home.backgroundImage && (
              <img
                src={configuration.home.backgroundImage}
                alt="Home background"
                className={classes.backgroundObject}
              />
            )}
          </div>
        </Fragment>
      </MotionDiv>
      <MainNavigation />
    </AnimatePresence>
  );
};
