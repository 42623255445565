import { HomeButton } from 'components/HomeButton';
import { AppContext, AppContextType } from 'context';
import { Fragment, ReactNode, useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';

type LeftColumnProps = {
  title: string | Record<string, string>;
  children?: ReactNode;
  className?: string;
};

export const LeftColumn: React.FC<LeftColumnProps> = ({
  children,
  title,
  className,
}: LeftColumnProps) => {
  const { useStyles } = useContext<AppContextType>(AppContext);
  const classes = useStyles();
  const localClasses = useLocalStyles();

  const { configuration, tConfig } = useContext<AppContextType>(AppContext);
  const navigate = useNavigate();

  return (
    <Fragment>
      <Col
        xl={2}
        md={12}
        className={`d-flex flex-column ${localClasses.leftColumn} ${
          className ? className : ''
        }`}
      >
        <div className="text-center">
          <Row>
            {/* Logo for large screens */}
            <Col className="d-none d-xl-block">
              <img
                src={
                  configuration.general.additionalLogo === ''
                    ? configuration.general.mainLogo
                    : configuration.general.additionalLogo
                }
                className={`img-fluid ${classes.mainLogo}`}
                alt="logo"
              />
            </Col>

            {/* Clickable logo for small screens */}
            <Col className="d-block d-xl-none mt-3">
              <img
                src={
                  configuration.general.additionalLogo === ''
                    ? configuration.general.mainLogo
                    : configuration.general.additionalLogo
                }
                className={`img-fluid ${classes.mainLogo}`}
                alt="logo"
                onClick={() => {
                  navigate('/');
                }}
              />
            </Col>
          </Row>
          <Row>
            <span className={`${classes.pageTitle} my-4 my-xl-0`}>
              {tConfig(title).toUpperCase()}
            </span>
          </Row>
          <Row>
            <Col>{children}</Col>
          </Row>
        </div>

        <div className="mt-auto d-none d-xl-block">
          <Row>
            <HomeButton />
          </Row>
        </div>
      </Col>
    </Fragment>
  );
};

const useLocalStyles = createUseStyles({
  leftColumn: {
    '@media screen and (min-width:1200px)': {
      height: '100vh',
    },
    '@media screen and (max-height:820px)': {
      overflow: 'auto',
    },
  },
});
