import {
  IMAGE_RATIO,
  PlansButtonsContainer,
} from 'components/PlansButtonsContainer';
import * as Constants from 'constants/constants';
import { AppContext, AppContextType } from 'context';
import { AnimatePresence, motion } from 'framer-motion';
import { MarkerWindow } from 'pages/KeyPlan/GestureWindow';
import { useKeyPlanState } from 'pages/KeyPlan/hooks/useKeyplanState';
import React, { useContext } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import {
  AvailabilityColorsType,
  Keyplan,
  buttonsContainerPositionType,
} from 'types/configuration';

export const WINDOW_OFFSET = 50;
export const WINDOW_OFFSET_MULTIPLIER = 150;

export const THUMB_UP_ICON_CODE = 'f164';
export const THUMB_DOWN_ICON_CODE = 'f165';

type KeyPlanFullPageModeProps = {
  backgroundImage: string;
  data: Keyplan[];
  availabilityColors: AvailabilityColorsType;
  buttonsContainerPosition: buttonsContainerPositionType;
  gestureWindowHeight?: number;
};

export const generateMarkerStyle = (
  backgroundColor: string,
  iconCode: string,
  position: {
    top: number;
    left: number;
  },
) => ({
  '&::after': {
    color: 'white',
    content: `"\\${iconCode}"`,
    top: position.top,
    left: position.left,
    width: 20,
    height: 20,
    position: 'absolute',
    fontSize: 15,
    fontFamily: 'Line Awesome Free',
    borderRadius: '100%',
    backgroundColor,
    textAlign: 'center',
  },
});

export const KeyPlanFullPageMode: React.FC<KeyPlanFullPageModeProps> = ({
  backgroundImage,
  data,
  availabilityColors,
  buttonsContainerPosition,
  gestureWindowHeight,
}) => {
  const { useStyles, markerWindows, setMarkerWindows } =
    useContext<AppContextType>(AppContext);

  const classes = useStyles();

  const {
    currentImage,
    setCurrentImage,
    zoomedMenu,
    setZoomedMenu,
    currentMarkers,
    setCurrentMarkers,

    dataWithAvailabilities,
    plansData,
    availabilityData,
    opportunityProductDeletionInProgress,
    setOpportunityProductDeletionInProgress,
    isAddedToOpportunityProducts,
    hideAvailabilities,
  } = useKeyPlanState(backgroundImage, data, availabilityColors);

  // eslint-disable-next-line no-restricted-globals
  const { width } = screen;

  const localClasses = useLocalStyles({ width });

  return (
    <div className="cover-container d-flex w-100 h-100 mx-auto flex-column">
      <Container fluid className="mt-auto h-100">
        <Row className="h-100 overflow-hidden">
          <Col
            md="12"
            className="d-flex align-items-center justify-content-center p-0"
          >
            <AnimatePresence>
              {currentMarkers.map((cM, idx) => (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  className={`${classes.planMarker} 
                  
                  ${
                    localClasses[
                      isAddedToOpportunityProducts(
                        cM.title,
                      ) as keyof typeof localClasses
                    ]
                  }
                  
                  `}
                  style={{
                    left: (width / 100) * parseFloat(cM.left),
                    top: (width / IMAGE_RATIO / 100) * parseFloat(cM.top),
                    border: `3px solid ${
                      hideAvailabilities
                        ? availabilityColors.unknown
                        : cM.availabilityColor
                    }`,
                    cursor: 'pointer',
                    userSelect: 'none',
                  }}
                  transition={{
                    duration: 0.8,
                    delay: 1 + idx / 10,
                  }}
                  key={cM.title}
                  onClick={() => {
                    setMarkerWindows((prev) => {
                      return [
                        ...prev.filter((gW) => gW !== cM.title),
                        cM.title,
                      ];
                    });
                  }}
                >
                  {cM.title}
                </motion.div>
              ))}
            </AnimatePresence>
          </Col>
        </Row>
      </Container>

      {markerWindows.map((gW, idx) => (
        <MarkerWindow
          image={gW}
          key={gW}
          initialLeft={idx * WINDOW_OFFSET_MULTIPLIER + WINDOW_OFFSET}
          plansData={plansData}
          idx={idx}
          gestureWindowHeight={gestureWindowHeight}
          availabilityData={availabilityData}
          opportunityProductDeletionInProgress={
            opportunityProductDeletionInProgress
          }
          setOpportunityProductDeletionInProgress={
            setOpportunityProductDeletionInProgress
          }
        />
      ))}

      <PlansButtonsContainer
        setCurrentImage={setCurrentImage}
        setCurrentMarkers={setCurrentMarkers}
        dataWithAvailabilities={dataWithAvailabilities}
        zoomedMenu={zoomedMenu}
        setZoomedMenu={setZoomedMenu}
        width={width}
        buttonsContainerPosition={buttonsContainerPosition}
      />

      <AnimatePresence>
        <div className={classes.background} style={{ background: 'none' }}>
          <motion.img
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              duration: 2,
            }}
            src={currentImage}
            alt={currentImage}
            className={localClasses.keyplanFullBackground}
            key={currentImage}
          />
        </div>
      </AnimatePresence>
    </div>
  );
};

const position = {
  top: -10,
  left: -10,
};
const useLocalStyles = createUseStyles({
  [Constants.INTERESTED]: generateMarkerStyle(
    Constants.GREEN_COLOR,
    THUMB_UP_ICON_CODE,
    position,
  ),
  [Constants.NOT_INTERESTED]: generateMarkerStyle(
    Constants.RED_COLOR,
    THUMB_DOWN_ICON_CODE,
    position,
  ),
  [Constants.MISSING]: generateMarkerStyle(
    Constants.GREY_COLOR,
    THUMB_UP_ICON_CODE,
    position,
  ),

  keyplanFullBackground: ({ width }: { width: number }) => ({
    width: width,
  }),
});
