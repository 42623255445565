export const dom = {
  'availabilityStatuses.available': 'Disponibile',
  'availabilityStatuses.deal-closed': 'Trattativa chiusa',
  'availabilityStatuses.off-market': 'Off Market',
  'availabilityStatuses.rented': 'Locato',
  'availabilityStatuses.reserved': 'Prenotato',
  'availabilityStatuses.sold': 'Venduto',
  'availabilityStatuses.unavailable': 'Non Disponibile',
  'availabilityStatuses.under-negotiation': 'In trattativa',
  'availabilityTypes.business-lease': 'Business Lease',
  'availabilityTypes.letting': 'Locazione',
  'availabilityTypes.letting-sale': 'Locazione/Vendita',
  'availabilityTypes.sale': 'Vendita',
  'availabilityTypes.sub-lease': 'Sublocazione',
  'instructionStatuses.active': 'Attivo',
  'instructionStatuses.deleted': 'Cancellato',
  'instructionStatuses.draft': 'Bozza',
  'instructionStatuses.ended': 'Concluso',
  'instructionStatuses.on-going-negotiation': 'In Negoziazione',
  'instructionStatuses.undefined': 'Non definito',
  'instructionTypes.co-exclusive': 'Co-esclusiva',
  'instructionTypes.direct': 'Diretta',
  'instructionTypes.exclusive': 'Esclusiva',
  'instructionTypes.non-exclusive': 'Non esclusiva',
  'instructionTypes.open': 'Aperta',
  'instructionTypes.research-only': 'Uso ricerca',
  'instructionTypes.unknown': 'Sconosciuto',
  'instructionTypes.verbal': 'Verbale',
  'floorTypes.lower-floor': 'Piano basso',
  'floorTypes.upper-floor': 'Piano alto',
  'floors.-1': 'S1',
  'floors.-10': 'S10',
  'floors.-2': 'S2',
  'floors.-3': 'S3',
  'floors.-4': 'S4',
  'floors.-5': 'S5',
  'floors.-6': 'S6',
  'floors.-7': 'S7',
  'floors.-8': 'S8',
  'floors.-9': 'S9',
  'floors.1': 'P1',
  'floors.10': 'P10',
  'floors.11': 'P11',
  'floors.12': 'P12',
  'floors.13': 'P13',
  'floors.14': 'P14',
  'floors.15': 'P15',
  'floors.16': 'P16',
  'floors.17': 'P17',
  'floors.18': 'P18',
  'floors.19': 'P19',
  'floors.2': 'P2',
  'floors.20': 'P20',
  'floors.21': 'P21',
  'floors.22': 'P22',
  'floors.23': 'P23',
  'floors.24': 'P24',
  'floors.25': 'P25',
  'floors.26': 'P26',
  'floors.27': 'P27',
  'floors.28': 'P28',
  'floors.29': 'P29',
  'floors.3': 'P3',
  'floors.30': 'P30',
  'floors.4': 'P4',
  'floors.5': 'P5',
  'floors.6': 'P6',
  'floors.7': 'P7',
  'floors.8': 'P8',
  'floors.9': 'P9',
  'floors.all': 'Cielo-Terra',
  'floors.ground': 'PT',
  'floors.mezzanine': 'PM',
  'floors.raised-ground': 'PR',
  'floors.range': 'Intervallo',
  'floors.rooftop': 'RT',
  'priceTypes.asking': 'Richiesta',
  'mainUnitUses.living': 'Living',
  'mainUnitUses.logistics': 'Logistica',
  'mainUnitUses.office': 'Uffici',
  'mainUnitUses.parking': 'Parcheggio',
  'mainUnitUses.retail': 'Retail',
  'mainUnitUses.storage': 'Deposito',
  'usedStatuses.good-condition': 'Buono / Abitabile',
  'usedStatuses.mint-refurbished': 'Ottimo / Ristrutturato',
  'usedStatuses.new': 'Nuovo',
  'usedStatuses.to-be-refurbished': 'Da ristrutturare',
  'usedStatuses.under-construction': 'In costruzione',
  'boxAvailabilities.included': 'Abbinato',
  'boxAvailabilities.optional': 'Opzionale',
  'climaTypes.fan-coils': 'Fan coils',
  'climaTypes.fan-coils-ceiling': 'Fan coils canalizzazioni a soffitto',
  'climaTypes.split': 'Split',
  'climaTypes.underfloor': 'A pavimento',
  'climaTypes.underfloor-dehumidification': 'A pavimenti con deumidificazione',
  'energyRatingStatuses.actual': 'Effettiva',
  'energyRatingStatuses.by-project': 'Da progetto',
  'energyRatingStatuses.to-be-renewed': 'Da rinnovare',
  'energyRatings.not-available': 'Mancante',
  'energyRatings.not-required': 'Non soggetto',
  'energyRatings.pending-release': 'In fase di rilascio',
  'exposuretypes.double': 'Doppia',
  'exposuretypes.quadruple': 'Quadrupla',
  'exposuretypes.single': 'Unica',
  'exposuretypes.triple': 'Tripla',
  'heatingSources.geothermal': 'Geotermica',
  'heatingSources.hydrothermal': 'Idrotermica',
  'heatingSources.methane': 'Metano',
  'heatingSources.solar': 'Solare',
  'heatingSystems.air-heating': 'Ad aria',
  'heatingSystems.ceiling-heating': 'A soffitto',
  'heatingSystems.floor-heating': 'A pavimento',
  'heatingSystems.radiator': 'Radiatori',
  'kitchenTypes.coverable-kitchenette': 'Angolo cottura schermabile',
  'kitchenTypes.habitable': 'Abitabile',
  'kitchenTypes.kitchenette': 'Cucinotto',
  'kitchenTypes.open': 'A vista',
  'kitchenTypes.semi-habitable': 'Semi abitabile',
  'laPositioningTypes.east': 'Est',
  'laPositioningTypes.north': 'Nord',
  'laPositioningTypes.north-east': 'Nord-Est',
  'laPositioningTypes.north-west': 'Nord-Ovest',
  'laPositioningTypes.south': 'Sud',
  'laPositioningTypes.south-east': 'Sud-Est',
  'laPositioningTypes.south-west': 'Sud-Ovest',
  'laPositioningTypes.west': 'Ovest',
  'residentialUseTypes.Building': 'Immobile',
  'residentialUseTypes.apartment': 'Appartamento',
  'residentialUseTypes.attic': 'Solaio',
  'residentialUseTypes.automated-parking-space': 'Posto auto meccanizzato',
  'residentialUseTypes.box': 'Box',
  'residentialUseTypes.cellar': 'Cantina',
  'residentialUseTypes.covered-parking-space': 'Posto auto coperto',
  'residentialUseTypes.entire-building': 'Intero immobile',
  'residentialUseTypes.laboratory': 'Laboratorio',
  'residentialUseTypes.land': 'Terra',
  'residentialUseTypes.loft': 'Loft',
  'residentialUseTypes.loft-open-space': 'Loft open space',
  'residentialUseTypes.motorbike-parking-space': 'Posto moto',
  'residentialUseTypes.office': 'Ufficio',
  'residentialUseTypes.outdoor-parking-space': 'Posto auto soperto',
  'residentialUseTypes.penthouse': 'Attico',
  'residentialUseTypes.retail': 'Vendita al dettaglio',
  'residentialUseTypes.shed': 'Capannone',
  'residentialUseTypes.shop': 'Negozio',
  'residentialUseTypes.showroom': 'Showroom',
  'residentialUseTypes.terraced-house': 'Casa a schiera',
  'residentialUseTypes.urban-villa': 'Villa urbana',
  'residentialUseTypes.villa': 'Villa',
  'residentialUseTypes.warehouse': 'Magazzino',
  'windowFixtures.double': 'Doppio',
  'windowFixtures.single': 'Singolo',
  'windowFixtures.triple': 'Triplo',
  'reasons.amenities': 'Amenities',
  'reasons.building': 'Edificio',
  'reasons.concierge': 'Portineria/Concierge',
  'reasons.contract-terms': 'Condizioni contrattuali',
  'reasons.exposure': 'Esposizione/Luminosità',
  'reasons.external-areas': 'Aree esterne',
  'reasons.floor': 'Piano',
  'reasons.layout': 'Layout',
  'reasons.location': 'Location/Zona',
  'reasons.parking': 'Box/Parcheggio',
  'reasons.personal-taste': 'Gusto personale',
  'reasons.price': 'Prezzo/Canone',
  'reasons.quiet-noise': 'Calma/Rumorosità',
  'reasons.required-warranties': 'Garanzie richieste',
  'reasons.servicies': 'Servizi di zona',
  'reasons.surface': 'Superficie',
  'reasons.micro-location': 'Micro-Location',
  'reasons.timing': 'Tempistiche',
  'reasons.view': 'Affaccio',
};
