import * as Collections from 'constants/collections';
import * as Constants from 'constants/constants';
import * as NextDomApi from 'directus/api/dom';
import * as DirectusApi from 'directus/api/items';
import { DirectusClient } from 'directus/utils/client';
import { FeedbackElementsType } from 'pages/KeyPlan/components/FeedbackCard';
import {
  FeedbackReasonType,
  InterestOpportunity,
  OpportunityProductRaw,
} from 'types/opportunity';
import { DeepNullable, DeepPartial } from 'types/utils';
import { isEmpty } from 'utils/array';

export const getUserVisits = async (
  userDataId: string | undefined,
  startDate: string,
  endDate: string,
  pipelineCode: string,
) => {
  if (!userDataId) {
    return [];
  }

  const { data: userVisitsDetails } = await DirectusClient.get(
    Collections.DM_VISITS_COLLECTION,
    {
      params: {
        fields: [
          'id',
          'visit_title',
          'visit_start_date',
          'visit_end_date',
          'attendees',
          'employees.employee.email',
          'employees.employee.id',
          'location',
          'visit_note',
          'visit_type.code',
          'visit_type.icon',
          'online_meeting_url',
          'online_meeting_flag',
          'activity.opportunity.id',
          'activity.opportunity.name',
          'activity.opportunity.pipeline.name',
          'activity.opportunity.pipeline.code',
          'status.code',
        ],
        filter: {
          employees: {
            employee: {
              _eq: userDataId,
            },
          },
          visit_start_date: {
            _gte: startDate,
          },
          visit_end_date: {
            _lte: endDate,
          },
          status: {
            code: {
              _neq: Constants.CANCELED,
            },
          },
          activity: {
            opportunity: {
              pipeline: {
                code: {
                  _eq: pipelineCode,
                },
              },
            },
          },
        },

        limit: -1,
      },
    },
  );

  return userVisitsDetails.data;
};

export async function getDomReasons() {
  const serviceCode = await NextDomApi.getItemIdWithCode(
    Collections.DOM_SERVICE,
    Constants.LIVING_SEARCH_PROPERTY_SALE,
  );

  const reasons = await DirectusApi.getItemsByCollection<FeedbackReasonType>(
    Collections.DOM_REASON,
    {
      filter: {
        service: {
          _eq: serviceCode,
        },
        enabled_flag: {
          _eq: true,
        },
      },
      fields: ['name', 'code', 'enabled_flag'],
    },
    false,
  );
  return reasons;
}

export async function getOpportunityProducts(
  opportunityId: number,
): Promise<OpportunityProductRaw[]> {
  const products =
    await DirectusApi.getItemsByCollection<OpportunityProductRaw>(
      Collections.R_OPPORTUNITY_PRODUCTS,
      {
        filter: {
          opportunity: opportunityId,
        },

        fields: [
          'id',
          'initial_flag',
          'initiative.code',
          'initiative.id',
          'initiative.initiative_name',
          'negative_feedbacks.id',
          'negative_feedbacks.note',
          'negative_feedbacks.reason.code',
          'opportunity',
          'positive_feedbacks.id',
          'positive_feedbacks.note',
          'positive_feedbacks.reason.code',
          'property_unit.availabilities.availability_status.code',
          'property_unit.availabilities.availability_status.color',
          'property_unit.availabilities.availability_status.id',
          'property_unit.availabilities.available_surface',
          'property_unit.availabilities.price_list.actual_price_flag',
          'property_unit.availabilities.price_list.price_type.code',
          'property_unit.availabilities.price_list.price_unit_of_measure.code',
          'property_unit.availabilities.price_list.price',
          'property_unit.availabilities.instruction.instruction_status.code',
          'property_unit.code',
          'property_unit.floor.name',
          'property_unit.floor.code',
          'property_unit.id',
          'property_unit.levels_number',
          'property_unit.name',
          'property_unit.property.commercial_name',
          'property_unit.property.legal_status',
          'property_unit.property.used_status.code',
          'property_unit.property.initiative.initiative_name',
          'property_unit.residential_details.residential_use_type.code',
          'property_unit.residential_details.rooms_number',
          'property_unit.residential_details.communal_garden_flag',
          'property_unit.residential_details.terrace_flag',
          'property_unit.residential_details.balcony_flag',
          'property_unit.residential_details.cellar_flag',
          'property_unit.residential_details.clima_type.name',
          'property_unit.residential_details.clima_type.code',
          'property_unit.residential_details.heating_system_type.name',
          'property_unit.residential_details.heating_system_type.code',
          'property_unit.residential_details.air_conditioning_flag',
          'property_unit.residential_details.bedrooms_number',
          'property_unit.residential_details.bathroom_number',
          'property_unit.residential_details.kitchen_type.name',
          'property_unit.residential_details.kitchen_type.code',
          'property_unit.residential_details.exposure_type.name',
          'property_unit.residential_details.exposure_type.code',
          'property_unit.residential_details.la_positioning_type.name',
          'property_unit.residential_details.la_positioning_type.code',
          'property_unit.residential_details.window_fixtures_type.name',
          'property_unit.residential_details.window_fixtures_type.code',
          'property_unit.residential_details.security_door_flag',
          'property_unit.residential_details.box_flag',
          'property_unit.residential_details.box_availability_type.name',
          'property_unit.residential_details.parking_space_flag',
          'property_unit.residential_details.parking_space_moto_flag',
          'property_unit.residential_details.electric_charge_flag',
          'property_unit.residential_details.energy_rating',
          'property_unit.residential_details.energy_rating.code',
          'property_unit.residential_details.energy_rating_status',
          'property_unit.residential_details.energy_rating_status.code',
          'property.sector_details.available_surface',
          'property.used_status.code',
          'property.code',
          'property.status.code',
          'property.status.color',
          'property.status.id',
          'property.commercial_name',
          'property.id',
          'property.locations.city.name',
          'property.locations.street',
          'status.code',
          'status.color',
          'status.icon',
          'status.id',
          'property_unit.surface_uses.surface_type.code',
          'property_unit.surface_uses.unit_of_measure.code',
          'property_unit.surface_uses.available_value',
        ],
        sort: ['date_created'],
      },
      false,
    );
  return products;
}

export async function updateFeedbackElements(
  feedbackElements: FeedbackElementsType,
  id: number,
  positiveFeedbacksToDelete?: number[],
  negativeFeedbacksToDelete?: number[],
): Promise<void> {
  const insert = [] as Promise<unknown>[];
  const remove = [] as Promise<unknown>[];

  if (!isEmpty(positiveFeedbacksToDelete)) {
    await DirectusApi.removeItemsByIds(
      Collections.POSITIVE_FEEDBACK,
      positiveFeedbacksToDelete!,
    );
  }

  if (!isEmpty(negativeFeedbacksToDelete)) {
    await DirectusApi.removeItemsByIds(
      Collections.NEGATIVE_FEEDBACK,
      negativeFeedbacksToDelete!,
    );
  }

  Object.entries(feedbackElements).forEach(async ([reasonCode, values]) => {
    // Ignore feedback that has not changed
    if (
      (values.value === true && values.positiveFeedbackId) ||
      (values.value === false && values.negativeFeedbackId)
    ) {
      return;
    }

    // If the feedback has changed from its previous state delete it
    // from the corresponding table
    if (values.positiveFeedbackId && values.value !== true) {
      remove.push(
        DirectusApi.removeItemById(
          Collections.POSITIVE_FEEDBACK,
          values.positiveFeedbackId,
        ),
      );
    }

    // If the feedback has changed from its previous state delete it
    // from the corresponding table
    if (values.negativeFeedbackId && values.value !== false) {
      remove.push(
        DirectusApi.removeItemById(
          Collections.NEGATIVE_FEEDBACK,
          values.negativeFeedbackId,
        ),
      );
    }

    // Add the modified feedback to the corresponding tables
    if (values.value !== undefined) {
      insert.push(
        DirectusApi.createItem(
          values.value === true
            ? Collections.POSITIVE_FEEDBACK
            : Collections.NEGATIVE_FEEDBACK,
          {
            opportunity_product: id,
            reason: await NextDomApi.getItemIdWithCode(
              Collections.DOM_REASON,
              reasonCode,
            ),
          },
        ),
      );
    }
  });

  await Promise.all(remove);
  await Promise.all(insert);
}

export function mapOpportunityRaw(
  opportunity: DeepPartial<InterestOpportunity>,
): DeepPartial<DeepNullable<InterestOpportunity>> {
  const remappedObject = {
    ...(Constants.PRODUCTS in opportunity && {
      products: (opportunity.products ?? []).map((p) => ({
        id: p!.id!,
      })),
    }),
  };

  return remappedObject;
}

/**
 * Update opportunity with opportunity ID
 * @param opportunityForm
 * @param opportunity
 */
export async function updateOpportunity(
  opportunity: DeepPartial<InterestOpportunity> & { id: number },
) {
  // Flow [CRM - Opportunity update] - Creates an Activity when an interest is
  // updated
  await DirectusApi.updateItem(
    Collections.OPPORTUNITIES,
    opportunity.id,
    mapOpportunityRaw(opportunity),
  );
}
