import { dom as domEn } from 'lang/en/dom';
import { translations as translationsEn } from 'lang/en/translations';
import { dom as domIt } from 'lang/it/dom';
import { translations as translationsIt } from 'lang/it/translations';
import Mustache from 'mustache';

export const useCustomTranslation = (
  tableLanguageCode: string,
  dataset?: string,
) => {
  const availableDatasetsWithLanguage = [
    {
      languageCode: 'it',
      translations: translationsIt,
      dom: domIt,
    },
    {
      languageCode: 'en',
      translations: translationsEn,
      dom: domEn,
    },
  ];

  const selectedLanguageAvailableDatasets = availableDatasetsWithLanguage.find(
    (aD) => aD.languageCode === tableLanguageCode,
  );

  const selectedDataset =
    selectedLanguageAvailableDatasets?.[
      dataset as keyof typeof selectedLanguageAvailableDatasets
    ] ?? availableDatasetsWithLanguage[0];

  const t = (label: string, templateData?: Record<string, unknown>) => {
    if (!selectedDataset) {
      console.warn('Translations not found');
      return label;
    }

    let msg = '';
    if (templateData) {
      msg = Mustache.render(
        selectedDataset[label as keyof typeof selectedDataset],
        templateData,
      );
      return msg;
    }
    msg = selectedDataset[label as keyof typeof selectedDataset] || label;

    return msg;
  };

  const tConfig = (buttonText: string | Record<string, string>) => {
    if (typeof buttonText === 'string') {
      return buttonText;
    }

    return buttonText[tableLanguageCode];
  };

  return {
    t,
    tConfig,
  };
};
