// eslint-disable-next-line no-restricted-imports
import { GallerySlideshow } from 'components/GallerySlideshow';
import { LeftColumn } from 'components/LeftColumn';
import { MobileBottomContent } from 'components/MobileBottomContent';
import { MotionDiv } from 'components/MotionDiv';
import { AppContext, AppContextType } from 'context';
import React, { Fragment, useContext } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { GalleryType } from 'types/configuration';

export const Gallery: React.FC<GalleryType> = ({
  images,
  title,
  backgroundImage,
  className,
}) => {
  const { useStyles } = useContext<AppContextType>(AppContext);
  const classes = useStyles();

  return (
    <MotionDiv className={className ?? ''}>
      <Fragment>
        <Container fluid>
          <Row className="overflow-hidden">
            <LeftColumn title={title} />

            <Col
              xl={10}
              md={12}
              className={`${classes.galleryContainer} m-auto`}
            >
              <GallerySlideshow images={images} />
            </Col>
          </Row>
        </Container>

        <div className={classes.background}>
          {backgroundImage && (
            <img
              src={backgroundImage}
              alt="Gallery"
              className={classes.backgroundObject}
            />
          )}
        </div>

        <MobileBottomContent />
      </Fragment>
    </MotionDiv>
  );
};
