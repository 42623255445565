import { ConnectionStatus } from 'authentication/hooks/UseDatahubPolling';
import axios from 'axios';
import * as Codes from 'constants/codes';
import { useCustomTranslation } from 'hooks/useCustomTranslation';
import React, { useEffect, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { BASE_STYLES } from 'styles';
import { UserInfoType, VisitDetailsType } from 'types';
import { AppConfiguration, LanguageType } from 'types/configuration';
import { merge } from 'utils/deepMerge';
import { localCache } from 'utils/localCache';

export const DEFAULT_LANGUAGE = {
  code: 'it',
  language: 'Italiano',
};

export type AppContextType = {
  configuration: AppConfiguration;
  configurationUrl: string;
  useStyles: ReturnType<typeof createUseStyles>;
  ready: boolean;
  updating: boolean;

  authToken: string | null;
  setAuthToken: (token: string | null) => void;
  userData: UserInfoType | undefined;
  setUserData: (value: UserInfoType | undefined) => void;
  visitDetails: VisitDetailsType;
  setVisitDetails: (value: React.SetStateAction<VisitDetailsType>) => void;

  authenticationStatus: ConnectionStatus;
  setAuthenticationStatus: (
    value: React.SetStateAction<ConnectionStatus>,
  ) => void;
  markerWindows: string[];
  setMarkerWindows: React.Dispatch<React.SetStateAction<string[]>>;
  selectedLanguage: LanguageType;
  setSelectedLanguage: React.Dispatch<React.SetStateAction<LanguageType>>;
  t: (label: string, templateData?: Record<string, unknown>) => string;
  tDom: (label: string, templateData?: Record<string, unknown>) => string;
  tConfig: (textButton: string | Record<string, string>) => string;
};

const DEFAULT_VALUE: AppContextType = {
  configuration: {
    general: {
      mainLogo: '',
      additionalLogo: '',
      backgroundImage: '',
      tableUuid: '',
      datahubToken: '',
      tableName: '',
      pipelineCode: '',
      demoDate: '',
      hideAvailabilities: false,
      defaultLanguage: DEFAULT_LANGUAGE.code,
    },
    pages: [],
    home: { backgroundImage: '', homeIcon: '' },
    idle: {
      video: {
        poster: '',
        webm: {
          src: '',
          type: '',
        },
        mp4: {
          src: '',
          type: '',
        },
      },
    },
    style: {},
  },
  configurationUrl: '',
  useStyles: () => ({}),
  ready: false,
  updating: false,
  selectedLanguage: DEFAULT_LANGUAGE,
  setSelectedLanguage: () => {},

  authToken: null,
  setAuthToken: () => {},
  userData: undefined,
  setUserData: () => {},
  visitDetails: null,
  setVisitDetails: () => {},
  authenticationStatus: ConnectionStatus.connectionUnavailable,
  setAuthenticationStatus: () => {},
  markerWindows: [],
  setMarkerWindows: () => {},
  t: () => '',
  tDom: () => '',
  tConfig: () => '',
};

export function useAppContext(): AppContextType {
  const [appContext, setAppContext] = useState<AppContextType>(DEFAULT_VALUE);
  const [authToken, setAuthToken] = useState<string | null>(null);
  const [userData, setUserData] = useState<UserInfoType | undefined>(undefined);
  const [visitDetails, setVisitDetails] = useState<VisitDetailsType>(null);
  const [markerWindows, setMarkerWindows] = useState<string[]>([]);

  const [authenticationStatus, setAuthenticationStatus] =
    useState<ConnectionStatus>(ConnectionStatus.userOffline);

  const initialized = useRef<boolean>(false);

  useEffect(() => {
    const localAuthKey: string | null = localCache.getItem(Codes.AUTH_TOKEN);

    if (localAuthKey) {
      setAuthenticationStatus(ConnectionStatus.userAuthenticated);
      setAuthToken(localAuthKey);
    }
  }, []);

  useEffect(() => {
    const init = async () => {
      let configurationUrl = '';

      // The app is built as a React app
      if (!window.electron) {
        const configurationMapping = JSON.parse(
          process.env.REACT_APP_CONFIGURATION_URL ?? '{}',
        );
        configurationUrl =
          configurationMapping[new URL(window.location.href).hostname];

        if (!configurationUrl) {
          return;
        }

        if (navigator?.serviceWorker?.controller) {
          navigator.serviceWorker.controller.postMessage({
            type: 'SET_CONFIGURATION_URL',
            data: configurationUrl,
          });
        }

        const { data: config } = await axios.get(
          process.env.REACT_APP_RUN_LOCAL_CONFIG === 'true'
            ? // Run local config
              `/config.json`
            : // Run remote config
              `${configurationUrl}config.json`,
        );

        setAppContext({
          configuration: config,
          configurationUrl,
          useStyles: createUseStyles(merge(BASE_STYLES, config.style)),
          ready: true,
        } as AppContextType);
      }
      // The app is build as an Electron app
      else {
        window.electron.ipcRenderer.once(
          'got-config',
          (config: AppConfiguration) =>
            setAppContext({
              configuration: config,
              configurationUrl,
              useStyles: createUseStyles(merge(BASE_STYLES, config.style)),
              ready: true,
              updating: false,
            } as AppContextType),
        );

        window.electron.ipcRenderer.once('updating-data', () =>
          setAppContext({ ...DEFAULT_VALUE, updating: true }),
        );

        window.electron.ipcRenderer.once(
          'error-getting-config',
          (configFilePath: string) =>
            alert(`No configuration found (${configFilePath})`),
        );

        window.electron.ipcRenderer.sendMessage('get-config');
      }
    };

    if (!initialized.current) {
      initialized.current = true;
      init();
    }
  }, []);

  const [selectedLanguage, setSelectedLanguage] =
    useState<LanguageType>(DEFAULT_LANGUAGE);

  useEffect(() => {
    const configDefaultLanguage =
      appContext.configuration.general.availableLanguages?.find(
        (language) =>
          language.code === appContext.configuration.general?.defaultLanguage,
      );

    if (configDefaultLanguage) {
      setSelectedLanguage(configDefaultLanguage);
    }
  }, [
    appContext.configuration.general.availableLanguages,
    appContext.configuration.general?.defaultLanguage,
  ]);

  const { t } = useCustomTranslation(selectedLanguage.code, 'translations');
  const { t: tDom } = useCustomTranslation(selectedLanguage.code, 'dom');
  const { tConfig } = useCustomTranslation(selectedLanguage.code);

  return {
    ...appContext,
    authToken,
    setAuthToken,
    userData,
    setUserData,
    visitDetails,
    setVisitDetails,
    authenticationStatus,
    setAuthenticationStatus,
    markerWindows,
    setMarkerWindows,
    selectedLanguage,
    setSelectedLanguage,

    t,
    tDom,
    tConfig,
  };
}

export const AppContext = React.createContext<AppContextType>(DEFAULT_VALUE);
