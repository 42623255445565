import { ITEMS_ENDPOINT } from 'constants/constants';
import { Query } from 'directus/types/query';
import { DirectusClient } from 'directus/utils/client';
import { isEmpty } from 'utils/array';

export async function getItemsByCollection<T = unknown>(
  collection: string,
  queryParams: Query = {},
  propagateError: boolean = false,
): Promise<T[]> {
  if (!collection) {
    return [];
  }

  try {
    const { data } = await DirectusClient.get(
      `${ITEMS_ENDPOINT}/${collection}`,
      {
        params: queryParams,
      },
    );

    return data.data ?? [];
  } catch (err) {
    console.error(
      `Error loading items for collection: ${collection}`,
      typeof err === 'object' ? JSON.stringify(err) : err,
    );
    if (propagateError) {
      throw err;
    }

    return [];
  }
}

/**
 * Remove row with id and table name
 * @param collection table name
 * @param itemId primary key of row
 */
export async function removeItemById(
  collection: string,
  itemId: string | number,
  propagateError: boolean = false,
): Promise<void> {
  if (!collection) {
    throw new Error(
      `Error removing item for collection ${collection}. Collection ID is required.`,
    );
  }

  try {
    await DirectusClient.delete(`items/${collection}/${itemId}`);
  } catch (err) {
    if (propagateError) {
      throw err;
    }

    throw new Error(`Error removing item for collection ${collection}`);
  }
}

/**
 * Remove rows with array of ids and table name
 * @param collection table name
 * @param itemIds array of primary keys of rows
 */
export async function removeItemsByIds(
  collection: string,
  itemIds: string[] | number[],
  propagateError: boolean = false,
): Promise<void> {
  if (!collection) {
    throw new Error(
      `Error removing items for collection ${collection}. Collection ID is required.`,
    );
  }

  if (isEmpty(itemIds)) {
    return;
  }

  try {
    await DirectusClient.delete(`items/${collection}`, {
      data: itemIds,
    });
  } catch (err) {
    if (propagateError) {
      throw err;
    }

    throw new Error(`Error removing items for collection ${collection}`);
  }
}

/**
 * Insert a specific row in the db with table name and data
 * @param collection table name
 * @param itemData data as {key:value}
 * @return {} object inserted in the db
 */
export async function createItem<T = unknown>(
  collection: string,
  itemData: Record<string, unknown>,
  propagateError: boolean = false,
): Promise<T> {
  if (!collection) {
    throw new Error(
      `Error creating item for collection ${collection}. Collection ID is required.`,
    );
  }

  try {
    const { data } = await DirectusClient.post(
      `${ITEMS_ENDPOINT}/${collection}`,
      itemData,
    );

    return data.data;
  } catch (err) {
    if (propagateError) {
      throw err;
    }

    throw new Error(`Error creating item for collection ${collection}`);
  }
}

/**
 * Update a row with item id, table name and directus query params
 * @param collection table name
 * @param itemId primary key of row
 * @param item data as {key:value}
 * @param queryParams query parameters as Query type
 */
export async function updateItem<T = unknown>(
  collection: string,
  itemId: string | number,
  item: Partial<T>,
  queryParams: Query = {},
  propagateError: boolean = false,
): Promise<T> {
  if (!collection) {
    throw new Error(
      `Error patching item for collection ${collection}. Collection ID is required.`,
    );
  }

  try {
    const { data } = await DirectusClient.patch(
      `${ITEMS_ENDPOINT}/${collection}/${itemId}`,
      item,
      {
        params: queryParams,
      },
    );

    return data.data;
  } catch (err) {
    if (propagateError) {
      throw err;
    }

    throw new Error(`Error patching item for collection ${collection}`);
  }
}
