import axios from 'axios';
import * as Constants from 'constants/constants';
import { AppContext, AppContextType } from 'context';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { AvailabilityDataType } from 'types';
import { AvailabilityColorsType, Keyplan, Marker } from 'types/configuration';

const AVAILABILITY_STATUS_AVAILABLE = 'available';

export function useKeyPlanState(
  backgroundImage: string,
  data: Keyplan[],
  availabilityColors: AvailabilityColorsType,
) {
  const { configurationUrl, visitDetails, configuration } =
    useContext<AppContextType>(AppContext);

  const [availabilityData, setAvailabilityData] =
    useState<AvailabilityDataType>({ units: [] });

  const [currentImage, setCurrentImage] = useState<string>(backgroundImage);
  const [zoomedMenu, setZoomedMenu] = useState<boolean>(true);

  const [currentMarkers, setCurrentMarkers] = useState<Marker[]>([]);

  const [
    opportunityProductDeletionInProgress,
    setOpportunityProductDeletionInProgress,
  ] = useState<boolean>(false);

  const fetchAvailabilityData = useCallback(async () => {
    // The app is built as a React app
    if (!window.electron) {
      try {
        const { data } = await axios.get(
          `${configurationUrl}availabilities.json?timestamp=${Math.random()}`,
        );
        setAvailabilityData(data);
      } catch (err) {}
    }
    // The app is build as an Electron app
    else {
      window.electron.ipcRenderer.once(
        'got-availabilities',
        (data: AvailabilityDataType) => setAvailabilityData(data),
      );

      window.electron.ipcRenderer.sendMessage('get-availabilities');
    }
  }, [configurationUrl]);

  const plansData = data;

  useEffect(() => {
    fetchAvailabilityData();
  }, [fetchAvailabilityData]);

  const dataWithAvailabilities = useMemo<Keyplan[]>(() => {
    return plansData.map((pD) => {
      return {
        ...pD,
        markers: pD.markers.map((m) => {
          //Check the version of the file availabilities.json. Leave only the first version when the old structure will be deprecated
          const availability = availabilityData.units.find(
            (u) => u?.name === m.title,
          )?.availabilityStatus;

          let availabilityColor: string;

          switch (availability) {
            case AVAILABILITY_STATUS_AVAILABLE:
              availabilityColor = availabilityColors.available;
              break;
            case undefined:
              availabilityColor = availabilityColors.unknown;
              break;
            default:
              availabilityColor = availabilityColors.unavailable;
          }

          return { ...m, availability, availabilityColor };
        }),
      };
    });
  }, [
    plansData,
    availabilityData,
    availabilityColors.available,
    availabilityColors.unavailable,
    availabilityColors.unknown,
  ]);

  const isAddedToOpportunityProducts = useCallback(
    (propertyName: string) => {
      const opportunityProduct = visitDetails?.opportunityProducts?.find(
        (oP) => oP.property_unit?.name === propertyName,
      );
      if (!opportunityProduct) {
        return;
      }
      return opportunityProduct.status?.code ?? Constants.MISSING;
    },
    [visitDetails?.opportunityProducts],
  );

  const hideAvailabilities = useMemo(
    () => configuration.general.hideAvailabilities,
    [configuration.general.hideAvailabilities],
  );

  return {
    currentImage,
    setCurrentImage,
    zoomedMenu,
    setZoomedMenu,
    currentMarkers,
    setCurrentMarkers,
    dataWithAvailabilities,
    plansData,
    availabilityData,
    opportunityProductDeletionInProgress,
    setOpportunityProductDeletionInProgress,
    isAddedToOpportunityProducts,
    hideAvailabilities,
  };
}
