// eslint-disable-next-line no-restricted-imports
import { AppConfiguration, VideoType } from '../types/configuration';
import { LeftColumn } from 'components/LeftColumn';
import { MobileBottomContent } from 'components/MobileBottomContent';
import { MotionDiv } from 'components/MotionDiv';
import * as Constants from 'constants/constants';
import { AppContext, AppContextType } from 'context';
import { motion } from 'framer-motion';
import React, { Fragment, useContext, useMemo, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import {
  ControlBar,
  ForwardControl,
  Player,
  ReplayControl,
  VolumeMenuButton,
} from 'video-react';
import 'video-react/dist/video-react.css';

// Video sources can be configured in two different ways inside the config.json file
// If you have a single video you can just specify the src property:
// >> "src": "/video/video1.mp4"
// If multiple videos are available you can add an array of the following type:
// >> "availableVideos": [
//                        {
//                          "src": "/video/video1.mp4",
//                           "buttonText": "Video 1"
//                         },
//                         {
//                           "src": "/video/video2.mp4",
//                           "buttonText": "Video 2"
//                         }
//                       ]
// It is useless to add both availableVideos AND src properties: in this case, the former will have
// priority over the latter, which will be ignored
// This way we can add the multiple videos feature without affecting the tables with older configurations.
// We also keep a simpler and effective configuration method when only one video is expected
// (that is the case most of the time)

export const Video: React.FC<VideoType> = ({
  src,
  title,
  backgroundImage,
  availableVideos,
  className,
}) => {
  const { useStyles } = useContext<AppContextType>(AppContext);

  const { configuration, tConfig } = useContext<AppContextType>(AppContext);
  const classes = useStyles();

  const localClasses = useLocalStyles(configuration);

  const [currentVideo, setCurrentVideo] = useState(0);

  const selectableVideos = useMemo(
    () =>
      availableVideos ?? [
        {
          src: src ?? '',
          buttonText: Constants.FIRST_VIDEO_TITLE,
        },
      ],
    [availableVideos, src],
  );

  return (
    <MotionDiv className={className ?? ''}>
      <Fragment>
        <Container fluid>
          <Row className="h-100 overflow-hidden">
            <LeftColumn title={title}>
              <div className={classes.videoButtonsContainer}>
                {selectableVideos.length > 1 &&
                  selectableVideos.map((video, idx) => {
                    return (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{
                          duration: 2,
                          delay: idx / 4,
                          ease: [0, 0.71, 0.2, 1.01],
                        }}
                        onClick={() => {
                          setCurrentVideo(idx);
                        }}
                        key={`${video.buttonText}-${idx}`}
                      >
                        <motion.div
                          whileTap={{ scale: 0.95 }}
                          key={JSON.stringify(video.buttonText)}
                          className={classes.materialButton}
                        >
                          {currentVideo === idx && (
                            <motion.i
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                              transition={{
                                duration: 2,
                                delay: idx / 4,
                                ease: [0, 0.71, 0.2, 1.01],
                              }}
                              className="las la-play me-2"
                            />
                          )}
                          {tConfig(video.buttonText)}
                        </motion.div>
                      </motion.div>
                    );
                  })}
              </div>
            </LeftColumn>

            <Col
              xl={10}
              md={12}
              className={`${classes.galleryContainer}  ${localClasses.videoContainer} my-auto`}
            >
              <Player playsInline src={selectableVideos[currentVideo].src}>
                <ControlBar>
                  {/* @ts-ignore */}
                  <ReplayControl seconds={10} order={2} />
                  {/* @ts-ignore */}
                  <ForwardControl seconds={10} order={3} />
                  {/* @ts-ignore */}
                  <VolumeMenuButton order={5} />
                </ControlBar>
              </Player>
            </Col>
          </Row>
        </Container>

        <div className={classes.background}>
          {backgroundImage && (
            <img
              src={backgroundImage}
              alt="Video"
              className={classes.backgroundObject}
            />
          )}
        </div>

        <MobileBottomContent />
      </Fragment>
    </MotionDiv>
  );
};

const useLocalStyles = createUseStyles({
  videoContainer: (configuration: AppConfiguration) => ({
    '& .video-react-control-bar': {
      color: `${configuration.style.primaryColor}`,
      backgroundColor: '#000000a1',
    },
    '& .video-react-play-progress': {
      backgroundColor: `${configuration.style.primaryColor}`,
    },

    '& .video-react': {
      '& .video-react-big-play-button': {
        color: `${configuration.style.primaryColor}`,
        height: '2em',
        width: '2em',
        border: `3px solid ${configuration.style.primaryColor}`,
        borderRadius: '100%',
        margin: 'auto',
        left: 0,
        right: 0,
        top: '50%',
      },
      '& .video-react-volume-level': {
        backgroundColor: `${configuration.style.primaryColor}!important`,
      },
      '& .video-react-slider:focus': {
        boxShadow: `0 0 0.5em ${configuration.style.primaryColor}`,
      },

      '& .video-react-fullscreen-control': {
        display: 'none !important',
      },

      '& .video-react-control:before': {
        fontSize: '4.2em',
        lineHeight: '1.2em',
      },
      '& .video-react-time-control': {
        fontSize: '2em',
        lineHeight: '2.5em',
      },

      '& .video-react-control': {
        width: '6.5rem',
      },

      '& .video-react-control-bar': {
        height: '5em !important',
      },

      '& .video-react-play-progress:before': {
        fontSize: '2em',
      },

      '& .video-react-big-play-button:before': {
        marginTop: '5px',
      },

      '& .video-react-volume-bar.video-react-slider-horizontal': {
        height: '1.3em',
      },

      '& .video-react-slider': { backgroundColor: 'transparent' },

      '& .video-react-volume-bar': {
        margin: '1.35em 0 1.35em 3em',
      },

      '& .video-react-volume-menu-button': {
        flex: '0.05 1 auto!important',
      },
    },

    '& .video-react .video-react-progress-holder, .video-react-play-progress,  .video-react-load-progress, .video-react-load-progress div':
      {
        height: '.8em',
        borderRadius: '100px',
      },

    '& .video-react-control:hover:before, .video-react-control:focus, .video-react-control:focus:before':
      {
        textShadow: 'none !important',
      },

    '& .video-react-menu': {
      left: '64px!important',
      top: ' 8px!important',
    },
    //RESPONSIVE MEDIA QUERIES
    //XL and XXL Screens
    '@media screen and (min-width: 1200px)': {},
    //Medium & Large Screens
    '@media screen and (min-width: 768px) and (max-width: 1200px)': {
      '& .video-react': {
        '& .video-react-big-play-button': {
          top: '43% !important',
        },
        '& .video-react-play-progress:before': {
          fontSize: '2em !important',
          lineHeight: '0.75em !important',
        },
      },
    },
    //X-small and Small Screens
    '@media screen and (max-width: 768px)': {
      '& .video-react': {
        '& .video-react-big-play-button': {
          top: '35% !important',
        },
      },
      '& .video-react-control': {
        width: '2.5rem !important',
      },
      '& .video-react-control:before': {
        fontSize: '2.2em !important',
        lineHeight: '2.2em !important',
      },
      '& .video-react-time-control': {
        fontSize: '1.2em !important',
        lineHeight: '4em !important',
      },
      '& .video-react .video-react-progress-holder, .video-react-play-progress,  .video-react-load-progress, .video-react-load-progress div':
        {
          height: '0.3em !important',
        },
      '& .video-react-play-progress:before': {
        fontSize: '1em !important',
        lineHeight: '1em !important',
      },
    },
  }),
});
