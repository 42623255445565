export const translations = {
  unavailableImage: 'Immagine non disponibile',
  unavailableInformation: 'Informazioni non disponibili',
  unavailableData: 'Dato non disponibile',
  missingToken: 'Token di accesso mancante',
  noVisits: 'Non sono presenti appuntamenti',
  visits: 'Appuntamenti:',
  logout: 'Logout',
  'propertyUnit.generalInformation': 'Informazioni Generali',
  'propertyUnit.floor': 'Piano',
  'propertyUnit.levelsNumber': 'Numero di livelli',
  'propertyUnit.name': 'Interno',
  'propertyUnit.legalStatus': 'Stato Legale',
  'propertyUnit.residentialUseType': 'Tipologia Living',
  'propertyUnit.usedStatus': 'Stato Interno',
  'propertyUnit.features': 'Caratteristiche Interne',
  'propertyUnit.securityDoor': 'Porta blindata',
  'propertyUnit.parking': 'Parcheggio',
  'propertyUnit.box': 'Box / Garage',
  'propertyUnit.boxAvailability': 'Disponibilità box',
  'propertyUnit.parkingSpace': 'Posto auto',
  'propertyUnit.parkingSpaceMoto': 'Posto moto',
  'propertyUnit.electricChargeFlag': 'Colonnina di ricarica elettrica',
  'propertyUnit.pertinences': 'Pertinenze',
  'propertyUnit.cellar': 'Cantina',
  'propertyUnit.terrace': 'Terrazzo',
  'propertyUnit.balcony': 'Balcone',
  'propertyUnit.communalGarden': 'Giardino condominiale',
  'propertyUnit.climate': 'Clima',
  'propertyUnit.heating': 'Riscaldamento',
  'propertyUnit.heatingSystem': 'Tipo impianto',
  'propertyUnit.airConditioningFlag': 'Climatizzatore',
  'propertyUnit.climaType': 'Impianto di climatizzazione',
  'propertyUnit.composition': 'Composizione',
  'propertyUnit.roomsNumber': 'Numero locali (bagno e cucina esclusi)',
  'propertyUnit.bedroomsNumber': 'Camere',
  'propertyUnit.bathroomsNumber': 'Bagni',
  'propertyUnit.kitchenType': 'Tipologia Cucina',
  'propertyUnit.exposureType': 'Tipologia di esposizione',
  'propertyUnit.livingAreaPositioning': 'Orientamento zona giorno',
  'propertyUnit.fixturesMaterial': 'Materiale infissi',
  'propertyUnit.energyRating': 'Classe energetica',
  'propertyUnit.type': 'Tipologia',
  'propertyUnit.status': 'Stato',
  'propertyUnit.surfaces': 'Superfici',
  'propertyUnit.apartment': 'Appartamento',
  'propertyUnit.porch': 'Loggia',
  yes: 'Sì',
  no: 'No',
  propertyDetails: 'Scheda proprietà',
  feedback: 'Feedback',
  status: 'Stato',
  interested: 'Interessato',
  notInterested: 'Non interessato',
  save: 'Salva',
  saving: 'Salvataggio',
  saved: 'Salvato',
  delete: 'Elimina',
  deleting: 'In eliminazione',
  deleted: 'Eliminato',
  error: 'Error',
  addToSelection: 'Aggiungi',
  livingSurface: 'Appartamento',
  porchSurface: 'Loggia',
  price: 'Prezzo',
  addFromKeyplanSection: 'Aggiungi dalla sezione Keyplan',
  noPropertiesWarning: 'Per {{name}} non ci sono ancora immobili selezionati',
  confirmDelete: 'Conferma eliminazione?',
  addFromKeyplan: 'Aggiungi da Keyplan',
  selectFloor: 'Seleziona il Piano',
  selectLanguage: 'Seleziona Lingua',
  fullscreenAlert:
    'Per attivare la <b>modallità di visualizzazione avanzata</b>, avviare la pagina a Schermo Intero nel menu laterale <b>in basso a destra</b>',
  selectPage: 'Seleziona la pagina:',
  page: 'Pagina',
};
