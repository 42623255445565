// eslint-disable-next-line no-restricted-imports
import { ModalResponsive } from './components/ModalResponsive';
import { FullscreenAlert } from 'components/Fullscreenalert';
import * as Constants from 'constants/constants';
import { AppContext, AppContextType } from 'context';
import { motion } from 'framer-motion';
import {
  THUMB_DOWN_ICON_CODE,
  THUMB_UP_ICON_CODE,
} from 'pages/KeyPlan//KeyPlanFullPageMode';
import { generateMarkerStyle } from 'pages/KeyPlan/KeyPlanFullPageMode';
import { useKeyplanResponsiveState } from 'pages/KeyPlan/hooks/useKeyplanResponsiveState';
import { useKeyPlanState } from 'pages/KeyPlan/hooks/useKeyplanState';
import React, { Fragment, SetStateAction, useContext, useEffect } from 'react';
import { Form, Row } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import { createUseStyles } from 'react-jss';
import { AvailabilityColorsType, Keyplan, Marker } from 'types/configuration';

type KeyPlanFullPageModeProps = {
  backgroundImage: string;
  data: Keyplan[];
  availabilityColors: AvailabilityColorsType;
  backgroundResponsiveImage: string;
  setShowAlert: React.Dispatch<SetStateAction<boolean>>;
  showAlert: boolean;
};

export const KeyPlanResponsiveMode: React.FC<KeyPlanFullPageModeProps> = ({
  backgroundImage,
  backgroundResponsiveImage,
  data,
  availabilityColors,
  setShowAlert,
  showAlert,
}) => {
  const { useStyles, configuration, t, tConfig } =
    useContext<AppContextType>(AppContext);

  const classes = useStyles();

  const localClasses = useLocalStyles();

  const {
    currentFloor,
    setCurrentFloor,
    currentMarker,
    setCurrentMarker,
    showModal,
    setShowModal,
    floorMenuSection,
    setFloorMenuSection,
    currentFloorId,
    setCurrentFloorId,
    windowIsFlipped,
    setWindowIsFlipped,
  } = useKeyplanResponsiveState();

  useEffect(() => {
    setFloorMenuSection(document.getElementById('floor-menu') as Element);
  }, [setFloorMenuSection]);

  const {
    dataWithAvailabilities,
    availabilityData,
    isAddedToOpportunityProducts,
    hideAvailabilities,
  } = useKeyPlanState(backgroundImage, data, availabilityColors);

  useEffect(() => {
    if (!showModal) {
      setWindowIsFlipped(false);
    }
  }, [setWindowIsFlipped, showModal]);

  return (
    <Fragment>
      {/* Render the content inside of the left column in the KeyPlan page */}
      {floorMenuSection &&
        ReactDOM.createPortal(
          <Fragment>
            <h6
              className={`mt-3 d-none d-xl-block floorName ${classes.pageTitle}`}
              style={{ height: '30px' }}
            >
              {currentFloor?.buttonText
                ? tConfig(currentFloor?.buttonText)
                : ''}
            </h6>
            <div
              className={`d-none d-xl-block mt-4 ${classes.materialButtonsContainer}`}
            >
              {dataWithAvailabilities.map((floor, idx) => {
                return (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{
                      duration: 2,
                      delay: idx / 4,
                      ease: [0, 0.71, 0.2, 1.01],
                    }}
                    onClick={() => {
                      setCurrentFloor(dataWithAvailabilities[idx]);
                    }}
                    key={JSON.stringify(floor.buttonText)}
                  >
                    <motion.div
                      whileTap={{ scale: 0.95 }}
                      key={JSON.stringify(floor.buttonText)}
                      className={classes.materialButton}
                      style={{
                        fontWeight:
                          currentFloor?.buttonText === floor.buttonText
                            ? 900
                            : 400,
                        color: configuration.style.primaryColor as string,
                      }}
                    >
                      {tConfig(floor.buttonText)}
                    </motion.div>
                  </motion.div>
                );
              })}
            </div>
          </Fragment>,
          floorMenuSection,
        )}

      {/* Display only on small screens */}

      <Row className="justify-content-center d-flex d-xl-none my-5">
        <Form.Select
          aria-label="page selection"
          className={classes.mobileSelect}
          value={currentFloorId}
          onChange={(e) => {
            const id = parseInt(e.target.value);
            setCurrentFloor(dataWithAvailabilities[id]);
            setCurrentFloorId(id);
          }}
        >
          <option value="">{t('selectFloor')}</option>
          {dataWithAvailabilities.map((floor, id) => {
            return (
              <option value={id} key={id}>
                {tConfig(floor.buttonText)}
              </option>
            );
          })}
        </Form.Select>
      </Row>

      <Row
        className={`justify-content-center ${classes.markersButtonContainer}  d-flex d-md-none`}
      >
        <ResponsiveMarkersButtons
          currentFloor={currentFloor}
          setCurrentMarker={setCurrentMarker}
          setShowModal={setShowModal}
          currentMarker={currentMarker}
          availabilityColors={availabilityColors}
          isAddedToOpportunityProducts={isAddedToOpportunityProducts}
          hideAvailabilities={hideAvailabilities}
        />
      </Row>
      <motion.img
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          duration: 1.5,
        }}
        src={
          currentFloor?.backgroundResponsiveImage
            ? currentFloor?.backgroundResponsiveImage
            : backgroundResponsiveImage
        }
        alt={
          currentFloor?.backgroundResponsiveImage
            ? currentFloor?.backgroundResponsiveImage
            : backgroundResponsiveImage
        }
        className={`img-fluid ${localClasses.backgroundKeyplanImage}`}
        key={JSON.stringify(currentFloor?.buttonText)}
      />

      {/* Display only on large screens */}
      <Row
        className={`d-flex justify-content-center ${classes.flipbookButtonsContainer} d-md-flex d-none`}
      >
        <ResponsiveMarkersButtons
          currentFloor={currentFloor}
          setCurrentMarker={setCurrentMarker}
          setShowModal={setShowModal}
          currentMarker={currentMarker}
          availabilityColors={availabilityColors}
          isAddedToOpportunityProducts={isAddedToOpportunityProducts}
          hideAvailabilities={hideAvailabilities}
        />
      </Row>

      {currentMarker && (
        <ModalResponsive
          currentMarker={currentMarker}
          availabilityData={availabilityData}
          showModal={showModal}
          setShowModal={setShowModal}
          setWindowIsFlipped={setWindowIsFlipped}
          windowIsFlipped={windowIsFlipped}
        />
      )}
      {showAlert && !window.electron && (
        <FullscreenAlert setShowAlert={setShowAlert} />
      )}
    </Fragment>
  );
};

type ResponsiveMarkersButtonsProps = {
  currentFloor: Keyplan | null;
  setCurrentMarker: React.Dispatch<React.SetStateAction<Marker | null>>;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  currentMarker: Marker | null;
  availabilityColors: AvailabilityColorsType;
  isAddedToOpportunityProducts: (propertyName: string) => string | undefined;
  hideAvailabilities: boolean | undefined;
};
const ResponsiveMarkersButtons: React.FC<ResponsiveMarkersButtonsProps> = ({
  currentFloor,
  setCurrentMarker,
  setShowModal,
  currentMarker,
  availabilityColors,
  isAddedToOpportunityProducts,
  hideAvailabilities,
}) => {
  const { useStyles } = useContext<AppContextType>(AppContext);

  const classes = useStyles();

  const localClasses = useLocalStyles();

  return (
    <Fragment>
      {currentFloor &&
        currentFloor.markers.map((marker, id) => {
          return (
            <motion.div
              whileTap={{ scale: 0.8 }}
              key={marker.title}
              onClick={() => {
                setCurrentMarker(marker);
                setShowModal(true);
              }}
              className={`${classes.unitButtons} ${
                currentMarker &&
                currentMarker.title === marker.title &&
                classes.flipbookActiveButton
              }  ${
                localClasses[
                  isAddedToOpportunityProducts(
                    marker.title,
                  ) as keyof typeof localClasses
                ]
              }`}
              style={{
                border: `3px solid ${
                  hideAvailabilities
                    ? availabilityColors.unknown
                    : marker.availabilityColor
                }`,
              }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                duration: 1,
                delay: id / 5,
              }}
            >
              {marker.title}
            </motion.div>
          );
        })}
    </Fragment>
  );
};

const position = {
  top: -7,
  left: -7,
};

const useLocalStyles = createUseStyles({
  backgroundKeyplanImage: {
    maxHeight: '70vh',
    '@media (max-width: 767px)': {
      marginTop: '0!important',
    },
  },
  [Constants.INTERESTED]: generateMarkerStyle(
    Constants.GREEN_COLOR,
    THUMB_UP_ICON_CODE,
    position,
  ),
  [Constants.NOT_INTERESTED]: generateMarkerStyle(
    Constants.RED_COLOR,
    THUMB_DOWN_ICON_CODE,
    position,
  ),
  [Constants.MISSING]: generateMarkerStyle(
    Constants.GREY_COLOR,
    THUMB_UP_ICON_CODE,
    position,
  ),
});
