import * as DirectusApi from 'directus/api/items';
import { localCache, LocalCache } from 'utils/localCache';

const CACHE_NAMESPACE = 'DOM-ITEMS-BY-ID';

/**
 * Retrieve id from required list of DomItems from Directus. By default, it retrieves
 * the 'id' field from required list of DomItems whose code is === @param code
 *
 * @param collection - The collection to retrieve the DomItems from.
 * @param code - default params for filter the DomItems, if overwrite @param filter this
 *    param is === undefined
 * @param filter: a filter to apply to the DomItems. The default filter filters
 *    out the dom items with code === @param code.,
 *    Important: This overwrite the default filter
 * @param sort: the fields to sort the DomItems by.
 */

export type Filter = {
  [keyOrOperator: string]: Filter | any;
};

export async function getItemIdWithCode(
  collection: string,
  code: string | undefined,
  filter?: Filter | null | undefined,
  sort?: string[],
): Promise<number | undefined> {
  if (!code) {
    return undefined;
  }

  const filterDefault = {
    code: {
      _eq: code,
    },
  };

  const query = {
    fields: ['id'],
    filter: filter ?? filterDefault,
    sort,
    limit: 1,
  };

  const cacheKey = `${collection}-${code}-${btoa(JSON.stringify(query))}`;
  const cachedItem = localCache.getItem(cacheKey);
  if (cachedItem) {
    return cachedItem === '' ? undefined : Number(cachedItem);
  }

  try {
    const [result] = await DirectusApi.getItemsByCollection<{ id: number }>(
      collection,
      query,
      false,
    );

    if (!result) {
      return undefined;
    }

    localCache.setItem(cacheKey, result?.id ? result.id.toString() : '', {
      customNamespace: CACHE_NAMESPACE,
      expireTime: LocalCache.FIVE_MINUTES_IN_SECONDS,
    });
    return result.id;
  } catch (err) {
    throw err;
  }
}

export async function getItemCodeWithId(
  collection: string,
  id: number | undefined,
  filter?: Filter | null | undefined,
  sort?: string[],
): Promise<string | undefined> {
  const filterDefault = {
    id: {
      _eq: id,
    },
  };

  try {
    const [result] = await DirectusApi.getItemsByCollection<{ code: string }>(
      collection,
      {
        fields: ['code'],
        filter: filter ?? filterDefault,
        sort,
        limit: 1,
      },
    );
    return result?.code;
  } catch (err) {
    throw err;
  }
}

export async function getItemId(
  collection: string,
  filter: Filter,
  sort?: string[],
): Promise<number | undefined> {
  const query = {
    fields: ['id'],
    filter,
    sort,
    limit: 1,
  };

  const cacheKey = `${collection}-${btoa(JSON.stringify(query))}`;

  const cachedItem = localCache.getItem(cacheKey);
  if (cachedItem) {
    return cachedItem === '' ? undefined : Number(cachedItem);
  }

  try {
    const [result] = await DirectusApi.getItemsByCollection<{ id: number }>(
      collection,
      query,
    );

    localCache.setItem(cacheKey, result?.id ? result.id.toString() : '', {
      customNamespace: CACHE_NAMESPACE,
      expireTime: LocalCache.FIVE_MINUTES_IN_SECONDS,
    });

    return result?.id;
  } catch (err) {
    throw err;
  }
}
