import { getDomReasons } from 'api';
import * as Collections from 'constants/collections';
import { AppContext, AppContextType } from 'context';
import * as DirectusApi from 'directus/api/items';
import { InfoCardTab } from 'pages/KeyPlan/components/infoCard';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { DomItem } from 'types';
import { FeedbackReasonType, OpportunityProductRaw } from 'types/opportunity';
import { chunk, isEmpty } from 'utils/array';

export const useInfoCardState = ({
  propertyName,
}: {
  propertyName: string;
}) => {
  const { authenticationStatus, visitDetails } =
    useContext<AppContextType>(AppContext);

  const opportunityId = useMemo<number | undefined>(() => {
    return visitDetails?.opportunityProducts?.[0]?.opportunity;
  }, [visitDetails?.opportunityProducts]);

  const selectedOpportunityProduct = useMemo<OpportunityProductRaw | undefined>(
    () =>
      visitDetails?.opportunityProducts?.find(
        (oP) => oP?.property_unit?.name === propertyName,
      ),
    [propertyName, visitDetails?.opportunityProducts],
  );

  const [selectedTab, setSelectedTab] = useState<InfoCardTab>(
    InfoCardTab.Information,
  );

  const [feedbackReasons, setFeedbackReasons] = useState<FeedbackReasonType[]>(
    [],
  );

  const [feedbackElements, setFeedbackElements] = useState<
    Record<
      string,
      {
        value: boolean | undefined;
        positiveFeedbackId?: number;
        negativeFeedbackId?: number;
      }
    >
  >({});

  async function handleFeedbackButtonClick() {
    const reasons = await getDomReasons();
    setFeedbackReasons(reasons);
  }

  const feedbackReasonsChunk = chunk(
    feedbackReasons,
    Math.ceil(feedbackReasons.length / 2),
  );

  type ExistingFeedbackType = {
    id: number;
    reason: DomItem;
  };

  const getExistingFeedbacks = useCallback(
    async (opportunityProductId: number) => {
      const existingFeedbacksPromises: Promise<ExistingFeedbackType[]>[] = [
        Collections.POSITIVE_FEEDBACK,
        Collections.NEGATIVE_FEEDBACK,
      ].map(async (feedbackType) => {
        return DirectusApi.getItemsByCollection(feedbackType, {
          filter: {
            opportunity_product: {
              _eq: opportunityProductId,
            },
          },
          fields: ['reason.code', 'id'],
        });
      });

      const [positiveFeedbacks, negativeFeedbacks] = await Promise.all(
        existingFeedbacksPromises,
      );

      if (!isEmpty(positiveFeedbacks)) {
        positiveFeedbacks.map((feedback) =>
          setFeedbackElements((prev) => ({
            ...prev,
            [feedback.reason.code]: {
              value: true,
              positiveFeedbackId: feedback.id,
            },
          })),
        );
      }

      if (!isEmpty(negativeFeedbacks)) {
        negativeFeedbacks.map((feedback) =>
          setFeedbackElements((prev) => ({
            ...prev,
            [feedback.reason.code]: {
              value: false,
              negativeFeedbackId: feedback.id,
            },
          })),
        );
      }
    },
    [],
  );

  useEffect(() => {
    if (!selectedOpportunityProduct) {
      return;
    }
    getExistingFeedbacks(selectedOpportunityProduct.id);
  }, [getExistingFeedbacks, selectedOpportunityProduct]);

  return {
    authenticationStatus,
    opportunityId,
    feedbackReasonsChunk,
    selectedTab,
    setSelectedTab,
    handleFeedbackButtonClick,
    feedbackElements,
    selectedOpportunityProduct,
    getExistingFeedbacks,
    setFeedbackElements,
  };
};
