import { useEffect, useState } from 'react';

const KEYPLAN_IMAGE_PROTOCOL = window.electron ? 'asset:/' : '';

export const useImageStatus = (imageName: string | undefined) => {
  const [imageIsLoading, setImageIsLoading] = useState(false);
  const [imageIsAvailable, setImageIsAvailable] = useState(false);

  useEffect(() => {
    setImageIsLoading(true);

    const image = new Image();
    image.src = `${KEYPLAN_IMAGE_PROTOCOL}/image/planimetry/${imageName}.png`;

    image.onload = () => {
      setImageIsAvailable(true);
      setImageIsLoading(false);
    };
    image.onerror = () => {
      setImageIsAvailable(false);
      setImageIsLoading(false);
    };
  }, [imageName]);

  return {
    imageIsLoading,
    imageIsAvailable,
  };
};
