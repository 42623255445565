/**
 * Check if an Object or Array is empty
 *
 * @param obj the object to check
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function isEmpty<T>(obj: Object | T[] | null | undefined): boolean {
  if (!obj) {
    return true;
  }

  if (Array.isArray(obj)) {
    return obj.length === 0;
  }

  return Object.keys(obj).length === 0;
}

/**
 * Creates an array of elements split into groups the length of size.
 * @param {Array} array Array to split
 * @param {number} size The size of the split
 */
export function chunk<T>(array: T[], size: number): T[][] {
  if (size < 1) {
    return [];
  }

  const numberOfChunks = Math.ceil(array.length / size);

  return Array.from({ length: numberOfChunks }).reduce(
    (result: T[][], _, chunkIndex: number) => [
      ...result,
      array.slice(chunkIndex * size, chunkIndex * size + size),
    ],
    [],
  );
}

/**
 * Split array into groups based on groupKey
 *
 * @param inputArray The array to split into groups
 * @param groupKey The 'key' prop to be used to group elements
 */
export function groupBy<T, K extends keyof T>(
  inputArray: T[],
  groupKey?: K,
): Record<string, T[]> {
  const groups = (inputArray || []).reduce((groupsAcc, item) => {
    const groupName = `${
      groupKey ? item[groupKey] : JSON.stringify(item).replace(/^"+|"+$/g, '')
    }` as unknown as T[K];

    if (!groupsAcc.has(groupName)) {
      groupsAcc.set(groupName, []);
    }

    groupsAcc.get(groupName)!.push(item);

    return groupsAcc;
  }, new Map<T[K] | '', T[]>());

  return Object.fromEntries(groups.entries());
}

/**
 * Split array into groups based on groupKeyFn returned string
 *
 * @param inputArray The array to split into groups
 * @param groupKeyFn The 'keyExtractor' function to be used to group elements of inputArray
 */
export function groupByFn<T>(
  inputArray: T[],
  // eslint-disable-next-line no-unused-vars
  groupKeyFn: (item: T) => string,
): Record<string, T[]> {
  const groups = (inputArray || []).reduce((groupsAcc, item) => {
    const groupName = groupKeyFn(item) ?? '';

    if (!groupsAcc.has(groupName)) {
      groupsAcc.set(groupName, []);
    }

    groupsAcc.get(groupName)!.push(item);

    return groupsAcc;
  }, new Map<string, T[]>());

  return Object.fromEntries(groups.entries());
}
