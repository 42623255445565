import { ConnectionStatus } from 'authentication/hooks/UseDatahubPolling';
import { LanguageMenu } from 'components/SideMenu/LanguageMenu';
import { QrCodePopover } from 'components/SideMenu/QrCodePopover';
import { VisitsMenu } from 'components/SideMenu/VisitsMenu';
import * as Constants from 'constants/constants';
import { AppContext, AppContextType, DEFAULT_LANGUAGE } from 'context';
import { motion } from 'framer-motion';
import { Fragment, useContext, useMemo, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import { useLocation } from 'react-router-dom';
import { VisitRaw } from 'types';
import { getEnvVariable } from 'utils/getEnvVariable';
import { toggleFullscreen } from 'utils/toggleFullScreen';

export const SideMenu: React.FC<{
  setShowAlert: React.Dispatch<React.SetStateAction<boolean>>;
  timestamp: number;
  userVisits: VisitRaw[];
  setUserVisits: React.Dispatch<React.SetStateAction<VisitRaw[]>>;
  showSideMenu: boolean;
  showLanguageMenu: boolean;
  setShowLanguageMenu: React.Dispatch<React.SetStateAction<boolean>>;
  setShowSideMenu: React.Dispatch<React.SetStateAction<boolean>>;
  isFullScreen: boolean;
  setTimestamp: React.Dispatch<React.SetStateAction<number>>;
  showQrCode: boolean;
  setShowQrCode: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  setShowAlert,
  timestamp,
  userVisits,
  setUserVisits,
  showSideMenu,
  setShowSideMenu,
  isFullScreen,
  setTimestamp,
  showQrCode,
  setShowQrCode,
  showLanguageMenu,
  setShowLanguageMenu,
}) => {
  const {
    useStyles,
    userData,
    authenticationStatus,
    configuration,
    selectedLanguage,
  } = useContext<AppContextType>(AppContext);

  const classes = useStyles();

  const [showVisitsMenu, setShowVisitsMenu] = useState(false);

  const { pathname } = useLocation();

  const sideMenuRef = useRef<HTMLDivElement>(null);

  const localClasses = useLocalStyles({
    authenticationStatus,
    showSideMenu,
    showLanguageMenu,
  });

  const availableLanguages = useMemo(() => {
    return configuration.general.availableLanguages;
  }, [configuration.general.availableLanguages]);

  return (
    <div className={localClasses.sideMenuContainer}>
      {pathname !== '/idle-page' && (
        <motion.div
          initial={{ right: -220 }}
          animate={{ right: showSideMenu ? 0 : -220 }}
          transition={{
            duration: 0.5,
            ease: [0.43, 0.13, 0.23, 0.96],
          }}
          className={`${localClasses.sideMenu}`}
          ref={sideMenuRef}
        >
          <Container>
            <Row className="flex-nowrap">
              <Col
                className="d-flex align-items-center p-0"
                style={{ marginRight: 10 }}
              >
                <motion.i
                  initial={{ transform: 'rotate(0deg)' }}
                  animate={{
                    transform: showSideMenu ? 'rotate(180deg)' : 'rotate(0deg)',
                  }}
                  transition={{
                    duration: 0.5,
                    ease: [0.43, 0.13, 0.23, 0.96],
                  }}
                  className={`las la-angle-left ${classes.arrowIcon}`}
                  onClick={() => {
                    setShowSideMenu((prev) => !prev);
                    setShowQrCode(false);
                    setShowVisitsMenu(false);
                    setShowLanguageMenu(false);
                  }}
                />
              </Col>
              {authenticationStatus === ConnectionStatus.userOffline && (
                <Fragment>
                  {/* Default Login through QR code */}
                  <Col className="d-none d-lg-block px-2">
                    <motion.div
                      className={classes.sideMenuIconContainer}
                      whileTap={{ scale: 0.9 }}
                      onClick={() => {
                        setShowQrCode((prev) => !prev);
                        setShowLanguageMenu(false);
                        setTimestamp(new Date().getTime());
                      }}
                    >
                      <i
                        className={`las la-lock ${classes.sideMenuIcon} ${
                          showQrCode ? classes.activeSideMenuIcon : ''
                        }`}
                      />
                    </motion.div>
                  </Col>
                  {/* Smaller screens version: Login directly by clicking on the lock icon */}
                  {!window.electron && (
                    <Col className="d-block d-lg-none px-2">
                      <motion.a
                        className={classes.sideMenuIconContainer}
                        whileTap={{ scale: 0.9 }}
                        onClick={() => {
                          setTimestamp(new Date().getTime());
                        }}
                        href={`${getEnvVariable(
                          'REACT_APP_NEXT_BASE_URL',
                        )}/business-units/worklife/apps/digital-table-authenticator/${encodeURIComponent(
                          configuration.general.tableName,
                        )}/${
                          configuration.general.tableUuid
                        }/authorization/${timestamp}`}
                        target="_blank"
                      >
                        <i
                          className={`las la-lock ${classes.sideMenuIcon} ${
                            showQrCode ? classes.activeSideMenuIcon : ''
                          }`}
                        />
                      </motion.a>
                    </Col>
                  )}
                </Fragment>
              )}

              {authenticationStatus === ConnectionStatus.userAuthenticated &&
                userData?.firstName &&
                userData?.lastName && (
                  <motion.div
                    className={`${classes.nunitoSans} 
                    ${classes.initialsButton} 
                    ${localClasses.connectionDot} 
                    col 
                    ${showVisitsMenu ? classes.activeSideMenuIcon : ''}
                    `}
                    whileTap={{ scale: 0.9 }}
                    onClick={() => {
                      setShowVisitsMenu((prev) => !prev);
                      setShowLanguageMenu(false);
                    }}
                  >
                    <motion.div className={classes.initialsText}>
                      {`${userData.firstName.charAt(
                        0,
                      )}${userData.lastName.charAt(0)} `}
                    </motion.div>
                  </motion.div>
                )}

              {!window.electron && (
                <Col className="d-none d-lg-block px-2">
                  <motion.div
                    className={classes.sideMenuIconContainer}
                    whileTap={{ scale: 0.9 }}
                    onClick={() => {
                      toggleFullscreen();
                      setShowAlert(false);
                    }}
                  >
                    <i
                      className={`las ${
                        isFullScreen ? 'la-compress' : 'la-expand'
                      } ${classes.sideMenuIcon} ${
                        isFullScreen ? classes.activeSideMenuIcon : ''
                      }`}
                    />
                  </motion.div>
                </Col>
              )}
              {availableLanguages && (
                <Col className="px-2">
                  <motion.div
                    className={classes.sideMenuIconContainer}
                    whileTap={{ scale: 0.9 }}
                    onClick={() => {
                      setShowLanguageMenu((prev) => !prev);
                      setShowQrCode(false);
                      setShowVisitsMenu(false);
                    }}
                  >
                    <img
                      src={`${window.electron ? 'asset://' : ''}image/flags/${
                        selectedLanguage?.flagImgCode ?? selectedLanguage.code
                      }.svg`}
                      className={localClasses.flagImage}
                      alt="language flag"
                    />
                  </motion.div>
                </Col>
              )}
            </Row>
          </Container>
          {showQrCode &&
            authenticationStatus === ConnectionStatus.userOffline && (
              <QrCodePopover showQrCode={showQrCode} timestamp={timestamp} />
            )}
        </motion.div>
      )}
      {showVisitsMenu &&
        authenticationStatus === ConnectionStatus.userAuthenticated && (
          <VisitsMenu
            showVisitsMenu={showVisitsMenu}
            userVisits={userVisits}
            setUserVisits={setUserVisits}
            setShowSideMenu={setShowSideMenu}
            setShowVisitsMenu={setShowVisitsMenu}
          />
        )}

      {showLanguageMenu && (
        <LanguageMenu
          showLanguageMenu={showLanguageMenu}
          setShowSideMenu={setShowSideMenu}
          setShowLanguageMenu={setShowLanguageMenu}
          availableLanguages={availableLanguages}
        />
      )}
    </div>
  );
};

const useLocalStyles = createUseStyles({
  sideMenuContainer: { position: 'fixed', right: 0, bottom: 0, zIndex: 100 },
  sideMenu: ({
    authenticationStatus,
    showSideMenu,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    showLanguageMenu,
  }: {
    authenticationStatus: ConnectionStatus;
    showSideMenu: boolean;
    showLanguageMenu: boolean;
  }) => ({
    width: 250,

    bottom: 65,
    position: 'absolute',
    backgroundColor: '#00000038',
    padding: '10px 5px 10px 10px',
    borderRadius: '20px 0 0 20px',
    zIndex: 999999,
    minWidth: 100,
    '&::after': {
      content: '""',
      width: 12,
      position: 'absolute',
      backgroundColor: Constants.GREEN_COLOR,
      zIndex: 999999,
      borderRadius: '100%',
      height: 12,
      left: 0,
      top: 2,
      visibility:
        authenticationStatus === ConnectionStatus.userAuthenticated &&
        !showSideMenu
          ? 'visible'
          : 'hidden',
    },
  }),

  connectionDot: ({
    authenticationStatus,
  }: {
    authenticationStatus: ConnectionStatus;
  }) => ({
    '&::after': {
      content: '""',
      width: 12,
      position: 'absolute',
      backgroundColor: `${
        authenticationStatus === ConnectionStatus.userAuthenticated
          ? Constants.GREEN_COLOR
          : Constants.RED_COLOR
      }`,
      zIndex: 999999,
      borderRadius: '100%',
      height: 12,
      left: 0,
      top: 2,
    },
  }),
  flagImage: ({ showLanguageMenu }: { showLanguageMenu: boolean }) => ({
    width: 50,
    height: 50,
    borderRadius: '100%',
    padding: 10,
    backgroundColor: showLanguageMenu ? Constants.WHITE : '#00000070',
  }),
});
