import * as Constants from 'constants/constants';
import { AppContext, AppContextType } from 'context';
import { motion } from 'framer-motion';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

export const HomeButton: React.FC = () => {
  const navigate = useNavigate();

  const { useStyles } = useContext<AppContextType>(AppContext);

  const classes = useStyles();

  const { configuration, setMarkerWindows } =
    useContext<AppContextType>(AppContext);

  return (
    <motion.div
      initial={{ marginBottom: -100, opacity: 0 }}
      animate={{ marginBottom: 50, opacity: 1 }}
      transition={{
        duration: 0.8,
        delay: 1,
        ease: [0, 0.71, 0.2, 1.01],
      }}
      onClick={() => {
        setMarkerWindows([]);
        navigate('/');
      }}
      className={classes.mainNavButton}
    >
      <motion.div
        whileTap={{ scale: 0.9 }}
        className={`text-uppercase text-center ${classes.homeButtonInner}`}
      >
        <img
          src={configuration.home.homeIcon}
          alt="Home icon"
          className={classes.homeIconImg}
        />
        <span>{Constants.HOME}</span>
      </motion.div>
    </motion.div>
  );
};
