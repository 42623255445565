import * as Codes from 'constants/codes';
import { AppContext, AppContextType } from 'context';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { getEnvVariable } from 'utils/getEnvVariable';
import { StorageType, localCache } from 'utils/localCache';

const TWO_SECONDS_IN_MILLIS = 2000;
const ONE_MINUTE_IN_MILLIS = 60000;

export enum ConnectionStatus {
  userOffline,
  userAuthenticated,
  connectionUnavailable,
}
type UseDatahubPollingType = {
  timestamp: number;
  setShowQrCode: React.Dispatch<React.SetStateAction<boolean>>;
  setShowSideMenu: React.Dispatch<React.SetStateAction<boolean>>;
  setShowLanguageMenu: React.Dispatch<React.SetStateAction<boolean>>;
};

export type ResponseMessageType = {
  type: string;
  status?: string;
  error?: {
    code: string;
    message: string;
  };
  data?: {
    id: number;
    session: string;
    status: string;
    table_code: string;
    user: string;
  };
  event?: string;
};

export const UseDatahubPolling = ({
  timestamp,
  setShowQrCode,
  setShowSideMenu,
  setShowLanguageMenu,
}: UseDatahubPollingType) => {
  const {
    configuration,
    setAuthToken,
    setAuthenticationStatus,
    authenticationStatus,
  } = useContext<AppContextType>(AppContext);

  const token = useMemo<string>(
    () => configuration.general.datahubToken,
    [configuration.general.datahubToken],
  );

  const tableUuid = useMemo<string>(
    () => configuration.general.tableUuid,
    [configuration.general.tableUuid],
  );

  const authenticationHandler = useCallback(
    (message: { access_token: string; expires: number } | undefined) => {
      if (message?.access_token) {
        setAuthenticationStatus(ConnectionStatus.userAuthenticated);
        setAuthToken(message?.access_token);
        localCache.setItem(Codes.AUTH_TOKEN, message?.access_token, {
          storageType: StorageType.LocalStorage,
          expireTime: -1,
        });
      }
    },
    [setAuthToken, setAuthenticationStatus],
  );

  const handleSubscription = useCallback(async () => {
    const url = `${getEnvVariable(
      'REACT_APP_DATAHUB_BASE_URL',
    )}/custom/sso/digital-table/${tableUuid}/auth/${timestamp}`;
    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const auth = await response.json();
    authenticationHandler(auth.data);
  }, [authenticationHandler, tableUuid, timestamp, token]);

  useEffect(() => {
    if (authenticationStatus === ConnectionStatus.userAuthenticated) {
      return;
    }

    let pollingInterval: NodeJS.Timeout;
    let timeoutHandle: NodeJS.Timeout;

    const startPolling = () => {
      pollingInterval = setInterval(async () => {
        await handleSubscription();
      }, TWO_SECONDS_IN_MILLIS);

      timeoutHandle = setTimeout(() => {
        setShowQrCode(false);
        setShowSideMenu(false);
        setShowLanguageMenu(false);
        clearInterval(pollingInterval);
      }, ONE_MINUTE_IN_MILLIS);
    };

    startPolling();

    return () => {
      clearInterval(pollingInterval);
      clearTimeout(timeoutHandle);
    };
  }, [
    authenticationStatus,
    handleSubscription,
    setShowLanguageMenu,
    setShowQrCode,
    setShowSideMenu,
  ]);
};
