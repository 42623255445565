import { LoadingSpinner } from 'components/LoadingSpinner';
import { AppContext, AppContextType } from 'context';
import { Fragment, useContext, useEffect, useState } from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { createUseStyles } from 'react-jss';

export type GallerySlideshowProps = {
  images: { original: string; thumbnail: string }[];
};

export const GallerySlideshow: React.FC<GallerySlideshowProps> = ({
  images,
}) => {
  const { configuration } = useContext<AppContextType>(AppContext);

  const useStyles = createUseStyles({
    gallerySlideshow: {
      '& button.active': {
        borderColor: `${configuration.style.primaryColor}`,
      },
      '& .image-gallery-thumbnail:hover': {
        borderColor: `${configuration.style.primaryColor}`,
      },
      '& .image-gallery-icon:hover': {
        color: `${configuration.style.primaryColor}`,
      },
      '& .image-gallery-slide .image-gallery-image': {
        objectFit: 'cover!important',
      },
    },
  });

  const classes = useStyles();

  const [imagesLoaded, setImagesLoaded] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (imagesLoaded === images.length) {
      setIsLoading(false);
    }
  }, [images.length, imagesLoaded]);

  return (
    <Fragment>
      {isLoading && <LoadingSpinner />}

      <div style={{ display: isLoading ? 'none' : 'block' }}>
        <ImageGallery
          items={images}
          additionalClass={classes.gallerySlideshow}
          onImageLoad={() => {
            setImagesLoaded((prev) => prev + 1);
          }}
        />
      </div>
    </Fragment>
  );
};
