import { getOpportunityProducts, getUserVisits } from 'api';
import { ConnectionStatus } from 'authentication/hooks/UseDatahubPolling';
import { getTodayDateRange } from 'authentication/hooks/UseFetchUserDetails';
import * as Codes from 'constants/codes';
import * as Constants from 'constants/constants';
import { AppContext, AppContextType } from 'context';
import { format, utcToZonedTime } from 'date-fns-tz';
import { AnimatePresence, motion } from 'framer-motion';
import { useContext } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';
import { VisitRaw } from 'types';
import { isEmpty } from 'utils/array';
import { localCache } from 'utils/localCache';

const EUROPE_ROME_TIMEZONE = 'Europe/Rome';
const TIME_PATTERN = 'HH:mm';

export const VisitsMenu = ({
  showVisitsMenu,
  userVisits,
  setUserVisits,
  setShowSideMenu,
  setShowVisitsMenu,
}: {
  showVisitsMenu: boolean;
  userVisits: VisitRaw[];
  setUserVisits: React.Dispatch<React.SetStateAction<VisitRaw[]>>;
  setShowSideMenu: React.Dispatch<React.SetStateAction<boolean>>;
  setShowVisitsMenu: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const {
    useStyles,
    configuration,
    setAuthToken,
    authToken,
    userData,
    setVisitDetails,
    setAuthenticationStatus,
    t,
    visitDetails,
    setMarkerWindows,
  } = useContext<AppContextType>(AppContext);

  const classes = useStyles();

  const localClasses = useLocalStyles(userVisits);

  const navigate = useNavigate();

  function handleLogoutClick() {
    setAuthToken(null);
    setUserVisits([]);
    setAuthenticationStatus(ConnectionStatus.userOffline);
    setVisitDetails(null);
    localCache.removeItem(Codes.AUTH_TOKEN);
    navigate('/');
  }

  async function handleRefreshClick() {
    const { startDate, endDate } = getTodayDateRange(
      configuration.general.demoDate,
    );

    const visitsData = await getUserVisits(
      userData?.id,
      startDate,
      endDate,
      configuration.general.pipelineCode,
    );

    setUserVisits(visitsData);
  }

  async function handleNameCardClick(userEvent: VisitRaw, firstName: string) {
    const opportunityId = userEvent.activity?.opportunity.id;

    if (!opportunityId || !authToken) {
      return;
    }
    setShowSideMenu(false);
    setShowVisitsMenu(false);

    const opportunityProducts = await getOpportunityProducts(opportunityId);
    setVisitDetails({
      name: firstName,
      opportunityProducts: opportunityProducts,
      opportunityId: opportunityId,
    });
  }

  function extractTime(datetimeStr: string) {
    const date = new Date(datetimeStr);
    const timeZone = EUROPE_ROME_TIMEZONE;
    const zonedDate = utcToZonedTime(date, timeZone);
    const pattern = TIME_PATTERN;
    return format(zonedDate, pattern, { timeZone });
  }

  return (
    <AnimatePresence>
      <motion.div
        initial={{ right: -200, opacity: 0 }}
        animate={{
          right: showVisitsMenu ? 0 : -200,
          opacity: showVisitsMenu ? 1 : 0,
        }}
        transition={{
          duration: 0.3,
          ease: [0.43, 0.13, 0.23, 0.96],
        }}
        className={`${localClasses.visitsMenu} ${classes.nunitoSans}`}
        key="qrCode"
      >
        {isEmpty(userVisits) && (
          <Container className={localClasses.visitsMenuContainer}>
            <Row>
              <div className={localClasses.visitsMenuTitle}>
                <i className="las la-calendar" /> {t('noVisits')}
              </div>
            </Row>
          </Container>
        )}
        {!isEmpty(userVisits) && (
          <Container className={localClasses.visitsMenuContainer}>
            <Row>
              <div className={localClasses.visitsMenuTitle}>
                <i className="las la-calendar" /> {t('visits')}
              </div>
            </Row>
            {userVisits
              .sort((a, b) => {
                const dateA = a.visit_start_date
                  ? new Date(a.visit_start_date)
                  : new Date();
                const dateB = b.visit_start_date
                  ? new Date(b.visit_start_date)
                  : new Date();
                return dateA.getTime() - dateB.getTime();
              })
              .map((userEvent, idx) => {
                const [firstName, lastName] =
                  userEvent.activity?.opportunity.name.split(' ') ?? [];

                return (
                  <motion.div
                    whileTap={{ scale: 0.95 }}
                    key={userEvent.id}
                    onClick={async () => {
                      setMarkerWindows([]);
                      await handleNameCardClick(userEvent, firstName);
                    }}
                  >
                    <motion.div
                      initial={{ opacity: 0, x: 100 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{
                        duration: 0.8,
                        delay: idx / 8,
                        ease: [0, 0.71, 0.2, 1.01],
                      }}
                    >
                      <Row className={localClasses.eventCard}>
                        <Col
                          className={localClasses.hourColumn}
                          xs={5}
                          style={{
                            color:
                              visitDetails?.opportunityId ===
                              userEvent.activity?.opportunity.id
                                ? Constants.GREEN_COLOR
                                : '#b5b5c3',
                          }}
                        >
                          {extractTime(userEvent.visit_start_date!)}
                        </Col>
                        <Col xs={7} className={localClasses.nameColumn}>
                          {`${firstName} ${lastName.charAt(0)}.`}
                        </Col>
                      </Row>
                    </motion.div>
                  </motion.div>
                );
              })}
          </Container>
        )}
        <footer className={localClasses.visitsMenuFooter}>
          <Row>
            <Col xs={3}>
              <motion.div whileTap={{ scale: 0.95 }}>
                <Button
                  className={localClasses.syncButton}
                  onClick={handleRefreshClick}
                >
                  <i className="las la-sync" />
                </Button>
              </motion.div>
            </Col>
            <Col xs={9}>
              <motion.div whileTap={{ scale: 0.95 }}>
                <Button
                  className={localClasses.logoutButton}
                  onClick={handleLogoutClick}
                >
                  {t('logout')} <i className="las la-sign-out-alt" />
                </Button>
              </motion.div>
            </Col>
          </Row>
        </footer>
      </motion.div>
    </AnimatePresence>
  );
};

const useLocalStyles = createUseStyles({
  visitsMenuTitle: {
    color: '#B5B5C3',
    margin: '12px 0px 10px 0',
  },

  nameColumn: {
    padding: '0 5px',
    textAlign: 'right',
  },

  visitsMenu: (userVisits: VisitRaw[]) => ({
    width: 200,
    height: userVisits.length === 0 ? 150 : 325,
    position: 'absolute',
    backgroundColor: 'white',
    bottom: 150,
    borderRadius: '10px 0 0 10px',
    zIndex: 9999,
    fontSize: 14,
    overflow: 'auto',
    userSelect: 'none',
  }),
  visitsMenuContainer: (userVisits: VisitRaw[]) => ({
    minHeight: userVisits.length === 0 ? 90 : 265,
    fontWeight: 600,
  }),
  eventCard: {
    backgroundColor: '#F0F0F3',
    padding: '12px 10px',
    borderRadius: 8,
    margin: '0 0 8px 0',
    cursor: 'pointer',
  },
  hourColumn: {
    color: '#B5B5C3',
    padding: '0 5px',
  },
  visitsMenuFooter: {
    position: 'sticky',
    bottom: 0,
    backgroundColor: 'white',
    padding: 12,
    boxShadow: '0px -4px 10px rgba(0, 0, 0, 0.15)',
  },

  logoutButton: {
    width: '100%',
    backgroundColor: '#222222',
    border: 'none',
    '&:hover': {
      backgroundColor: '#222222',
      border: 'none',
    },
    '&:active': {
      backgroundColor: '#222222!important',
      border: 'none',
    },
  },

  syncButton: {
    backgroundColor: 'rgb(240 240 243)',
    color: '#212529',
    border: 'none',
    '&:hover': {
      backgroundColor: 'rgb(240 240 243)',
      color: '#212529',
      border: 'none',
    },
    '&:active': {
      backgroundColor: 'rgb(240 240 243)!important',
      color: '#212529!important',
      border: 'none',
    },
  },
});
