import { FlipbookComponent } from 'components/FlipbookComponent';
import { LeftColumn } from 'components/LeftColumn';
import { MobileBottomContent } from 'components/MobileBottomContent';
import { MotionDiv } from 'components/MotionDiv';
import * as Constants from 'constants/constants';
import { AppContext, AppContextType } from 'context';
import { motion } from 'framer-motion';
import { useWindowDimensions } from 'pages/KeyPlan/GestureWindow';
import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { FlipbookType } from 'types/configuration';

export type Orientation = 'portrait' | 'landscape' | null;

export const Flipbook: React.FC<FlipbookType> = ({
  title,
  defaultWidth,
  defaultHeight,
  variants,
  backgroundImage,
  className,
}) => {
  const { useStyles } = useContext<AppContextType>(AppContext);
  const { configuration, tConfig } = useContext<AppContextType>(AppContext);

  const [currentMaterials, setCurrentMaterials] = useState<number>(0);
  const [orientation, setOrientation] = useState<Orientation>(null);
  const [startPage, setStartPage] = useState<number>(0);

  const flipBook = useRef();

  const classes = useStyles();

  const { width: screenWidth } = useWindowDimensions();

  const [flipbookSize, setFlipbookSize] = useState({
    width: defaultWidth,
    height: defaultHeight,
  });

  useEffect(() => {
    const flipbookSizeRatio = flipbookSize.width / flipbookSize.height;
    if (screenWidth > flipbookSize.width) {
      return;
    }
    setFlipbookSize({
      width: screenWidth * 0.9,
      height: (screenWidth * 0.9) / flipbookSizeRatio,
    });
  }, [flipbookSize.height, flipbookSize.width, screenWidth]);

  useEffect(() => {
    if (orientation === 'landscape') {
      setStartPage(0);
    } else {
      setStartPage(1);
    }
  }, [orientation]);

  return (
    <MotionDiv className={className ?? ''}>
      <Fragment>
        <Container fluid>
          <Row className="h-100 overflow-hidden">
            <LeftColumn title={title}>
              {variants.length > 1 && (
                <div className={classes.materialButtonsContainer}>
                  {variants.map((variant, idx) => {
                    return (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{
                          duration: 2,
                          delay: idx / 4,
                          ease: [0, 0.71, 0.2, 1.01],
                        }}
                        onClick={() => {
                          setCurrentMaterials(idx);
                          //@ts-ignore
                          flipBook.current.pageFlip().turnToPage(startPage);
                        }}
                        key={JSON.stringify(variant.buttonText)}
                      >
                        <motion.div
                          whileTap={{ scale: 0.95 }}
                          key={JSON.stringify(variant.buttonText)}
                          className={classes.materialButton}
                          style={{
                            color:
                              currentMaterials === idx
                                ? Constants.WHITE
                                : `${configuration.style.primaryColor}`,
                          }}
                        >
                          <i className={`las ${variant.icon}`} />{' '}
                          {tConfig(variant.buttonText)}
                        </motion.div>
                      </motion.div>
                    );
                  })}
                </div>
              )}
            </LeftColumn>

            <Col xl={10} md={12} className="m-auto">
              <FlipbookComponent
                width={flipbookSize.width}
                height={flipbookSize.height}
                images={variants[currentMaterials].images}
                flipBook={flipBook}
                setOrientation={setOrientation}
                startPage={startPage}
              />
            </Col>
          </Row>
        </Container>

        <div className={classes.background}>
          {backgroundImage && (
            <img
              src={backgroundImage}
              alt="Gallery"
              className={classes.backgroundObject}
            />
          )}
        </div>
        <MobileBottomContent />
      </Fragment>
    </MotionDiv>
  );
};
