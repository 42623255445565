import { getUserVisits } from 'api';
import { ConnectionStatus } from 'authentication/hooks/UseDatahubPolling';
import * as Codes from 'constants/codes';
import { AppContext } from 'context';
import { useContext, useEffect, useState } from 'react';
import { UserInfoType, VisitRaw } from 'types';
import { getEnvVariable } from 'utils/getEnvVariable';
import { localCache } from 'utils/localCache';

export function getTodayDateRange(stringDate?: string) {
  let todayDate: Date;
  if (stringDate) {
    todayDate = new Date(stringDate);
  } else {
    todayDate = new Date();
  }

  //Get visits of the day
  const startDate = new Date(todayDate.setHours(0, 0, 0, 0)).toISOString();
  const endDate = new Date(todayDate.setHours(23, 59, 59, 999)).toISOString();

  return {
    startDate,
    endDate,
  };
}

export const UseFetchUserDetails = (demoDate: string | undefined) => {
  const [userVisits, setUserVisits] = useState<VisitRaw[]>([]);
  const {
    configuration,
    setUserData,
    userData,
    authToken,
    setAuthenticationStatus,
    setAuthToken,
    setVisitDetails,
  } = useContext(AppContext);

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (!authToken || !getEnvVariable('REACT_APP_DATAHUB_BASE_URL')) {
        return;
      }

      try {
        const url = `${getEnvVariable('REACT_APP_DATAHUB_BASE_URL')}/users/me`;

        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        const { data: userDataResponse } = await response.json();

        if (!userDataResponse) {
          //Token expired but still in local storage
          //or new authentication required
          setAuthenticationStatus(ConnectionStatus.userOffline);
          setAuthToken(null);
          setUserVisits([]);
          setAuthenticationStatus(ConnectionStatus.userOffline);
          setVisitDetails(null);
          localCache.removeItem(Codes.AUTH_TOKEN);
          return;
        }

        const userInfo: UserInfoType = {
          email: userDataResponse.email,
          firstName: userDataResponse.first_name,
          lastName: userDataResponse.last_name,
          id: userDataResponse.id,
        };

        setUserData(userInfo);

        const { startDate, endDate } = getTodayDateRange(demoDate);

        const visitsData = await getUserVisits(
          userData?.id,
          startDate,
          endDate,
          configuration.general.pipelineCode,
        );

        setUserVisits(visitsData);
      } catch (error) {
        console.error(error);
      }
    };

    fetchUserDetails();
  }, [
    authToken,
    configuration.general.pipelineCode,
    demoDate,
    setAuthToken,
    setAuthenticationStatus,
    setUserData,
    setVisitDetails,
    userData?.id,
  ]);
  return {
    userData,
    userVisits,
    setUserData,
    setUserVisits,
  };
};
