import { AppContext, AppContextType } from 'context';
import { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';

export const InfoCardRowTitle = ({
  title,
  icon,
}: {
  title: string;
  icon: string;
}) => {
  const localClasses = useLocalStyles();
  const { useStyles } = useContext<AppContextType>(AppContext);
  const classes = useStyles();

  return (
    <Row>
      <Col className={classes.propertyInfoContainerTitle}>
        <i
          className={`${icon} ${localClasses.propertyInfoContainerTitleIcon}`}
        />
        {title}
      </Col>
    </Row>
  );
};

const useLocalStyles = createUseStyles({
  propertyInfoContainerTitleIcon: {
    marginRight: 10,
  },
});
