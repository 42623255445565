import { ConnectionStatus } from 'authentication/hooks/UseDatahubPolling';
import { AppContext, AppContextType } from 'context';
import { AnimatePresence, motion } from 'framer-motion';
import { Orientation } from 'pages/KeyPlan/GestureWindow';
import { FeedbackCard } from 'pages/KeyPlan/components/FeedbackCard';
import { PropertyDetailsCard } from 'pages/KeyPlan/components/PropertyDetailsCard';
import { useInfoCardState } from 'pages/KeyPlan/hooks/useInfoCardState';
import { useContext } from 'react';
import { Button, Container, Row } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import { UnitDataType } from 'types';

export enum InfoCardTab {
  Information,
  Feedback,
}

export const InfoCard = ({
  propertyName,
  unitData,
  windowOrientation,
  opportunityProductDeletionInProgress,
  setOpportunityProductDeletionInProgress,
  isFullScreen,
}: {
  propertyName: string;
  unitData: UnitDataType | undefined;
  windowOrientation: Orientation;
  opportunityProductDeletionInProgress: boolean;
  setOpportunityProductDeletionInProgress: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  isFullScreen: boolean;
}) => {
  const { t, visitDetails, useStyles } = useContext<AppContextType>(AppContext);
  const localClasses = useLocalStyles({ windowOrientation });
  const classes = useStyles();

  const {
    authenticationStatus,
    opportunityId,
    feedbackReasonsChunk,
    selectedTab,
    setSelectedTab,
    handleFeedbackButtonClick,
    feedbackElements,
    selectedOpportunityProduct,
    getExistingFeedbacks,
    setFeedbackElements,
  } = useInfoCardState({ propertyName });

  return (
    <AnimatePresence>
      <Container
        fluid
        className={`${
          isFullScreen
            ? `d-flex ${localClasses.propertyInfoContainer}`
            : 'h-100'
        }`}
        key="infoCardContainer"
      >
        {authenticationStatus === ConnectionStatus.userAuthenticated &&
          visitDetails && (
            <Row
              className={`justify-content-center ${
                isFullScreen ? 'w-100' : ''
              }`}
            >
              <motion.span
                className="d-inline-block w-auto p-0 me-3"
                whileTap={{ scale: 0.95 }}
              >
                <Button
                  className={`${classes.tabButton} 
                    ${
                      isFullScreen
                        ? localClasses.tabButtonSize
                        : localClasses.tabButtonSizeResponsive
                    }
                    ${selectedTab === InfoCardTab.Information ? 'active' : ''}`}
                  onClick={() => {
                    setSelectedTab(InfoCardTab.Information);
                  }}
                >
                  <i className="las la-list-alt me-2" />
                  {t('propertyDetails')}
                </Button>
              </motion.span>

              <motion.span
                className="d-inline-block w-auto p-0"
                whileTap={{ scale: 0.95 }}
              >
                <Button
                  className={`${classes.tabButton}  ${
                    isFullScreen
                      ? localClasses.tabButtonSize
                      : localClasses.tabButtonSizeResponsive
                  } ${selectedTab === InfoCardTab.Feedback ? 'active' : ''}`}
                  onClick={() => {
                    setSelectedTab(InfoCardTab.Feedback);
                    handleFeedbackButtonClick();
                  }}
                >
                  <i
                    className={`las la-${
                      selectedOpportunityProduct ? 'thumbs-up' : 'plus'
                    } me-2`}
                  />
                  {t(
                    selectedOpportunityProduct ? 'feedback' : 'addToSelection',
                  )}
                </Button>
              </motion.span>
            </Row>
          )}

        {unitData && selectedTab === InfoCardTab.Information && (
          <motion.div
            key="propertyDetails"
            initial={{ opacity: 0, x: -100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 100 }}
            transition={{ type: 'spring', stiffness: 250, damping: 20 }}
          >
            <PropertyDetailsCard
              windowOrientation={windowOrientation}
              unitData={unitData}
              isFullScreen={isFullScreen}
            />
          </motion.div>
        )}

        {unitData && selectedTab === InfoCardTab.Feedback && (
          <motion.div
            key="feedback"
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -100 }}
            transition={{ type: 'spring', stiffness: 250, damping: 20 }}
            style={{ width: '100%' }}
          >
            <FeedbackCard
              feedbackReasonsChunk={feedbackReasonsChunk}
              feedbackElements={feedbackElements}
              setFeedbackElements={setFeedbackElements}
              propertyName={propertyName}
              selectedOpportunityProduct={selectedOpportunityProduct}
              getExistingFeedbacks={getExistingFeedbacks}
              opportunityId={opportunityId}
              unitData={unitData}
              opportunityProductDeletionInProgress={
                opportunityProductDeletionInProgress
              }
              setOpportunityProductDeletionInProgress={
                setOpportunityProductDeletionInProgress
              }
              isFullScreen={isFullScreen}
            />
          </motion.div>
        )}
      </Container>
      {isFullScreen && (
        <div
          className={`${localClasses.propertyInfoContainerUnitBadge} ${classes.unitBadgeText}`}
        >
          {propertyName}
        </div>
      )}
    </AnimatePresence>
  );
};

const useLocalStyles = createUseStyles({
  propertyInfoContainer: ({
    windowOrientation,
  }: {
    windowOrientation: Orientation;
  }) => ({
    height: 1000,
    flexDirection: 'column',
    alignItems: 'flex-start',
    textAlign: 'left',
    marginBottom: 'auto',
    fontFamily: '"Nunito Sans", sans-serif',
    width: windowOrientation === Orientation.Landscape ? '140%' : '100%',
    transform:
      windowOrientation === Orientation.Landscape
        ? 'scale(0.78) translateX(-230px)'
        : 'none',
  }),
  fixedHeight: {
    height: 1000,
  },

  propertyInfoContainerUnitBadge: ({
    windowOrientation,
  }: {
    windowOrientation: Orientation;
  }) => ({
    fontSize: 80,
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: 180,
    height: 180,
    borderRadius: '100%',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transform: windowOrientation ? 'scale(0.5)' : 'none',
  }),
  tabButtonSizeResponsive: {
    width: 160,
    marginTop: 10,
  },
  tabButtonSize: {
    width: 250,
  },
});
