import axios from 'axios';
import type { AxiosRequestConfig } from 'axios';
import * as tokenUtils from 'directus/utils/tokens';
import { getEnvVariable } from 'utils/getEnvVariable';

const DIRECTUS_TIMEOUT = 180000;

export const DirectusClient = axios.create({
  baseURL: getEnvVariable('REACT_APP_DATAHUB_BASE_URL'),
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: DIRECTUS_TIMEOUT,
});

DirectusClient.interceptors.request.use(
  requestSuccessInterceptor,
  requestErrorInterceptor,
);

// REQUEST
export async function requestSuccessInterceptor(
  axiosRequestCfg: AxiosRequestConfig,
): Promise<AxiosRequestConfig> {
  try {
    // Check and acquire a token before the request is sent
    const token = tokenUtils.getAccessToken();

    // Set token inside provided request
    axiosRequestCfg.headers!.Authorization = `Bearer ${token}`;
  } catch (err) {
    // Do something with error of acquiring the token
    console.error('getAuthToken:', err);
  }

  return axiosRequestCfg;
}

export function requestErrorInterceptor(error: any): Promise<void> {
  // Do nothing in case of request error
  return Promise.reject(error);
}
