import { motion } from 'framer-motion';
import { ReactNode } from 'react';

type AnimatedTdProps = {
  children: ReactNode;
  delay: number;
};

export const AnimatedTd: React.FC<AnimatedTdProps> = ({ children, delay }) => {
  return (
    <motion.td
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        duration: 2,
        delay,
        ease: [0, 0.71, 0.2, 1.01],
      }}
    >
      {children}
    </motion.td>
  );
};
